/* How to use:

@include range(range, min-width, max-width) {
    // styles to apply
    ...
}

@parameters:
    range: a) list of 2 integers defining font-size range in which defined styles will be applied; e.g. (20, 22)
           b) an integer defining single font-size in which defined styles will be applied; e.g. 20
    min-width: integer - define minimum window width after which styles will be applied (optional)
    max-width: integer - define maximal window width upon which styles will be applied (optional)

        - if only min-width is passed, styles will be wrapped in @media(min-width: $min-width) {...}
        - if only max-width is passed, styles will be wrapped in @media(max-width: $max-width) {...}
        - if both max- and min- width is applied, styles will be wrapped in @media (min-width: $min-width) and (max-width: $max-width) { ... }


------ EXAMPLE -------

SCSS:

$range-4: (31, 32);

.sidebar-mobile {
    @include range((34, 35)) {
        background-color: rgb(162, 181, 255) !important;
    }
    .left-menu {
        span {
            @include range($range-4, 992, 1200) {
                float: unset;
                padding-left: 30px;
            }
        }
    }
}

#main {
    .left-col {
        @include range((20, 22), $max-width: 567) {
            width: 100%;
        }
    }
    .right-col {
        @include range(20, $min-width: 567) {
            padding: 40px;
        }
    }
}


--> Compiles to:

body.font__size__34 .sidebar-mobile {
    background-color: #a2b5ff !important;
}

body.font__size__35 .sidebar-mobile {
    background-color: #a2b5ff !important;
}

@media (min-width: 992px) and (max-width: 1200px) {
    body.font__size__31 .sidebar-mobile .left-menu span {
        float: unset;
        padding-left: 30px;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    body.font__size__32 .sidebar-mobile .left-menu span {
        float: unset;
        padding-left: 30px;
    }
}

@media (max-width: 567px) {
    body.font__size__20 #main .left-col {
        width: 100%;
    }
}

@media (max-width: 567px) {
    body.font__size__21 #main .left-col {
        width: 100%;
    }
}

@media (max-width: 567px) {
    body.font__size__22 #main .left-col {
        width: 100%;
    }
}

@media (min-width: 567px) {
    body.font__size__20 #main .right-col {
        padding: 40px;
    }
}


--> CSS is further optimized in minification process.

*/

$selector-prefix: 'body.font__size__';

@function get-from($range) {
    @if type-of($range) == number {
        @return $range;
    }
    @else {
        @return nth($range, 1);
    }
}

@function get-to($range) {
    @if type-of($range) == number {
        @return $range;
    }
    @else {
        @return nth($range, 2);
    }
}

@function get-error-msg() {
    $error_msg_parts: (
        '$range parameter has to be a list or integer. E.g. (20, 24) -> @include range((20, 24), or',
        '@include range(30); - using integer means styles will be applied only to that one font-size value.',
        'Read "How to use" in static/scss/accessibility/mixins/_font_size_range_selectors.scss'
    );
    $msg: '';
    @each $part in $error_msg_parts {
        $msg: join($msg, unquote($part), space);
    }
    @return $msg;
}

@mixin range($range, $min-width: 0, $max-width: 0) {
    @if type-of($range) != list and type-of($range) != number {
        @error get-error-msg();
    }

    $from: get-from($range);
    $to: get-to($range);

    @for $i from $from through $to {
        @if $min-width != 0 {
            @if $max-width != 0 {
                @media (min-width: #{$min-width}px) and (max-width: #{$max-width}px) {
                    #{$selector-prefix}#{$i} & {
                       @content;
                    }
                }
            }
            @else {
                @media (min-width: #{$min-width}px) {
                    #{$selector-prefix}#{$i} & {
                       @content;
                    }
                }
            }
        }
        @else {
            @if $max-width != 0 {
                @media (max-width: #{$max-width}px) {
                    #{$selector-prefix}#{$i} & {
                       @content;
                    }
                }
            }
            @else {
                #{$selector-prefix}#{$i} & {
                    @content;
                }
            }
        }
    }
}
