@import '../mixins/font_size_range_selectors';

#acc_toolbar {
    transition: bottom .2s;
    position: fixed;
    bottom: 5.2rem;
    @include range((19, 23)) {bottom: 4.8rem;}
    @include range((24, 28)) {bottom: 4.5rem;}
    @include range((29, 32)) {bottom: 4.2rem;}
    right: -3rem; opacity: 0; // hidden by default
    z-index: 1002;

    &.above-scrollUp {
        bottom: 8.5rem;
        @include range((18, 20)) {bottom: 8.1rem;}
        @include range((21, 24)) {bottom: 7.9rem;}
        @include range((25, 28)) {bottom: 7.5rem;}
        @include range((29, 32)) {bottom: 7.3rem;}
    }

    & > .options {
        list-style: none;
        & > li {
            padding-bottom: 0.625rem;
            & > button {
                // min-width: 3rem;
                width: 3rem;
                text-align: center;
                -webkit-box-shadow: 4px 4px 10px 0 rgba(0,0,0,0.48);
                box-shadow: 4px 4px 10px 0 rgba(0,0,0,0.48);
            }
        }
    }

    button {
        i {
            padding: 0;
        }
    }

    .acc-btn-disabled {
        pointer-events: none;
        opacity: 0.4;
    }

    .acc-btn-active {
        color: #fff !important;
        background-color: #0e6877 !important;
        border-color: #0e6877 !important;
    }
}

#_acc_toggle {
    transition: bottom .2s;
    position: fixed;
    bottom: 35px;
    right: 12px;
    z-index: 1002;

    border: none;
    background: #fff;
    border-radius: 50%;
    width: fit-content;
    padding: 0;
    height: fit-content;
    -webkit-box-shadow: 4px 4px 10px 0 rgba(0,0,0,0.48);
    box-shadow: 4px 4px 10px 0 rgba(0,0,0,0.48);

    &.above-scrollUp {
        bottom: 5.6rem;
        @include range((18, 20)) {bottom: 5.2rem;}
        @include range((21, 24)) {bottom: 5rem;}
        @include range((25, 28)) {bottom: 4.7rem;}
        @include range((29, 32)) {bottom: 4.5rem;}
    }

    & > i {
        padding-right: 0;
        font-size: 3rem;
        color: #17a2b8;
    }

}
