/********** Accessibility font size ranges specific styles **********/

@import '../mixins/font_size_range_selectors';
@import '../../custom_mixins';

$max-size: 32;

// font-size ranges
$_1: (17, 20);
$_2: (21, 24);
$_3: (25, 28);
$_4: (29, $max-size);

.owl-carousel:not(.slider-active) {
    & .owl-nav {
        & button {
            // prevent decrease in button and icon size on smaller screens
            @include range((17, $max-size)) {
                width: 69px;
                height: 69px;
                i {
                    font-size: 50px;
                }
            }
        }
    }
}

/********** SIDEBAR **********/
.sidebar-mobile {
    @mixin left-menu-span {
        float: unset;
        padding-left: 30px;
    }

    .left-menu {
        span {
            @include range($_4, 992, 1370) { @include left-menu-span; }
            @include range($_3, 992, 1230) { @include left-menu-span; }
            @include range($_2, 992, 1090) { @include left-menu-span; }
        }
    }
}
/********** SIDEBAR end **********/

/********** PRIVACY SETTINGS (COOKIE CONTROL) **********/
 // cookie control banner
// #privacy-banner {}
// cookie control modal
// #privacysettings {}
/********** PRIVACY SETTINGS (COOKIE CONTROL) end **********/

/********** News detail books **********/
.product-main-area {
    .book-detail-bg {
        @include range((21, $max-size)) {
            padding-bottom: 20px;
        }
    }
}
/********** News detail books end **********/

/********** Header & menus **********/
.header-mid-area {
    .language-area {
		ul {
			&.pages {
                @include range($_1, $max-width: 449.98) {
                    @include top-bar-menu--pages--hidden;
                }
                @include range($_2, $max-width: 509.98) {
                    @include top-bar-menu--pages--hidden;
                }
                @include range($_3, $max-width: 575.98) {
                    @include top-bar-menu--pages--hidden;
                }
                @include range($_4, $max-width: 634.98) {
                    @include top-bar-menu--pages--hidden;
                }
			}
			#top_bar_links_toggle {
                @include range($_1, $max-width: 449.98) {
                    display: inline-block;
                }
                @include range($_2, $max-width: 509.98) {
                    display: inline-block;
                }
                @include range($_3, $max-width: 575.98) {
                    display: inline-block;
                }
                @include range($_4, $max-width: 634.98) {
                    display: inline-block;
                }
			}
		}
	}

    .menu-area {
        @include range((22, $max-size)) {
            padding: .5rem 0;
        }
        ul {
            & > li {
                .search-toggle-button {
                    @include range((17, 18), $min-width: 1200) {
                        min-width: 180px !important;
                    }
                    &[aria-expanded=false] {
                        span:first-child {
                            & > span {
                                @include range((27, 30), $max-width: 1440) {
                                    display: none !important;
                                }
                                @include range((31, $max-size)) {
                                    display: none !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    #search-form {
        .input-group {
            @include range((18, 22), $max-width:767.98) {
                @include search-menu__input-group-mobile();
            }
            @include range((23, 29), $max-width:991.98) {
                @include search-menu__input-group-mobile();
            }
            @include range((30, $max-size), $max-width:1199.98) {
                @include search-menu__input-group-mobile();
            }
        }
    }
}

#search-form-results-page {
    .input-group {
        @include range((18, 22), $max-width:767.98) {
            @include search-menu__input-group-mobile($border-color: #d4d4d4);
        }
        @include range((23, 29), $max-width:991.98) {
            @include search-menu__input-group-mobile($border-color: #d4d4d4);
        }
        @include range((30, $max-size), $max-width:1199.98) {
            @include search-menu__input-group-mobile($border-color: #d4d4d4);
        }
    }
}

// .mobile-menu-area {
//     .mean-container {
//         .mean-bar {
//         }
//     }
// }

/********** Header & menus **********/

/********** Homepage #featured_links **********/
#featured_links {
    & .featured-link-wrapper {
        @include range((20, $max-size), $min-width: 992, $max-width: 1200) {
            flex: 0 0 50%;
            max-width: 50%;
        }
        @include range((24, $max-size), $min-width: 1200, $max-width: 1440) {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
}
/********** Homepage #featured_links **********/

/********** Homepage slider **********/
.slider-area {
    & .slider-content {
        @media(min-width:1500px) {
            @include range((21, $max-size)) {
                padding-left: 4rem;
            }
        }
        @media(max-width:991.98px) {
            @include range((25, $max-size)) {
                width: 90%;
            }
        }
        @include range((17, $max-size)) {
            & a {
                font-size: 1.2rem !important;
                padding: .5rem 1.5rem !important;
            }
        }
    }
}
/********** Homepage slider **********/

/********** Homepage announcements **********/
#announcements {
    @mixin announcements-font-size-adjust {
        margin-top: 0;
        & .col-lg-5 {
            width: 100%; max-width: 100%; flex: 0 0 100%;
        }
        & .obavijesti {
            width: 100%; max-width: 100%; flex: 0 0 100%;
            padding-left: 15px;
            padding-top: 1.5rem;
        }
        & .gradient-hr-mustard {display: block;}
        & .featured-banners {
                padding: 2rem 0;
                // padding: 2rem 0 .5rem 0;
                & > .col-6 {
                    width: 25%; max-width: 25%; flex: 0 0 25%;
            }
                & > div:not([class*="gradient-hr"]) {
                    margin: 15px 0;
            }
        }
    }
    @include range((21, 24), 992, 1199) {
        @include announcements-font-size-adjust();
    }
    @include range((25, $max-size), 992, 1439) {
        @include announcements-font-size-adjust();
    }
}
/********** Homepage announcements **********/

/********** Homepage events section **********/
#events-section {
    & .post-content {
        & > h3 {
            & > a {
                @include range((18, $max-size)) {
                    height: 6rem;
                }
            }
        }
    }
    & .table-wrapper {
		table#incoming_events {
			td {
				span {
					& > i {
                        @include range((18, $max-size)) {
                            top: .1rem
                        }
					}
				}
			}
		}
    }
}
/********** Homepage events section **********/

/********** E-katalog section **********/
#e_katalog {
    .e-katalog-box {
        @include range((26, $max-size)) {
            width: 50%;
        }
        @include range((22, $max-size), $max-width: 1440) {
            width: 50%;
        }
        @include range((29, $max-size), $max-width:1440) {
            width: 70%;
        }
        @include range((20, $max-size), $max-width:1250) {
            width: 70%;
        }
        @include range((29, $max-size), $max-width:991.98) {
            position: relative;
            top: 0;
            right: 0;
            width: 100%;
        }
        @include range((26, $max-size), $max-width:850) {
            position: relative;
            top: 0;
            right: 0;
            width: 100%;
        }
        @include range((20, $max-size), $max-width:767.98) {
            position: relative;
            top: 0;
            right: 0;
            width: 100%;
        }
    }
}
/********** E-katalog section **********/

/********** container sizing ***********/
.container {
    @media(min-width: 1441px) {
        @include range($_2) {
            max-width:1654px;
        }
        @include range($_3) {
            max-width:1654px;
        }
        @include range($_4) {
            max-width:1840px;
        }
    }
}
/********** container sizing ***********/

/********** selected_pages section ***********/
#selected_pages {
    .selected-pages-wrapper {
        @include range($_4) {
            width: 65%;
            @media(max-width:1439.98px) {
                flex: 0 0 100%;
                max-width: 100%;
            }
            .selected-pages {
                @media(max-width:576.98px) {
                    width: 100%;
                    position: relative;
                    padding: 0 15px;
                    left: 0;
                    right: 0;
                }
                @media(min-width:577px) and (max-width:991.98px) {
                    width:80%;
                }
                // @media(min-width:1440px) {
                //     width:65%;
                // }
            }
        }
        @include range($_3) {
            .selected-pages {
                width: 65%;
                @media(max-width:576.98px) {
                    width: 100%;
                    position: relative;
                    padding: 0 15px;
                    left: 0;
                    right: 0;
                }
                @media(min-width:577px) and (max-width:1199.98px) {
                    width:80%;
                }
            }
        }
        @include range($_2) {
            .selected-pages {
                @media(max-width:767.98px) {
                    width: 100%;
                    position: relative;
                    padding: 0 15px;
                    left: 0;
                    right: 0;
                }
                @media(min-width:768px) and (max-width:991.98px) {
                    width:80%;
                }
                @media(min-width:992px) and (max-width:1300px) {
                    width:70%;
                }
            }
        }
    }
}
/********** selected_pages section ***********/

/********** gallery_teaser section ***********/
#gallery-teaser {
    .galleries-wrapper {
        @include range($_2) {
            & > div {
                &:nth-child(2) {
                    & > .row {
                        &:nth-child(2) {
                            .gallery-title {
                                display: flex;
                                flex-direction: column;
                                justify-content: space-around;
                                padding: 0;
                            }
                        }
                    }
                }
            }
        }
        @include range($_3) {
            @media(min-width:992px) {
                height: 990px;
            }
        }
        @include range($_4) {
            @media(min-width:1200px) {
                height: 990px;
            }
            & > div {
                &:nth-child(2) {
                    & > .row {
                        & > div {
                            @media(max-width: 399.98px) {
                                height: 10rem;
                            }
                            @media(min-width: 400px) and (max-width: 575.98px) {
                                height: 12rem;
                            }
                            @media(min-width: 576px) and (max-width: 767.98px) {
                                height: 10rem;
                            }
                            @media(min-width: 768px) and (max-width: 991.98px) {
                                height: 12rem;
                            }
                        }
                    }
                }
            }
            @media(min-width: 992px) and (max-width:1200px) {
                height: unset;
                & > div {
                    height: 100%;
                    &:nth-child(2) {
                        & > .row {
                            &:nth-child(1) {
                                height: unset;
                            }
                            &:nth-child(2) {
                                height: unset;
                            }
                            & > div {
                                height: 15rem;
                                padding-bottom: 30px;
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-end;
                                .gal-link {
                                    &.third, &.fourth {
                                        height: 100% !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
	}
}
/********** gallery_teaser section ***********/

///// font-size utility classes
div {
    @include range($_4) {
        &.d-xl-block__font-size-4 {
            @media(min-width:992px) {display:block !important;}
            @media(max-width:1199.98px) {display:none !important;}
        }
        &.d-xl-none__font-size-4 {
            @media(min-width:992px) {display:none !important;}
            @media(max-width:1199.98px) {display:block !important;}
        }
        &.col-md-12__font-size-4 {
            @media(min-width:992px) and (max-width: 1199.98px) {flex: 0 0 100%; max-width: 100%;}
        }
        &.col-xl-12__font-size-4 {
            @media(min-width:1200px) {flex: 0 0 100%; max-width: 100%;}
        }
        &.col-lg-6__font-size-4 {
            @media(min-width:992px) and (max-width: 1199.98px) {flex: 0 0 50%; max-width: 50%;}
        }
    }
}
