a.meanmenu-reveal {
  display: none;
}

.mean-container .mean-bar {
  background: transparent none repeat scroll 0 0;
  float: left;
  min-height: 50px;
  position: relative;
  width: 100%;
  z-index: 999;
}

.mean-container a.meanmenu-reveal {
  width: 2rem;
  height: 2rem;
  top: 0;
  right: 0;
  cursor: pointer;
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  text-indent: -9999em;
  line-height: 22px;
  font-size: 1px;
  display: block;
  font-family: Arial,Helvetica,sans-serif;
  font-weight: 700;
  float: right;
  overflow: hidden;
}

.mean-container a.meanmenu-reveal span {
  display: block;
  background: #fff;
  /* background:#000; */
  height: .25rem;
  margin-top: .3rem;
  width: 2rem;
}

.mean-container a.meanmenu-reveal.meanclose {
  line-height: 2rem;
  font-size: 2rem;
}

.mean-container .mean-nav {
  width: 100%;
  background: #262626;
}

.mean-container .mean-nav ul {
  padding: 0;
  margin: 0;
  width: 100%;
  list-style-type: none;
}

.mean-container .mean-nav ul li {
  background: #f8f8f8 none repeat scroll 0 0;
  float: left;
  position: relative;
  width: 100%;
  border-top: 1px solid#ddd;
}

.mean-container .mean-nav ul li a {
  background: #f8f8f8 none repeat scroll 0 0;
  color: #666666;
  display: block;
  float: left;
  font-size: 12px;
  margin: 0;
  padding: 1em 5%;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;
  width: 90%;
  font-weight: bold;
}

.mobile-menu-area {
  /* height: 3rem; */
  background: #262626;
  /* background: #fff; */
}

.mean-container .mean-nav ul li li a {
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  opacity: 0.75;
  padding: 1em 10%;
  text-shadow: none !important;
  visibility: visible;
  width: 80%;
  font-weight: normal;
  text-transform: capitalize;
  color: #444;
}

.mean-container .mean-nav ul li.mean-last a {
  border-bottom: 0;
  margin-bottom: 0;
}

.mean-container .mean-nav ul li li li a {
  width: 70%;
  padding: 1em 15%;
}

.mean-container .mean-nav ul li li li li a {
  width: 60%;
  padding: 1em 20%;
}

.mean-container .mean-nav ul li li li li li a {
  width: 50%;
  padding: 1em 25%;
}

.mean-container .mean-nav ul li a:hover {
  background: #f8f8f8 none repeat scroll 0 0;
  color: #F47E1E;
}

.mean-container .mean-nav ul li a.mean-expand {
  background: #f8f8f8 none repeat scroll 0 0;
  border: 0 none !important;
  font-weight: 700;
  height: 15px;
  margin-top: 1px;
  padding: 6px 12px 12px !important;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 60px;
  z-index: 2;
}

.mean-container .mean-nav ul li a.mean-expand:hover {
  background: #f8f8f8;
}

.mean-container .mean-push {
  float: left;
  width: 100%;
  padding: 0;
  margin: 0;
  clear: both;
}

.mean-nav .wrapper {
  width: 100%;
  padding: 0;
  margin: 0;
}

.mean-container .mean-bar,
.mean-container .mean-bar * {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.mean-remove {
  display: none!important;
}

.mean-container .mean-bar::after {
  color: #ffffff;
  font-size: 21px;
  left: 0;
  position: absolute;
  top: 12px;
}

.mean-container .mean-nav ul li a.mean-expand {
  font-size: 1.2rem;
}

.mean-container > .mean-bar img {
  height: 100%;
  /* filter: invert(1); */
}

.mean-container > .mean-bar > .search-toggle-button.mobile {
  background-color: transparent !important;
  border: none;
}

.mean-container > .mean-bar > .search-toggle-button.mobile:focus {
  box-shadow: none !important;
}

.mean-container > .mean-bar >  .search-toggle-button.mobile span:first-child > i,
.mean-container > .mean-bar >  .search-toggle-button.mobile span:last-child > i {
  font-size: 1.4rem;
}

.mean-container > .mean-bar >  .search-toggle-button.mobile[aria-expanded=false] span:first-child,
.mean-container > .mean-bar >  .search-toggle-button.mobile[aria-expanded=true] span:last-child {
  display: inline;
}

.mean-container > .mean-bar >  .search-toggle-button.mobile[aria-expanded=false] span:last-child,
.mean-container > .mean-bar >  .search-toggle-button.mobile[aria-expanded=true] span:first-child {
  display: none;
}