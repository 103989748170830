@media (min-width: 1300px) and (max-width: 1500px) {
  .home-3-main {
    max-width: 1200px;
  }
}

@media (min-width: 1170px) and (max-width: 1299px) {
  .menu-area ul li > a,
  .safe-area a {
    padding: 13px;
  }

  .slider-width {
    margin-left: 144px;
    width: 36%;
  }

  .timer .cdown span {
    width: 58px;
  }

  .home-3-main {
    max-width: 1170px;
  }

  .timer-2 .cdown span {
    width: 45px;
  }

  .timer-2 .cdown {
    margin: 0 3px;
  }

  .post-content h3 a {
    font-size: 20px;
  }

  .block-newsletter {
    margin-top: 43px;
  }

  .block-newsletter h2 {
    font-size: 17px;
  }

  .home-4 .post-content h3 a {
    font-size: 17px;
  }

  .section-title-5 h2 {
    font-size: 21px;
  }

  .product-wrapper-content .product-details p {
    margin: 10px 0 20px;
  }

  .product-wrapper-content .product-details h4 {
    margin-bottom: 6px;
    margin-top: 6px;
  }

  .author-destils .author-right ul li {
    margin-left: 5px;
  }

  .single-banner .banner-text h4 {
    font-size: 15px;
  }

  .single-banner .banner-img {
    margin-right: 5px;
    flex: 0 0 27px;
  }

  .menu-area ul li .mega-menu {
    width: 770px;
  }

  .menu-area ul li .mega-menu.mega-menu-2 {
    width: 600px;
  }

  .deal-off-day p {
    font-size: 14px;
    padding: 15px 0;
  }
}

/* Normal desktop :992px. */

@media (min-width: 992px) and (max-width: 1169px) {
  .menu-area ul li > a,
  .safe-area a {
    padding: 12px 8px;
  }

  .menu-area ul li {
    margin-right: 2px;
  }

  .menu-area ul li .mega-menu {
    width: 690px;
  }

  .menu-area ul li .mega-menu-2 {
    width: 540px!important;
  }

  .menu-area ul li .mega-menu-3 {
    width: 460px!important;
  }

  .single-banner .banner-text h4 {
    font-size: 15px;
  }

  .bestseller-content h1 {
    margin-bottom: 25px;
  }

  .bestseller-content h2 {
    font-size: 50px;
  }

  .author-destils .author-right ul li {
    margin-left: 8px;
  }

  .product-details .product-link .product-button a {
    font-size: 14px;
  }

  .product-details .product-link .add-to-link ul li a {
    height: 25px;
    line-height: 25px;
    width: 25px;
  }

  .section-title-5 h2 {
    font-size: 20px;
  }

  .home-2 .main-menu ul li a {
    font-size: 14px;
    padding: 12px 11px;
  }

  .home-2 .slider-content h1 {
    font-size: 55px;
  }

  .timer .cdown {
    margin: 0 5px;
  }

  .timer .cdown span {
    width: 60px;
  }

  .timer .cdown::before {
    right: -11px;
  }

  .home-4 .single-banner-2 {
    padding: 15px 7px;
  }

  .home-4 .product-link .product-button a {
    font-size: 15px;
  }

  .home-4 .post-content h3 a {
    font-size: 17px;
  }

  .timer-2 .cdown span {
    width: 40px;
  }

  .timer-2 .cdown::before {
    right: -7px;
  }

  .timer-2 .cdown {
    margin: 0 3px;
  }

  .home-5 .menu-area ul li {
    margin-right: 2px;
  }

  .home-6 .menu-area ul li {
    margin-right: 2px!important;
  }

  .home-6 .menu-area ul li > a,
  .home-6 .safe-area a {
    font-size: 15px;
  }

  .bestseller-active.owl-carousel:hover .owl-nav button.owl-prev {
    left: -10px;
  }

  .bestseller-active.owl-carousel:hover .owl-nav button.owl-next {
    right: -10px;
  }

  .tab-active.owl-carousel .owl-nav button,
  .post-active.owl-carousel .owl-nav button,
  [class*="carousel__generic"].owl-carousel .owl-nav button,
  .bestseller-active.owl-carousel .owl-nav button,
  .tab-active-3.owl-carousel .owl-nav button,
  .hot-sell-active.owl-carousel .owl-nav button {
    font-size: 40px;
    height: 50px;
    width: 50px;
  }

  .block-newsletter {
    margin-top: 43px;
  }

  .block-newsletter h2 {
    font-size: 17px;
  }

  .post-content h3 a {
    font-size: 20px;
  }

  .deal-off-day p {
    font-size: 14px;
  }

  .deal-off-day h2 a {
    font-size: 40px;
  }

  .home-3-main {
    max-width: 970px;
  }

  .slider-width {
    margin-left: 90px;
    width: 50%;
  }

  .pl-295 {
    padding-left: 248px;
  }

  .flex-direction-nav a {
    margin: -75px 0 0;
  }

  .single-banner .banner-img {
    margin-right: 6px;
    flex: 0 0 27px;
  }

  .category-area h3 {
    padding: 0 15px;
  }

  .category-menu {
    padding: 0 0 20px 15px;
  }
}

/* Tablet desktop :768px. */

@media (min-width: 768px) and (max-width: 991px) {
  .slider-content.slider-animated-1.text-center {
    text-align: left !important;
  }

  .bestseller-active {
    margin-top: 30px;
  }

  .banner-static-area .ptb-100 {
    padding: 60px 0;
  }

  .testimonial-text p {
    font-size: 16px;
    margin: 20px 0;
  }

  .about-content ul li a {
    font-size: 15px;
  }

  .skill-content h3 {
    font-size: 23px;
  }

  .author-destils .author-right span {
    font-size: 15px;
  }

  .author-destils .author-right ul li {
    margin-left: 3px;
  }

  .author-destils .author-left .author-description {
    margin-top: 0;
  }

  .comment-reply-wrap ul li .public-comment .comment-img {
    margin-right: 10px;
  }

  .ship-different-title label {
    font-size: 18px;
  }

  .left-title h4 {
    font-size: 15px;
  }

  .product-info-main .page-title h1 {
    font-size: 21px;
  }

  .left-title-2 h2 {
    font-size: 14px;
  }

  .product-details .product-link .add-to-link ul li a {
    width: 25px;
  }

  .list-page {
    margin-left: 0;
  }

  .control span {
    font-size: 13px;
  }

  .toolbar-sorter {
    margin-right: 0px;
  }

  .control select {
    min-width: 16px !important;
  }

  .toolbar-sorter span {
    font-size: 13px;
  }

  .tab-3 ul li {
    margin-right: 10px;
  }

  .section-title-5 h2 {
    font-size: 18px;
  }

  .home-2 .slider-active {
    float: none;
    width: 100%;
  }

  .timer .cdown {
    margin: 0 6px;
  }

  .timer .cdown::before {
    right: -12px;
  }

  .twitter-content .twitter-text {
    width: 75%;
  }

  .twitter-content .twitter-icon {
    width: 25%;
  }

  .home-2 .product-area .product-link .add-to-link ul li a {
    font-size: 16px;
  }

  .home-2 .product-link .product-button a {
    font-size: 13px;
  }

  .banner-total-2 {
    margin-top: 7px;
  }

  .banner-area-4 {
    padding-bottom: 30px;
  }

  .home-3 .slider-content h1 {
    display: block;
  }

  .timer-2 .cdown span {
    font-size: 20px;
    height: 40px;
    line-height: 40px;
    width: 40px;
  }

  .timer-2 .cdown::before {
    right: -10px;
    top: -6px;
  }

  .tab-menu-2 ul li a {
    margin-right: 35px;
  }

  .product-total-3 {
    width: 50%;
  }

  .block-newsletter-2 .container-inner h2 {
    font-size: 15px;
  }

  .block-newsletter-2 .newsletter-content form input {
    width: 250px;
  }

  .mrg-sm {
    margin-top: 40px;
  }

  .single-slider {
    background-position: 63% center;
  }

  .pl-100 {
    padding-left: 50px;
  }

  .banner-area-5 .banner-text h3 {
    font-size: 16px;
  }

  .banner-area-5 .banner-text h2 {
    font-size: 22px;
    margin-right: 0;
  }

  .tab-active.owl-carousel .owl-nav button,
  .post-active.owl-carousel .owl-nav button,
  [class*="carousel__generic"].owl-carousel .owl-nav button,
  .bestseller-active.owl-carousel .owl-nav button,
  .tab-active-3.owl-carousel .owl-nav button,
  .hot-sell-active.owl-carousel .owl-nav button {
    font-size: 30px;
    height: 40px;
    width: 40px;
  }

  .bestseller-active.owl-carousel:hover .owl-nav button.owl-next {
    right: 0;
  }

  .bestseller-active.owl-carousel:hover .owl-nav button.owl-prev {
    left: 0px;
  }

  .home-3-main {
    max-width: 750px;
  }

  .category-menu {
    padding: 0 0 20px 20px;
  }

  .category-area h3 {
    padding: 0 20px;
  }

  .pos-search .bootstrap-select {
    width: 190px;
  }

  .header-bottom-area form input {
    padding-left: 209px;
  }

  .pl-295 {
    padding-left: 253px;
  }

  .service-mrg-btm {
    margin-bottom: 40px;
    overflow: hidden;
  }

  .home-4 .header-search form input,
  .home-5 .header-search form input {
    width: 100%;
  }

  .home-4 .header-search,
  .home-5 .header-search {
    margin-right: 0;
  }

  .slider-width {
    margin-left: 50px;
    width: 60%;
  }

  .home-6 .slider-content-2 {
    margin-right: 0;
    text-align: center;
  }

  .shop-tab {
    display: block;
    float: none;
    overflow: hidden;
  }

  .field-limiter {
    display: inline-block;
    float: left;
    margin-top: 20px;
  }

  .toolbar-sorter {
    margin-left: 30px;
    margin-right: 0;
    margin-top: 20px;
  }

  .author-destils .author-left {
    width: 63%;
  }

  .author-destils .author-right {
    width: 37%;
  }

  .wishlist-table table .product-add-to-cart > a {
    display: inline-block;
    padding: 10px;
    text-align: center;
    width: 160px;
  }

  .wishlist-table table .product-add-to-cart {
    width: 200px;
  }

  #productModal .modal-dialog {
    width: 750px;
  }

  .flex-direction-nav a {
    margin: -54px 0 0;
  }

  .block-newsletter {
    margin-top: 90px;
    padding: 45px 25px;
  }

  .home-4 .block-newsletter h2 {
    font-size: 18px;
  }

  .mobile-menu-area .mean-nav ul#nav {
    /* height: 252px; */
    height: max-content;
    margin-top: 10px;
    overflow-y: auto;
  }

  .mean-container .mean-bar::after {
    top: 9px;
  }

  .tab-3 ul li::before {
    top: 10px;
  }

  .review-form .single-form form input {
    width: 300px;
  }

  .review-form .single-form form textarea {
    width: 300px;
  }

  .review-form {
    padding-left: 0;
  }

  .product-add-form form a {
    padding: 0 20px;
  }

  .youtube-video iframe {
    height: 300px;
  }

  .mrg-none-sm {
    margin-bottom: 0;
  }

  .your-order {
    padding: 30px 25px 45px;
  }

  .most-product-content h4 a {
    font-size: 15px;
  }

  .home-3 .mobile-menu-area .mean-nav ul#nav {
    /* height: 170px; */
    height: max-content;
  }

  .banner-static-2 {
    margin-top: 30px;
  }

  .blog-main-area {
    /* margin-bottom: 60px; */
  }

  .mt-sm-50 {
    margin-top: 50px;
  }

  .product-details .new-book-area.mt-60 {
    margin-bottom: 30px;
  }
}

/* small mobile :320px. */

@media (max-width: 767px) {
  .category-menu.category-menu-1 {
    display: none;
  }

  .category-mb {
    margin-bottom: 5px;
  }

  .category-area h3 {
    padding: 0 17px;
  }

  .category-menu ul li a i.none-sm {
    display: none;
  }

  .category-menu ul li a i.none-lg {
    display: block;
  }

  .account-area {
    text-align: center !important;
  }

  .single-banner {
    margin-bottom: 20px;
    overflow: hidden;
  }

  .banner-area.ptb-30 {
    margin-top: 30px;
    margin-bottom: 0px;
  }

  .slider-active.owl-carousel.owl-theme .owl-controls .owl-nav .owl-prev {
    display: none!important;
  }

  .slider-active.owl-carousel.owl-theme .owl-controls .owl-nav .owl-next {
    display: none!important;
  }

  .slider-content h2 {
    font-size: 30px;
    margin-bottom: 7px;
  }

  .slider-content h3 {
    font-size: 20px;
  }

  .section-title h2 {
    /* font-size: 21px; */
  }

  .tab-menu ul li {
    margin-right: 8px;
  }

  .bestseller-area .bestseller-content {
    float: none;
    padding-right: 0;
    width: 100%;
    margin-bottom: 30px;
  }

  .tab-active.owl-carousel.owl-theme .owl-controls .owl-nav div,
  .post-active.owl-carousel.owl-theme .owl-controls .owl-nav div,
  [class*="carousel__generic"].owl-carousel.owl-theme .owl-controls .owl-nav div {
    display: none!important;
  }

  .product-active-2 {
    margin-bottom: 30px;
  }

  .testimonial-area.ptb-100 {
    padding: 60px 0;
  }

  .twitter-content .twitter-text {
    padding-left: 30px;
  }

  .twitter-content {
    margin-bottom: 30px;
    overflow: hidden;
  }

  .link-follow ul li a {
    margin-bottom: 10px;
  }

  .footer-top-menu ul li::before {
    display: none;
  }

  .footer-top-menu ul li {
    margin-bottom: 0;
    margin-left: 2px;
    margin-right: 3px;
    padding-right: 0;
  }

  .footer-top-menu.bb-2 ul {
    text-align: center;
  }

  .copy-right-area p {
    text-align: center;
  }

  .single-footer {
    text-align: center;
  }

  .footer-top-menu ul li a {
    font-size: 14px;
  }

  .br-2 {
    border-right: none;
  }

  .copy-right-area {
    margin-bottom: 30px;
  }

  .payment-img {
    text-align: center !important;
  }

  .about-img {
    margin-bottom: 30px;
  }

  .single-misson {
    margin-bottom: 30px;
  }

  .single-counter {
    margin-bottom: 30px;
  }

  .single-team {
    /* margin-bottom: 30px; */
  }

  .skill-content h3 {
    font-size: 18px;
  }

  .skill-content p {
    font-size: 15px;
  }

  .skill-progress {
    margin-top: 30px;
  }

  .blog-main-wrapper {
    /* margin-bottom: 40px; */
  }

  .author-destils .author-left {
    float: none;
    margin-bottom: 20px;
    width: 100%;
  }

  .author-destils .author-right {
    float: none;
    margin-top: 0;
    width: 100%;
  }

  .author-destils .author-left .author-description {
    margin-top: 0;
  }

  .sharing-post .share-icon ul li {
    padding-left: 6px;
  }

  .sharing-post .share-text span {
    margin-right: 10px;
  }

  .comment-reply-wrap ul li .public-comment .comment-img {
    margin-right: 6px;
  }

  .comment-reply-wrap ul li .public-comment .public-text .single-comm-top p a {
    padding-left: 0;
  }

  .buttons-cart ul li a,
  .coupon form a {
    padding: 0 12px;
  }

  p.checkout-coupon input[type="text"] {
    width: 145px;
  }

  .ship-different-title label {
    font-size: 15px;
    padding-right: 0;
  }

  .your-order h3 {
    font-size: 26px;
  }

  .your-order-table table .order-total th {
    font-size: 14px;
  }

  .payment-accordion .panel-title a {
    font-size: 15px;
  }

  .contact-form {
    margin-top: 30px;
  }

  .login-form .single-login a {
    margin-right: 5px;
    padding: 10px 24px;
  }

  .wishlist-title h2 {
    font-size: 25px;
  }

  .product-info-main .page-title h1 {
    font-size: 28px;
  }

  .product-info-main {
    margin-top: 30px;
  }

  .reviews-actions {
    margin-left: 6px;
  }

  .product-info-area .valu > ul {
    padding-left: 0;
  }

  .review-right {
    margin-top: 20px;
    padding-left: 0;
  }

  .review-form .single-form form {
    padding-left: 0;
  }

  .review-form .single-form form input {
    width: 100%;
  }

  .review-form {
    padding-left: 0;
  }

  .review-form .single-form form textarea {
    margin-left: 0;
    width: 100%;
  }

  .product-details .section-title.text-center h3 {
    font-size: 25px;
  }

  .product-details .new-book-area.mt-60 {
    margin-bottom: 30px;
  }

  .tab-3 ul li::before {
    display: none;
  }

  .shop-tab {
    float: none;
    margin-bottom: 20px;
  }

  .toolbar-sorter span {
    font-size: 12px;
  }

  .toolbar-sorter a {
    font-size: 17px;
    padding-left: 0;
  }

  .toolbar-sorter {
    float: left;
    margin-right: 0;
  }

  .control span {
    font-size: 13px;
  }

  .control select {
    min-width: 40px;
  }

  .shop .product-wrapper {
    float: none;
    margin-bottom: 30px;
    width: 100%;
  }

  .product-wrapper-2 {
    margin-bottom: 30px;
  }

  .product-wrapper-content {
    margin-bottom: 30px;
  }

  .home-2 .slider-active {
    float: none;
    width: 100%;
  }

  .home-2 .slider-content p {
    display: none;
  }

  .home-2 .slider-content h3 {
    display: none;
  }

  .home-2 .slider-content h1 {
    display: block;
    font-size: 20px;
  }

  .home-2 .slider-content a {
    margin-top: 10px;
  }

  .banner-area-5.mtb-70 {
    margin: 30px 0;
  }

  .deal-off-day {
    float: none;
    padding: 0;
    width: 100%;
  }

  .bestseller-content h2 {
    font-size: 45px;
  }

  .deal-off-day h2 a {
    font-size: 30px;
  }

  .timer .cdown {
    margin: 0 4px;
  }

  .timer .cdown span {
    width: 55px;
    font-size: 22px;
  }

  .timer .cdown::before {
    right: -10px;
  }

  .home-2 .banner-img-3 {
    float: none;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
  }

  .home-2 .banner-area.ptb-30 {
    margin-top: 0;
  }

  .home-3 {
    background-image: none;
  }

  .home-3 .header-top-area,
  .home-3 .header-mid-area,
  .banner-area-4 {
    margin: 0;
  }

  .home-3 .slider-area {
    margin: 0;
  }

  .banner-total .single-banner-7 {
    float: none;
    padding-right: 0;
    width: 100%;
  }

  .banner-total .single-banner-3 {
    float: none;
    padding-left: 0;
    width: 100%;
    padding-right: 0;
  }

  .banner-total-2 .single-banner-4 {
    float: none;
    padding-right: 0;
    width: 100%;
  }

  .banner-total-2 .single-banner-5 {
    float: none;
    padding-left: 0;
    width: 100%;
  }

  .home-3 .product-link {
    padding: 0 18px;
  }

  .logo-area {
    text-align: center;
  }

  .home-4 .slider-content h2 {
    font-size: 27px;
  }

  .home-4 .slider-content a {
    margin-top: 0px;
  }

  .home-4 .banner-static-2 {
    margin-top: 30px;
  }

  .home-4 .category-menu ul li .left-menu {
    display: none;
  }

  .home-4 .banner-area-5 {
    margin-top: 30px;
  }

  .home-4 .single-banner-4 {
    float: none;
    padding-right: 0;
    width: 100%;
  }

  .home-4 .single-banner-5 {
    float: none;
    padding-left: 0;
    width: 100%;
  }

  .timer-2 .cdown span {
    width: 55px;
  }

  .product-total-3 {
    float: none;
    padding-right: 0;
    width: 100%;
    margin-bottom: 30px;
  }

  .tab-menu-2 ul li a {
    font-size: 15px;
    margin-right: 6px;
  }

  .home-5 .slider-content a {
    margin-top: 0;
  }

  .home-5 .banner-area.ptb-30 {
    margin-top: 0;
  }

  .home-6 .single-banner-4 {
    float: none;
    padding-right: 0;
    width: 100%;
  }

  .home-6 .single-banner-5 {
    float: none;
    padding-left: 0;
    width: 100%;
  }

  .home-6 .banner-img-3 {
    float: none;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .block-newsletter-2 .container-inner h2 {
    font-size: 15px;
    margin-bottom: 10px;
    text-align: left;
  }

  .block-newsletter-2 .newsletter-content form input {
    width: 100%;
  }

  .block-newsletter-2 .newsletter-content form a {
    margin-left: 0;
    margin-top: 10px;
  }

  .home-6 .slider-content h2 {
    font-size: 25px;
    margin-bottom: 7px;
  }

  .logo-xs-mrg {
    margin: 20px 0 15px;
  }

  .header-mid-area .my-cart {
    display: flex;
    float: none;
    justify-content: center;
    margin-top: 0;
  }

  .mini-cart-sub {
    left: 50%;
    transform: translateX(-50%);
  }

  .header-mid-area .my-cart ul li a {
    padding: 0;
  }

  .mrg-none-xs {
    margin: 0;
  }

  .slider-content h1 {
    font-size: 45px;
  }

  .slider-content a {
    font-size: 18px;
    margin-top: 20px;
    padding: 10px 25px;
  }

  .slider-content.slider-content-2 h1 {
    font-size: 23px;
  }

  .pb-130 {
    padding-bottom: 100px;
  }

  .pt-125 {
    padding-top: 94px;
  }

  .pt-215 {
    padding-top: 114px;
  }

  .modal-body {
    padding: 30px 20px;
  }

  #productModal .modal-dialog {
    width: 100%;
  }

  .modal-pro-content {
    margin-top: 30px;
  }

  .modal-pro-content h3 {
    font-size: 35px;
  }

  .modal-pro-content form button {
    margin-left: 30px;
  }

  .tab-menu ul li {
    margin: 0 6px 10px;
  }

  .tab-menu ul li a {
    font-size: 16px;
  }

  .tab-active.owl-carousel .owl-nav button,
  .post-active.owl-carousel .owl-nav button,
  [class*="carousel__generic"].owl-carousel .owl-nav button,
  .bestseller-active.owl-carousel .owl-nav button,
  .tab-active-3.owl-carousel .owl-nav button,
  .hot-sell-active.owl-carousel .owl-nav button {
    font-size: 30px;
    height: 40px;
    width: 40px;
  }

  .tab-active.owl-carousel .owl-nav button i,
  .post-active.owl-carousel .owl-nav button i,
  [class*="carousel__generic"].owl-carousel .owl-nav button i,
  .bestseller-active.owl-carousel .owl-nav button i,
  .tab-active-3.owl-carousel .owl-nav button i,
  .hot-sell-active.owl-carousel .owl-nav button i {
    top: -4px;
  }

  .banner-area-5 .banner-img-2 a {
    height: 100px;
  }

  .banner-area-5 .banner-img-2 a img {
    height: 100%;
  }

  .banner-area-5 .banner-text h3 {
    font-size: 12px;
    text-align: center;
  }

  .banner-area-5 .banner-text h2 {
    font-size: 18px;
    margin-right: 0;
  }

  .banner-area-5 .banner-text {
    left: 10%;
  }

  .bestseller-active.owl-carousel:hover .owl-nav button.owl-prev {
    left: 0;
  }

  .bestseller-active.owl-carousel:hover .owl-nav button.owl-next {
    right: 0;
  }

  .xs-mb {
    margin-bottom: 30px;
  }

  .testimonial-text p {
    font-size: 17px;
  }

  .post-content h3 a {
    font-size: 19px;
  }

  .twitter-text p {
    font-size: 15px;
  }

  .tab-menu.mb-40 {
    margin-bottom: 20px;
  }

  .pt-95 {
    padding-top: 60px;
  }

  .mtb-95 {
    margin: 30px 0 60px;
  }

  .bestseller-content h1 {
    margin-bottom: 30px;
  }

  .bestseller-area .banner-img-2 {
    float: left;
    margin-bottom: 50px;
    padding-left: 0;
    width: 100%;
  }

  .pb-70 {
    padding-bottom: 40px;
  }

  .pb-100 {
    padding-bottom: 60px;
  }

  .pt-100 {
    padding-top: 60px;
  }

  .section-title.section-title-res {
    margin-bottom: 10px;
  }

  .ptb-100 {
    padding: 60px 0;
  }

  .pt-90 {
    padding-top: 60px;
  }

  .testimonial-text p {
    margin: 25px 0 18px;
  }

  .recent-post-area.pb-90 {
    padding-bottom: 39px;
  }

  .section-title-3 h3 {
    margin-bottom: 20px;
  }

  .slider-content-3.pl-100 {
    padding-left: 15px;
  }

  .slider-content-3 h1 {
    font-size: 46px;
    line-height: 55px;
  }

  .pb-145 {
    padding-bottom: 60px;
  }

  .home-2 .banner-area-5.mtb-100 {
    margin: 40px 0 60px;
  }

  .deal-off-day-title {
    display: block;
    overflow: hidden;
  }

  .deal-off-day h2 {
    margin-top: 15px;
  }

  .deal-off-day {
    text-align: left;
  }

  .bestseller-content h2,
  .bestseller-content p.categories,
  .bestseller-content p,
  .bestseller-content .social-author {
    text-align: left;
  }

  .logo-area.logo-xs-mrg-bottom {
    margin-bottom: 15px;
  }

  .pl-295 {
    padding-left: 15px;
  }

  .pb-225 {
    padding-bottom: 75px;
  }

  .pt-105 {
    padding-top: 80px;
  }

  .ptb-40 {
    padding: 30px 0;
  }

  .pt-85 {
    padding-top: 60px;
  }

  .home-4 .header-search form input,
  .home-5 .header-search form input {
    float: none;
    width: 100%;
  }

  .home-4 .header-search,
  .home-5 .header-search {
    margin-bottom: 22px;
    margin-right: 0;
    margin-top: 20px;
  }

  .slider-content-4.pl-40 {
    padding-left: 15px;
  }

  .home-4 .slider-content-4 h2 {
    font-size: 35px;
  }

  .home-4 .slider-content-4 h1 {
    font-size: 26px;
  }

  .pt-154 {
    padding-top: 85px;
  }

  .pb-154 {
    padding-bottom: 85px;
  }

  .home-4 .banner-area-5 .banner-img-2.for-height a img {
    height: 100%;
  }

  .banner-img-2 a {
    width: 100%;
  }

  .language-area {
    text-align: center;
  }

  .language-area ul > li {
    padding: 9px 0 9px;
  }

  .pt-135 {
    padding-top: 75px;
  }

  .pb-180 {
    padding-bottom: 80px;
  }

  .home-5 .banner-img-3 {
    float: left;
    margin-bottom: 38px;
    margin-top: 40px;
    width: 100%;
  }

  .slider-width {
    margin-left: 0;
    padding: 0 15px;
    width: 100%;
  }

  .pb-140 {
    padding-bottom: 132px;
  }

  .mean-container .mean-bar::after {
    font-size: 20px;
    top: 8px;
  }

  .mb-70 {
    margin-bottom: 60px;
  }

  .pagination-area {
    /* margin-top: 30px; */
  }

  .product-info-area.mt-80 {
    margin-top: 30px;
  }

  .product-info-area .section-title.mb-60.mt-60 {
    margin: 22px 0;
  }

  .review-form .single-form form {
    display: block;
    float: none;
  }

  .review-form .single-form label {
    display: block;
    float: none;
    text-align: left;
  }

  .author-destils .author-left .author-img {
    width: 15%;
  }

  .author-destils {
    margin-bottom: 20px;
  }

  .youtube-video iframe {
    height: auto;
  }

  .comment-reply-wrap ul li .public-comment .public-text {
    width: 80%;
  }

  .comment-reply-wrap ul li .public-comment .comment-img {
    width: 15%;
  }

  .comment-reply-wrap ul li .public-comment-2 {
    margin-left: 0;
  }

  .ptb-70 {
    padding: 60px 0;
  }

  .counter-area {
    padding-top: 60px;
    padding-bottom: 30px;
  }

  .team-area {
    /* padding-top: 60px;
        padding-bottom: 30px; */
  }

  .team-title.mb-50 {
    margin-bottom: 30px;
  }

  .your-order {
    padding: 30px 20px 45px;
  }

  .checkbox-form h3 {
    font-size: 25px;
  }

  .coupon-accordion h3:last-child {
    margin: 0 0 0;
  }

  .coupon-area.mb-70 {
    margin-bottom: 25px;
  }

  .coupon form input {
    width: 100%;
  }

  .coupon {
    padding-bottom: 0;
  }

  .cart_totals {
    text-align: left;
  }

  .cart_totals table {
    float: left;
  }

  .login-form {
    padding: 25px 20px;
  }

  .wishlist-share {
    margin: 10px 0;
  }

  .wishlist-table table .product-add-to-cart > a {
    padding: 10px 15px;
    width: 130px;
  }

  .page .entry-header p {
    font-family: "Rufina", serif;
    font-size: 19px;
  }

  .single-slider.pb-100.bg-img {
    padding-bottom: 102px;
  }

  .block-newsletter {
    margin-top: 55px;
    padding: 45px 25px;
  }

  .home-3-main {
    max-width: 100%;
  }

  .mobile-menu-area .mean-nav ul#nav {
    /* height: 252px; */
    height: max-content;
    margin-top: 10px;
    overflow-y: auto;
  }

  .home-3 .mobile-menu-area .mean-nav ul#nav {
    /* height: 170px; */
    height: max-content;
  }

  .flex-direction-nav a {
    margin: -80px 0 0;
  }

  .pos-search .bootstrap-select {
    border-radius: 5px 5px 5px;
    width: 100%;
  }

  .pos-search {
    position: static;
    width: 100%;
    margin-top: 5px;
  }

  .header-bottom-area form input {
    padding-left: 18px;
  }

  .single-slider.slider-h1-2 {
    background-position: 65% center;
  }

  .single-slider.slider-hm4-1 {
    background-position: 80% center;
  }

  .mean-container .mean-bar::after {
    top: 9px;
  }

  .comment-reply-wrap.mt-50 {
    margin-top: 30px;
  }

  .your-order-table table th {
    width: 110px;
  }

  .slider-content h3 {
    color: #333;
  }

  .home-6 .single-slider.slider-hm6-2 {
    background-position: 10% center;
  }

  .single-banner-8 {
    margin-bottom: 30px;
  }

  .blog-main-area {
    /* margin-bottom: 50px; */
  }

  .mt-xs-40 {
    margin-top: 40px;
  }

  .modal-pro-content h3 {
    font-size: 24px;
  }
}

/* Large Mobile :480px. */

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .container {
    max-width: 460px;
  }

  .section-title p {
    font-size: 15px;
  }

  .link-follow ul li {
    margin-right: 4px;
  }

  .review-right {
    margin-top: 0;
    padding-left: 110px;
  }

  .review-form .single-form form input {
    width: 350px;
  }

  .review-form .single-form form textarea {
    width: 350px;
  }

  .banner-area-5 .banner-text h3 {
    font-size: 14px;
  }

  .banner-shadow-hover img {
    width: 100%;
  }

  .single-banner .banner-img {
    flex: 0 0 19px;
    margin-right: 8px;
  }

  .single-banner .banner-text h4 {
    font-size: 15px;
  }

  .banner-area.banner-res-large .col-12 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .home-6 .slider-content-2 {
    margin-right: 0;
  }

  .modal-pro-content {
    margin-top: 0px;
  }

  .modal-pro-content h3 {
    font-size: 24px;
  }
}