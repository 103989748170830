@import './custom_mixins';
@import './newsletter_form';

html {
	@media(max-width: 575.98px) {
		// default font size for mobile
		font-size: 14px;
	}
}

.product-img {
	height: 370px;
	size: cover;
}

.home__section {
	margin-bottom: 5rem;
	&.slider-area {
		margin-top: 0;
	}
	@media(max-width: 991.98px) {
		.obavijesti {
			margin-top: 60px;
		}
	}
	@media(max-width: 575.98px) {
		margin-bottom: 4rem;
	}
}

.owl-carousel {
	.owl-nav {
		button {
			display: flex;
			justify-content: center;
			i {
				top: 0 !important;
				padding: 0;
				align-self: center;
			}
		}
	}
}

.home-5 {
	.header-top-area {
		background: white none repeat scroll 0 0;
		border-bottom: none;
	}

	.header-top-area.alert {
		padding: 2rem 7rem;
		background-color: #f56702;
		*:not(a) {
			color: white;
		}
		a {
			color:#ffec9e
		}
	}

	.header-mid-area {
		background: white;
	}
	.main-menu-area {
		background: #22aeac none repeat scroll 0 0;
	}
	.language-area {
		ul {
			li {
				a {
					color: #666;
					font-size: 15px;
				}
			}
		}
	}
	.language-area {
		ul {
			&.pages {
				& > .close-icon {
					display: none;
				}
				@media(max-width: 384.98px) {
					@include top-bar-menu--pages--hidden;
				}
			}
			li {
				a {
					color: #666;
					font-size: 15px;
					@media(max-width: 575.98px) {font-size: 18px;}
					i {
						font-size: 14px;
						@media(max-width: 575.98px) {font-size: 18px;}
					}
				}
			}
			li.horizontal-menu-divider {
				span {
					border-left: 1px solid #666;
				}
			}
			#top_bar_links_toggle {
				display: none;
				> a {
					> i {
						padding: 0 0 0 8px;
					}
					&:hover {
						color: unset;
					}
				}
				@media(max-width: 384.98px) {
					display: inline-block;
				}
			}
		}
	}
}

.mobile-menu-area {
	body:not(.high__contrast) & {
		ul {
			> li {
				&.active {
				   > a {
					   color: #f47e1e !important;
					}
				}
			}
		}
	}
}

.header-mid-area {
	position: relative;
	.my-cart {
		ul {
			>li {
				>a {
					padding-top: 0;
				}
			}
		}
	}
	.top-bar-menu {
		// a, i {
		// 	// TODO:
		// 	// font-size: 3.5vw !important;
		// }
		div.cms-placeholder {
			display: none;
		}
		#lang_dropdown_toggle {
			&:focus {
				outline: none;
			}
			cursor: pointer;
			padding: 0;
			> a {
				>i {
					color: #000;
				}
			}
			.header-sub {
				width: unset;
				ul {
					li {
						display: inline;
						> a {
							padding: 5px 0;
						}
					}
				}
				@media(max-width: 575.98px) {
					position: fixed;
					top: 0;
					left: 0;
					ul {
						width: 100vw;
						text-align: center;
						li {
							display: inline-block;
							padding: 0 4vw;
							margin: 5px 0;
							a {
								display: inline;
							}
						}
					}
				}
			}
		}
	}
	.menu-area {
		display: flex;
		ul {
			 > li {
				 &.active {
					> a {
						color: #ffb400;
				 	}
					> span {
						color: #ffb400;
					}
				 }
				 &:not(.search-btn):hover{
					 background-color: #f6f6f6;
				 }
				 transition: .3s;
				 padding-right: 16px;
				.sub-menu {
					min-width: max-content;
				}
				> a {
					display: inline-block;
					background: transparent none repeat scroll 0 0;
					color: #000;
					font-size: 18px;
					font-weight: 600;
					line-height: 25px;
					padding: 20px 6px 20px 20px;
					position: relative;
					text-transform: uppercase;
					z-index: 4;
				}
				> .submenu-trigger {
					font-weight: 600;
					color: #000;
					font-size: 18px;
					transition: .3s;
				}
				&:last-child {
					padding-left: 20px;
					margin-right: 0;
				}
				.sub-menu {
					ul {
						li {
							&:last-child {
								padding-left: 0;
							}
						}
					}
				}
				.search-toggle-button {
					margin-top: -5px;
					min-width: 4rem !important;
					&:focus {
						box-shadow: none !important;
					}
					@media(min-width:1200px) {
						min-width: 11.25rem !important;
					}
				}
				.search-toggle-button[aria-expanded=false] {
					span {
						&:first-child {
							display: inline;
						}
						&:last-child {
							display: none;
						}
					}
				}
				.search-toggle-button[aria-expanded=true] {
					span {
						&:last-child {
							display: inline;
						}
						&:first-child {
							display: none;
						}
					}
				}
			}
		}
	}
}
.blue-1 {
	background-color: #5CB9E6 !important;
}
.blue-2 {
	background-color: #15A0E3 !important;
}
.author-destils {
	.author-left {
		.author-description {
			float: left;
			margin-top: 0px;
			overflow: hidden;
			width: 80%;
		}
	}
}
.single-blog-content {
	.blog-single-content {
		p {
			font-size: 18px;
			line-height: 180%;
			font-family: 'Open Sans', sans-serif;
			font-weight: 300;
			color: rgb(85, 83, 83);
			font-size: 18px;
			line-height: 170%;
			font-family: 'Open Sans', sans-serif;
			font-weight: 300;
			color: rgb(85, 83, 83);
		}
	}
	.single-blog-title {
		h3 {
			color: #252525;
			font-size: 30px;
			font-weight: 500;
			transition: .3s;
			text-decoration: none;
			font-family: 'Poppins', serif;
			font-weight: 400;
			margin-bottom: 40px;
			color: #252525;
			font-size: 26px;
			font-weight: 600;
			transition: .3s;
			text-decoration: none;
			font-family: 'Poppins', serif;
			a {
				color: #252525;
				font-size: 26px;
				font-weight: 600;
				transition: .3s;
				text-decoration: none;
				font-family: 'Poppins', serif;
			}
		}
	}
}
.container {
	max-width: 1380px;
}
.line {
	padding: 50px 0;
	border-top: 1px solid silver;
	line-height: 0;
	height: 0;
	width: 100%;
}
.blue-box {
	padding: 25px;
	color: white;
	margin-bottom: 30px;
	transition: background-color ease-in-out .2s;
	background-color: #0261AC;
	a {
		color: white;
		padding-bottom: 5px;
		&:hover {
			color: black !important;
		}
	}
	li {
		&:hover {
			a {
				color: black !important;
			}
			i {
				color: black !important;
			}
		}
		i {
			transition: all .3s ease 0s;
		}
	}
	&:hover {
		background-color: #034f8a;
	}
	h2 {
		color: white;
		opacity: 0.6;
		margin-bottom: 0px;
		min-height: 60px;
	}
}
.peach-box {
	padding: 25px;
	color: white;
	margin-bottom: 30px;
	transition: background-color ease-in-out .2s;
	background-color: salmon;
	a {
		color: white;
		padding-bottom: 5px;
		&:hover {
			color: black !important;
		}
	}
	li {
		&:hover {
			a {
				color: black !important;
			}
			i {
				color: black !important;
			}
		}
		i {
			transition: all .3s ease 0s;
		}
	}
	&:hover {
		background-color: tomato;
	}
	i {
		padding-inline-end: 16px;
	}
	h2 {
		color: white;
		opacity: 0.6;
		margin-bottom: 0px;
		min-height: 60px;
	}
}
.mustard-box {
	padding: 25px;
	color: white;
	margin-bottom: 30px;
	transition: background-color ease-in-out .2s;
	background-color: #F6C244;
	a {
		color: white;
		padding-bottom: 5px;
		&:hover {
			color: black !important;
		}
	}
	li {
		&:hover {
			a {
				color: black !important;
			}
			i {
				color: black !important;
			}
		}
		i {
			transition: all .3s ease 0s;
		}
	}
	&:hover {
		background-color: orange;
	}
	h2 {
		color: white;
		opacity: 0.6;
		// margin-bottom: 0px;
		// min-height: 60px;
	}
}

.pretty-box {
	.post-content {
		border: 1px solid lightgray;
		padding: 5px 30px;
		min-height: 8.5rem;
		font-size: 22px !important;
	}
}
.header-img {
	position: relative;
	z-index: 0;
	background: url(../img/bg/5-1.jpg);
	background-size: cover;
	background-position: center;
	>.text-center {
		position: relative;
		z-index: 1;
	}
	h1 {
		color: white;
		z-index: 1000 !important;
	}
}
.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #000;
	z-index: 1;
	opacity: 0.15;
}
.link-list {
	p {
		color: white;
		font-weight: 600;
		font-size: 20px;
		padding-left: 10px;
		line-height: 175%;
	}
}

.safe-area {
	a {
		background: transparent none repeat scroll 0 0;
		color: #000;
		display: block;
		font-size: 18px;
		font-weight: 600;
		line-height: 25px;
		padding: 20px 20px;
		position: relative;
		text-transform: uppercase;
		z-index: 4;
	}
}
.single-banner {
	.banner-text {
		h4 {
			color: #222;
			font-family: "Poppins", serif;
			font-size: 18px;
			font-weight: 700;
			margin-bottom: 2px;
		}
		p {
			color: #333;
			font-size: 15px;
			font-weight: 400;
			margin-bottom: 0;
		}
	}
}

h1 {
	margin: 0 0 10px;
	font-family: 'Poppins', sans-serif;
	color: #333;
	font-weight: 700;
}
h2 {
	margin: 0 0 10px;
	font-family: 'Poppins', sans-serif;
	color: #333;
	font-weight: 700;
}
h3 {
	margin: 0 0 10px;
	font-family: 'Poppins', sans-serif;
	color: #333;
	font-weight: 700;
}
h4 {
	margin: 0 0 10px;
	font-family: 'Poppins', sans-serif;
	color: #333;
	font-weight: 700;
}
h5 {
	margin: 0 0 10px;
	font-family: 'Poppins', sans-serif;
	color: #333;
	font-weight: 700;
}
h6 {
	margin: 0 0 10px;
	font-family: 'Poppins', sans-serif;
	color: #333;
	font-weight: 700;
}
.product-details {
	a {
		color: #333;
		font-weight: 700;
		line-height: 1.3;
		overflow: hidden;
		padding: 0;
		font-family: 'Poppins', serif;
		font-size: 20px;
		// min-height: 46px;
		display: inline-block;
		margin-bottom: 10px;
    	margin-top: 13px;
		&:hover {
			color: #ffb400;
		}
	}
	h4 {
		a {
			font-size: 20px;
		}

	}
}
.post-content {
	h3 {
		img{
			margin-bottom: 30px;
		}
		a {
			font-weight: 600;
			font-size: 26px;
			line-height: 125%;
			//margin-bottom: 26px;
			// margin-top: 26px;
		}
	}

	h3{
		margin-bottom: 16px !important;
	}

	p {
		// border-bottom: 1px solid #e5e5e5;
		font-size: 17px;
		line-height: 170%;
		color: #777777;
		margin-bottom: 16px !important;
	}
	span.line {
		// border-bottom: 1px solid #e5e5e5;
		border-bottom: none;
	}
	a {
		// margin-top: 30px;
		align-self: center;
	}
	.meta-author{
		a{
			color: white;
		}
	}
}
.tab-menu {
	ul {
		li {
			a {
				font-size: 20px;
			}
		}
	}
}
.single-blog-date {
	margin-top: -30px;
	font-size: 26px;
	margin-bottom: 16px;
}
.meta-event {
	margin-bottom: 20px;
}
i {
	padding-inline-end: 8px;
}
.meta-event-data {
	border: 1px solid #eec0a9;
	padding: 13px;
	text-align: left;
	font-size: 15px;
	margin: 5px 0;
	border-radius: 6px;
	height: 3.3rem;
}
.box {
	.post-content {
		p {
			border-bottom: unset;
			padding-bottom: 0;
		}
		border-bottom: 1px solid #e5e5e5;
		padding-bottom: 45px;
	}
}
.btn-mustard {
	color: #fff;
	background-color: #F6C244;
	border-color: #F6C244;
	&:hover {
		color: #fff;
		background-color: #eeb226;
		border-color: #eeb226;
	}
}
.btn-salmon {
	color: #fff;
	background-color: #EA8777;
	border-color: #EA8777;
	&:hover {
		color: #fff;
		background-color: #e76e5b;
		border-color: #e76e5b;
	}
}

#search-menu {
	position: absolute;
	width: 100%;
	background-color: #0261AC;
	z-index: 3;
	@media (max-width: 991.98px) {
		margin-top: 4rem;
	}
	.search-menu-links {
		& > div{
			text-align: center;
			a {
				font-size: 16px;
			}
		}
	}
}

#search-form {
	.input-group {
		& > .search__input {
			border: 1px solid rgb(246, 245, 244);
			border-radius: 0;
			flex-grow: 1;
		}
		& > .input-group-append {
			& > button {
				border: 0;
				border-radius: 0;
				width: fit-content;
			}
		}
		@media(max-width: 575.98px) {
			@include search-menu__input-group-mobile();
		}
	}
}

#search-form-results-page {
	.input-group {
		& > .search__input {
			border: 1px solid #d4d4d4;
			border-radius: 0;
			flex-grow: 1;
		}
		& > .input-group-append {
			& > button {
				border: 0;
				border-radius: 0;
				width: fit-content;
			}
		}
		@media(max-width: 575.98px) {
			@include search-menu__input-group-mobile($border-color: #d4d4d4);
		}
	}
}

.mobile-menu-area {
	.mean-container {
		.mean-bar {
			display: flex;
			align-items: center;
			height: 4rem;
			// padding: .25rem 0;
			& > .logo {
				flex-grow: 1;
				height: 100%;
			}
			& > .search-toggle-button {
				padding-right: 40px;
			}
		}
	}
}

.single-banner-5 {
	float: left;
	padding-left: 30px;
	width: 32%;
}
.catalogue-banner {
	background-color: #E9CE6E;
	background-image: url(../img/bg/5-1.jpg);
	background-position: bottom left;
	background-repeat: no-repeat;
	background-size: 50%;
	height: 450px;
	padding-left: 53%;
	padding-right: 7%;
	margin-top: 60px;
	margin-bottom: 40px;
	h1 {
		padding-top: 50px;
		padding-bottom: 35px;
		color: white;
	}
	h3 {
		line-height: 150%;
		color: white;
		padding-bottom: 30px;
		font-weight: 300;
		font-size: 24px;
	}
}
.content-area {
	padding-top: 20px;
	margin-bottom: 60px;
	@media(min-width: 575px) {
		padding-top: 60px;
	}
	p, ul, li {
		font-size: 18px;
		line-height: 175%;
		font-weight: 300;
	}

	.cms-content {
		h2 {
			color: #D35400;
		}
		.background-plugin-wrapper h2 {
			color: #333
		}
	}

	.cms-content h1, h2, h3, h4, h5, h6 {
		line-height: 1.5;
	}

	.btn-info{
		color: white !important;
	}

	.news-list {
		h3{
			margin-bottom: 0 !important;
		}
		a{
			margin: 0;
		}
	}

	ul:not(.menu-plugin):not(.news-post-meta):not(.left-menu-list):not(.posts-tag-list):not(.ul-pagination):not(.share-links) {
		margin-bottom: 30px;
		margin-left: 40px;
		list-style: inside;
		list-style-type: none;
		// list-style-type: disc;
		& > li {
			&::before {
				content: "";
				display: inline-block;
				font-family: "FontAwesome";
				font-size: 16px;
				margin-right: 20px;
				color: #D35400;
			}
		}
	}

	.books-list {
		.blog-main-wrapper {
			.row {
				> div {
					@media(max-width: 575.98px) {
						&:nth-child(odd) {
							padding-right: 7.5px;
						}
						&:nth-child(even) {
							padding-left: 7.5px;
						}
					}
				}
			}
		}
		.product-img {
			height: unset;
		}
		.product-details {
			margin-bottom: 0;
		}
	}
	.book-detail {
		.book-link {
			display: inline-block;
			width: fit-content;
			margin-bottom: 1rem;
			> a {
				color: #fff !important;
			}
		}

		.book-tags {
			display: flex;

			& > .product-attribute {
				width: 25%;
			}
		}
	}

	.product-img-list {
		height: 375px;
	}
	.product-details {
		p {
			font-size: 16px;
		}
		a {
			color: #333;
			font-weight: 700;
			line-height: 1.3;
			overflow: hidden;
			padding: 0;
			font-family: 'Poppins', serif;
			font-size: 1.3rem;
			// min-height: 46px;
			display: inline-block;
			margin-bottom: 10px;
			margin-top: 13px;
			&:hover {
				color: #ffb400;
			}
		}
		h4 {
			a {
				font-size: 18px;
			}
		}
	}
	h1 {
		margin-bottom: 30px;
	}
	h2 {
		margin-bottom: 30px;
	}
	h3 {
		margin-bottom: 30px;
	}
	h4 {
		margin-bottom: 30px;
	}
	h5 {
		margin-bottom: 30px;
	}
	h6 {
		margin-bottom: 30px;
	}
	P {
		margin-bottom: 30px;
	}
}

.blog-title-detail {
	margin-bottom: 40px;
	font-weight: 500;
}
.event-meta-detail-wrapper {
	background-color: gold;
	&__default {
		background-color: transparent;
	}
}
.event-meta-detail {
	padding: 30px;
	>.badge-warning {
		font-size: 24px;
	}
}
.event-meta-img {
	img {
		object-fit: cover;
	}
}
.blog-main-wrapper {
	.badge {
		&__big {
			font-size: 100%;
			margin-bottom: 5px;
		}
	}

	.blog-single-share {
		p {
			display: inline-block;
		}
		ul {
			display: inline-block;
			>li {
				display: inline-block;
			}
		}
		font-size: 18px;
	}
	@media(max-width: 991.98px) {
		padding: 0;
	}
	@media(min-width: 992px) {
		&:not(.no-sidebar) {
			padding: 0 20px;
		}
	}
}
.product-main-area {
	.badge {
		&__big {
			font-size: 100%;
			margin-bottom: 5px;
		}
	}
	.product-addto-links {
		a {
			width: 3.125rem;
			height: 2.8125rem;
			i {
				padding: 0;
			}
		}
	}
}
.post_featured_image {
	width: 100%;
	height: 100%;
	background-size: cover;
	&__default {
		height: 500px;
		background-position: center;
	}
}
.news-gallery {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;

	.gallery-item {
		margin: 0 .5%;
		overflow: hidden;
		flex: 0 0 24%;
		margin-bottom: 10px;
		a {
			img {
				transition: .4s;
				&:hover {
					transform: scale(1.1);
				}
			}
		}
	}
}
.file-item {
	font-size: 16px;
	margin-bottom: 10px;
	>i {
		font-size: 20px;
	}
}
.blog-single-share {
	border-top: 1px solid lightgray;
	padding: 25px 0;
	margin-top: 60px;
}
strong {
	font-weight: 600 !important;
	color: black;
}
.product-img-book {
	height: 500px;
}
.list-page-2 {
	p {
		font-size: 16px;
	}
}

.multicolumn-plugin {
	margin-left: -20px;
	margin-right: -20px;

	.columns-col {
		padding: 0 20px 30px 20px;
	}
}

.card-image {
	padding: 60px 60px 20px;;
	min-height: 500px;
	background-color: cornsilk !important;
	background-repeat: no-repeat !important;
	background-size: cover !important;
}
.card-text {
	padding: 60px;
	background-color: cornsilk;
	&.wrapped-image {
		padding: 30px;
		& > .image-wrapper {
			width: 50%;
			height: 500px;
			margin-bottom: 20px;
			& > .overlay {
				position: relative;
			}
			& > .overlay-text {
				top: 0;
				width: 50%;
				padding: 40px 20px;
			}
		}
		& > .card-text-wrapper {
			// padding: 60px;
		}
	}

}
.card-plugin {
	padding-bottom: 60px;
}
#scrollUp {
	.fa.fa-angle-up {
		padding-right: 0;
	}
}
.pagination-area {
	margin-top: 0;
	@media(min-width: 992px) {
		margin-top: 50px;
	}
	li {
		>.active {
			color: #f07c29;
		}
		>.disabled {
			opacity: 0.4;
		}
	}
	.fa-angle-right {
		padding: 0 0 0 8px;
	}
	.fa-angle-left {
		padding: 0 8px 0 0;
	}
}

.lang-active {
	>a {
		color: #22aeac !important;
	}
}
.book-detail-bg {
	background-color: rgb(242, 240, 240);
	padding: 40px;
}
.book-date {
	margin-bottom: 30px;
	font-size: 16px;
	font-weight: 300;
}
.product-attributes {
	margin-bottom: 30px;
}
.book-title {
	margin-bottom: 15px;
	h2 {
		margin-bottom: 0;
		line-height: 135%;
	}
}
.book-author {
	p {
		font-size: 22px;
	}
}
.book-publisher {
	p {
		font-size: 16px;
	}
}
.book-cover {
	img {
		// border: 1px solid #e5e5e5;
	}
}
.separator-dash {
	width: 60px;
	height: 7px;
	background-color: rgb(171, 125, 171);
	margin-bottom: 30px;
}
#post_body.body-catalogue-link-hidden {
	a[href*='pula.zaki.com.hr'] {
		display: none;
	}
}
.obavijesti {
	h3 {
		margin-bottom: 40px;
	}
	p {
		font-size: 1rem;
		line-height: 150%;
		font-weight: 300;
		margin-bottom: 5px;
	}
}
.obavijest-item {
	margin-bottom: 30px;
	border: 1px solid #e5e5e5;
	border: 1px solid #eec0a9;
	border-radius: 10px;
	padding: 25px 30px 15px 30px;
}

#selected_pages {
	.selected-pages-wrapper { // replacement for col-lg-6
		padding: 0 15px;
		flex: 0 0 100%;
		max-width: 100%;
		@media(min-width: 992px) {
			flex: 0 0 50%;
			max-width: 50%;
		}
		& > div {
			background-size: cover;
			min-height: 23.75rem;
			position: relative;
			.selected-pages {
				@media(max-width:499.98px) {
					width: 100%;
                    position: relative;
                    padding: 15px;
                    left: 0;
                    right: 0;
				}
				@media(min-width:500px) and (max-width:600px) {
					width: 70%;
				}
				@media(min-width:992px) and (max-width:1199.98px) {
					width: 60%;
				}
				position: absolute;
				width: 50%;
				top: 0;
				h3 {
					color: white;
					a {
						color: white;
					}
				}
				.link-list {
					p {
						font-size: 16px;
					}
				}
			}
			&.zbirke {
				background-image: url(../img/zbirke.jpg);
				.selected-pages {
					@media(min-width:500px) {
						left: 8%;
					}
				}
			}
			&.ogranci {
				background-image: url(../img/ogranci.jpg);
				.selected-pages {
					@media(min-width:500px) {
						right: 8%;
					}
				}
			}
		}
	}
}

.team-img {
	a {
		&::before {
			background: #FFF none repeat scroll 0 0;
		}
	}
}
#featured_links {
	background-color: #eece5c;
	.featured-link-wrapper {
		padding: 0 15px;
		flex: 0 0 25%;
		max-width: 25%;
		.single-banner {
			.banner-img {
				margin-top: 1px;
				color: #fff;
			}
			.banner-text {
				color: #fff;
				p, h4, a {
					color: #fff;
				}
			}
		}
	}
}

.icon-banner-wrapper {
	&__border {
		border: 1px solid #8080802b;
		border-radius: 15px;
		padding-left: 6px;
		padding-right: 6px;

		.icon-banner {
			.banner-img {
				padding-top: 8%;
			}
			.icon-banner-text {
				padding-bottom: 8%;
			}
		}
	}

	.icon-banner {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;

		.banner-img {
			flex-basis: 50%;
			display: flex;
    		justify-content: center;
    		align-items: start;
		}

		.icon-banner-text {
			text-align: center;
			flex-basis: 50%;
			display: flex;
    		justify-content: center;
    		align-items: center;
			padding-left: 5px;
			padding-right: 5px;
			p, h4 {
				margin: 0;
			}
			a {
				margin: 0;
				color: #666;
			}
		}
	}
}

.slider-content {
	width: 100%;
	padding-left: 0;
	@media(min-width:400px) and (max-width:767.98px) {
		width: 85%;
	}
	@media(min-width:768px) and (max-width:991px) {
		width: 65%;
	}
	@media(min-width:576px) and (max-width:1499px) {
		padding-left: 4rem;
	}
	h3 {
		color: #fff;
		font-size: 30px;
		font-weight: 400;
		line-height: 1.5;
		text-transform: none;
		font-family: 'Poppins', serif;
		font-weight: 400;
	}
	a {
		&:hover {
			background: #f56702 !important;
			border: unset;
			box-shadow: unset;
			transition: unset;
		}
		background: #f07c29 none repeat scroll 0 0;
		border-radius: 4px;
		color: #fff;
		display: inline-block;
		font-size: 18px;
		font-weight: 400;
		line-height: 35px;
		margin-top: 13px;
		padding: 5px 20px;
		text-transform: uppercase;
		vertical-align: top;
		border: none;
		box-shadow: none;
		font-weight: 600;
	}
	h2 {
		color: #333;
		font-size: 70px;
		font-weight: 700;
		line-height: 1;
		margin-bottom: 25px;
		text-transform: none;
		font-family: 'Poppins', serif;
	}
}
.slider-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #000;
	opacity: 0.15;
}
.single-slider {
	height: 37.5rem;
}
#books-slider {
	.tab-menu {
		.nav {
			li {
				a {
					@media(max-width: 575.98px) {
						font-size: 1.2rem;
					}
				}
			}

			// @media(max-width: 575.98px) {
			// 	white-space: nowrap;
			// 	overflow: scroll;
			// 	flex-wrap: nowrap;
			// 	justify-content: flex-start;
			// }
		}
	}
	.tab-content {
		.book-list-link {
			margin-top: 10px;
			border-bottom: 1px solid #d2d2d2;
		}
	}
	.tab-active.owl-carousel {
		.product-img {
			@media(min-width: 576px) {
				height: unset !important;
			}
			@media(max-width: 479.98px) {
				&::after {
					content: none;
				}
			}
			img {
				@media(max-width: 575.98px) {
					height: 100%;
				}
				width: auto;
				margin: 0 auto;
			}
		}
		.owl-nav {
			button {
				top: 38%;
			}
		}
	}
	.product-wrapper {
		.product-details {
			>h4 {
				>a {
					min-height: 46px;
				}
			}

		}
	}
}
#announcements {
	.obavijesti {
		h3 {
			text-align: center;
		}
	}
	@media(min-width: 992px) {
		.gradient-hr-mustard {
			display: none;
		}
		.obavijesti {
			padding-left: 3rem;
			padding-top: 0;
		}
	}
	@media(max-width: 575.98px) {
		.featured-banners {
			justify-content: center;
			> div {
				max-width: 200px;
				&:nth-child(odd) {
					padding-right: 7.5px;
				}
				&:nth-child(even) {
					padding-left: 7.5px;
				}
				&:nth-child(1), &:nth-child(2) {
					padding-bottom: 15px;
				}
			}
		}
	}
}
#events-section {
	.owl-carousel {
		.owl-nav {
			button {
				top: 23%;
			}
			button.owl-next {
				right: 0;
			}
		}
	}
	.post-content {
		>h3 {
			>a {
				height: 64px;
				overflow: hidden;
			}
		}
		>p {
			border-bottom: none;
			padding-bottom: 0;
			margin-bottom: 25px;
			height: 5.1rem;
			overflow-y: hidden;
			text-overflow: hidden;
		}
		>span {
			border-bottom: 1px solid #e5e5e5;
			display: block;
		}
	}
	.meta-event {
		span {
			color: salmon;
			font-size: 18px;
		}
		i {
			padding-inline-end: 10px;
		}
		>.meta-event-data {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}
	.table-wrapper {
		overflow-x: auto;
		white-space: nowrap;
		width: 100%;
		margin: 0 15px 20px 15px;
		/* Hide scrollbar for Chrome, Safari and Opera */
		&::-webkit-scrollbar {
			display: none;
		}
		/* Hide scrollbar for IE, Edge and Firefox */
		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */

		#incoming_events {
			font-size: 15px;
			font-weight: 300;
			border-collapse: separate;
			border-spacing: 0 8px;
			margin-top: -8px;

			tr {
				&.table-row-yellow {
					background-color: #eece5c;
				}

				td {
					vertical-align: middle;
					padding-top: 20px !important;
					padding-bottom: 20px !important;
					&:first-child {
						border-left-style: none;
						border-top-left-radius: 10px;
						border-bottom-left-radius: 10px;
					}
					&:last-child {
						border-right-style: none;
						border-bottom-right-radius: 10px;
						border-top-right-radius: 10px;
					}
					&.event-meta-1 {
						width: 110px;
					}
					&.event-meta-2 {
						width: 200px;
					}
					&.event-meta-3 {
						width: 250px;
					}
					&.event-title {
						font-size: 18px;
						font-weight: 600;
						a {
							color: #212529;
						}
					}
					span {
						color: salmon;
						font-size: 18px;
						position: relative;
						> i {
							top: .05rem
						}
					}
					.text {
						padding-left: 1.7rem;
					}
				}
			}
		}
	}
}

#e_katalog {
	position: relative;
	.e-katalog-banner {
		position: relative;
		background-image: url(../img/e-katalog-2.jpg);
		background-size: cover;
		min-height: 25rem;
		@media(max-width: 575.98px) {
			min-height: 18rem;
		}
	}
	.e-katalog-box {
		padding: 1.5rem 2rem;
		position: absolute;
		width: 35%;
		top: -1px;
		right: 8%;
		h2 {
			color: #F8F9FA;
			margin-bottom: 1rem;
			@media(max-width: 575.98px) {
				// min-height: 40px;
			}
		}
		p {
			color: #F8F9FA;
			margin-bottom: 1rem;
			font-size: 18px;
			font-weight: 300;
			line-height: 150%;
			@media(max-width: 575.98px) {
				font-size: 16px;
			}
		}
		a {
			font-size: 22px;
			margin-bottom: 1rem;
		}
	}
}
#guides-and-links {
	.owl-carousel.padding-x-15 {
		padding: 0 15px;
	}
}
.selected-pages {
	ul {
		padding-left: 10px;
		font-weight: 600;
		font-size: 16px;
	}
}
#gallery-teaser {
	.galleries-wrapper {
		height: unset;
		& > div {
			&:nth-child(2) {
				& > .row {
					& > div {
						height: 15rem;
						padding-bottom: 15px;
					}
				}
			}
		}
		@media(min-width:768px) and (max-width: 991.98px) {
			& > div {
				&:nth-child(2) {
					& > .row {
						& > div {
							height: 20rem;
						}
					}
				}
			}
		}
		@media(min-width:576px) and (max-width: 991.98px) {
			& > div {
				&:nth-child(2) {
					& > .row {
						& > div {
							&:nth-child(odd) {
								padding-right: 7.5px;
							}
							&:nth-child(even) {
								padding-left: 7.5px;
							}
						}
					}
				}
			}
		}
		@media(min-width: 992px) {
			height: 660px;
			& > div {
				height: 100%;
				&:nth-child(2) {
					& > .row {
						&:nth-child(1) {
							height: 60%;
						}
						&:nth-child(2) {
							height: 40%;
						}
						& > div {
							height: 100%;
							padding-bottom: 0;
							display: flex;
							flex-direction: column;
							justify-content: flex-end;
						}
					}
				}
			}
		}
	}

	.gal-link {
		position: relative;
		height: 100%;
		img {
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
		&:hover {
			.gallery-title {
				opacity: 1;
			}
		}
		@media(min-width: 992px) {
			&.third {
				height: 90%;
			}
			&.fourth {
				height: 90%;
			}
		}
		.gallery-title {
			position: absolute;
			z-index: 1;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			padding: 50px;
			opacity: 0;
			transition: all .3s;
			background: rgba(246, 194, 80, 0.8);
			box-sizing: border-box;
		}
	}
}
.responsive-logo {
	margin: 8px 0px;
	height: 46px !important;
	width: auto;
}
@media(max-width: 576px) {
	.responsive-logo {
        height: 38px !important;
    }
}

.logo-area {
	position: absolute;
	top: 1.25rem;
	left: 1rem;
	z-index: 1;
}
[class*="gradient-hr"] {
	margin-left: auto !important;
	margin-right: auto !important;
	width: 90%;
	height: 1px;
	background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(223,223,223,1) 35%, rgba(255,255,255,1) 100%);
	position: relative;
}
.gradient-hr-mustard {
	background: linear-gradient(90deg, #fff 0%, #f6c244 35%, #fff 100%);
}

footer {
	.footer-top-menu {
		@media(max-width: 767.98px) {
			ul {
				> li {
					margin: 0 10px;
				}
			}
		}
	}
}

@media(max-width: 991px) {
	.event-meta-img {
		height: 400px;
		img {
			max-height: 100%;
			width: 100%;
		}
	}
	#featured_links {
		display: none;
		background-color: #262626;
		padding: 1rem;
	}
	.featured-banners {
		padding: 2rem 0;
	}
}
@media(max-width: 576px) {
	.gallery-item {
		flex: 0 0 32.33%;
	}
}

@media(max-width: 991.98px) {
	.left-col {
		padding: 60px 15px 60px 15px;
	}
	.right-col {
		padding: 60px 15px 60px 15px;
	}
	.sidebar-mobile {
		margin: 50px 0;
		.banner-area {
			> div {
				@media(max-width: 575.98px) {
					&:nth-child(odd) {
						padding-right: 7.5px;
					}
					&:nth-child(even) {
						padding-left: 7.5px;
					}
				}
			}
		}
	}
	.main-blog-wrapper {
		margin-bottom: 0;
	}
}

@media(min-width: 576px) {
	.featured-banners {
		>div {
			&:not([class*="gradient-hr"]) {
				margin: 15px 0;
			}
		}
	}
}
@media(max-width: 575.98px) {
	#e_katalog {
		.e-katalog-box {
			position: relative;
			top: 0;
			right: 0;
			width: 100%;
		}
	}
}
@media(min-width: 576px) and (max-width: 767.98px) {
	#e_katalog {
		.e-katalog-box {
			width: 75%;
		}
	}
}
@media(min-width: 768px) and (max-width: 991.98px) {
	#e_katalog {
		.e-katalog-box {
			width: 60%;
		}
	}
}
@media(min-width: 992px) and (max-width: 1199.98px) {
	#e_katalog {
		.e-katalog-box {
			width: 50%;
		}
	}
}

#current_acc_state {
	position: fixed; z-index: 2;
    font-size: 20px; color: red !important;
}

.wrapper.accordion-fwd{
    width:100%;
  }

  .accordion-fwd .panel-heading {
    padding: 0;
    border:0;
	border: 1px solid rgb(224, 224, 224);
	border-radius: 6px;
	&.active {
		background-color: cornsilk;
	}
  }
  .accordion-fwd .panel-title>a, .accordion-fwd .panel-title>a:active{
    display: block;
    padding: 16px 16px 12px;
    color: #555;
    font-size: 1.125rem;
    font-weight: 700;
    /* text-transform: uppercase; */
    /* letter-spacing: .0625rem; */
    word-spacing: 3px;
    text-decoration: none;
    line-height: 145%;
  }

  .accordion-fwd .panel-title{
    padding-bottom: 0;
	margin-bottom: 5px;
  }

  .accordion-fwd .panel-body{
    padding-top: 30px;
  }

  .accordion-fwd .panel-heading  a:before {
    font-family: 'Font Awesome\ 5 Free';
    content: "\f078";
    float: right;
    transition: all 0.5s;
    font-weight: 900;
  }
  .accordion-fwd .panel-heading.active a:before {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .accordion-fwd .panel-body {
	  padding-left: 1rem;
  }

  .no-pr{
	  padding-right: 0;
  }

  .no-pl{
	padding-left: 0;
}

.product-rating{
	ul{
		li{
			font-size: 14px;
		}
	}
}

.search-text {
	.highlighted {
		color: #f07c29;
	}
}

.admin-edit-link {
	position: relative;
	font-size: 32px;

	i {
		&.fa-close {
			font-size: 14px;
			color: rgb(72, 72, 72);
			position: absolute;
			top: 6px;
			left: 40px;
		}
	}
}

.menu-plugin {
	list-style: none;

	i {
		top: 4px;
	}
	&__level-2 {
		// padding-left is calculated via js
		a {
			// font size is calculated via js
		}
		i {
			top: 3px;
			// font size is calculated via js
		}
	}

	&__level-3 {
		// padding-left is calculated via js
		a {
			// font size is calculated via js
		}
		i {
			top: 2px;
			// font size is calculated via js
		}
	}
}

.left-menu {
	a {
		&.active {
			color: #f07c29;
			// pointer-events: none;
		}
	}
}

.breadcrumbs-menu {
	a {
		&.active {
			// pointer-events: none;
		}
	}
}

.blog-tag {
	ul {
		li  {
			.tag-active {
				background: #F07C29 none repeat scroll 0 0;
				border: 1px solid #F07C29;
				color: #fff;
			}
		}
	}
}

.btn-mustard {
	&.text-light {
		&:hover {
			color: #fff !important;
		}
	}
}

.background-plugin-wrapper {
	padding: 30px;
	border-radius: 8px;
	margin-bottom: 30px;
}
