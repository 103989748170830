@charset "UTF-8";

#mc_embed_signup input {
  border: 1px solid #ABB0B2;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

#mc_embed_signup input:focus {
  border-color: #333;
}

#mc_embed_signup .button {
  clear: both;
  background-color: #aaa;
  border: 0 none;
  border-radius: 4px;
  transition: all 0.23s ease-in-out 0s;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: normal;
  height: 32px;
  line-height: 32px;
  margin: 0 5px 10px 0;
  padding: 0 22px;
  text-align: center;
  text-decoration: none;
  vertical-align: top;
  white-space: nowrap;
  width: auto;
}

#mc_embed_signup .button:hover {
  background-color: #777;
}

#mc_embed_signup .small-meta {
  font-size: 11px;
}

#mc_embed_signup .nowrap {
  white-space: nowrap;
}

#mc_embed_signup .mc-field-group {
  clear: left;
  position: relative;
  width: 96%;
  padding-bottom: 3%;
  min-height: 50px;
}

#mc_embed_signup .size1of2 {
  clear: none;
  float: left;
  display: inline-block;
  width: 46%;
  margin-right: 4%;
}

* html #mc_embed_signup .size1of2 {
  margin-right: 2%;
  /* Fix for IE6 double margins. */
}

#mc_embed_signup .mc-field-group label {
  display: block;
  margin-bottom: 3px;
}

#mc_embed_signup .mc-field-group input {
  display: block;
  width: 100%;
  padding: 8px 0;
  text-indent: 2%;
}

#mc_embed_signup div#mce-responses {
  float: left;
  top: -1.4em;
  padding: 0em .5em 0em .5em;
  overflow: hidden;
  width: 90%;
  margin: 0 5%;
  clear: both;
}

#mc_embed_signup div.response {
  margin: 1em 0;
  padding: 1em .5em .5em 0;
  font-weight: bold;
  float: left;
  top: -1.5em;
  z-index: 1;
  width: 80%;
}

#mc_embed_signup #mce-error-response {
  display: none;
}

#mc_embed_signup #mce-success-response {
  color: #529214;
  display: none;
}

#mc_embed_signup label.error {
  display: block;
  float: none;
  width: auto;
  margin-left: 1.05em;
  text-align: left;
  padding: .5em 0;
}

#mc-embedded-subscribe {
  clear: both;
  width: auto;
  display: block;
  margin: 1em 0 1em 5%;
}

#mc_embed_signup #num-subscribers {
  font-size: 1.1em;
}

#mc_embed_signup #num-subscribers span {
  padding: .5em;
  border: 1px solid #ccc;
  margin-right: .5em;
  font-weight: bold;
}

#mc_embed_signup #mc-embedded-subscribe-form div.mce_inline_error {
  display: inline-block;
  margin: 2px 0 1em 0;
  padding: 5px 10px;
  background-color: rgba(255, 255, 255, 0.85);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: normal;
  z-index: 1;
  color: #e85c41;
}

#mc_embed_signup #mc-embedded-subscribe-form input.mce_inline_error {
  border: 2px solid #e85c41;
}

#mc_embed_signup {
  background: #fff;
  clear: left;
  font: 14px Helvetica,Arial,sans-serif;
}

#mc_embed_signup .indicates-required {
  text-align: right;
  font-size: 11px;
  margin-right: 4%;
}

#mc_embed_signup .asterisk {
  color: #e85c41;
  font-size: 150%;
  font-weight: normal;
  position: relative;
  top: 5px;
}

#mc_embed_signup .clear {
  clear: both;
}

#mc_embed_signup #mc-embedded-subscribe-form {
  padding: 0;
}

#mc_embed_signup #mc-embedded-subscribe-form h2 {
  margin-top: 0;
  font-size: 32px;
  margin-bottom: 40px;
}

@media (min-width: 992px) {
  #mc_embed_signup #mc-embedded-subscribe-form input[type=email] {
    width: 50%;
  }
}

#mc_embed_signup #mc-embedded-subscribe-form input[type=checkbox] {
  display: inline;
  width: auto;
  margin-right: 10px;
}

#mc_embed_signup #mc-embedded-subscribe-form input[type=submit] {
  background-color: #f6c244;
  border: 1px solid #f6c244;
  color: #f8f9fa;
  margin: 0;
}

#mc_embed_signup #mc-embedded-subscribe-form div.mce_inline_error {
  margin: 0 !important;
  padding: 5px 0 0 0 !important;
}

#mc_embed_signup #mc-embedded-subscribe-form #mce-responses {
  padding: 0 !important;
  margin: 0 !important;
}

#mc_embed_signup #mc-embedded-subscribe-form #mergeRow-gdpr {
  margin-top: 10px;
  padding-bottom: 0;
}

#mc_embed_signup #mc-embedded-subscribe-form #mergeRow-gdpr .content__gdpr .confirm-text {
  margin-bottom: 15px;
}

#mc_embed_signup #mc-embedded-subscribe-form #mergeRow-gdpr fieldset {
  border: none;
  min-height: 0px;
  margin-bottom: 30px;
  padding-bottom: 0px;
}

#mc_embed_signup #mc-embedded-subscribe-form #mergeRow-gdpr fieldset label {
  font-weight: bold;
}

@media (max-width: 575.98px) {
  html {
    font-size: 14px;
  }
}

.product-img {
  height: 370px;
  size: cover;
}

.home__section {
  margin-bottom: 5rem;
}

.home__section.slider-area {
  margin-top: 0;
}

@media (max-width: 991.98px) {
  .home__section .obavijesti {
    margin-top: 60px;
  }
}

@media (max-width: 575.98px) {
  .home__section {
    margin-bottom: 4rem;
  }
}

.owl-carousel .owl-nav button {
  display: flex;
  justify-content: center;
}

.owl-carousel .owl-nav button i {
  top: 0 !important;
  padding: 0;
  align-self: center;
}

.home-5 .header-top-area {
  background: white none repeat scroll 0 0;
  border-bottom: none;
}

.home-5 .header-top-area.alert {
  padding: 2rem 7rem;
  background-color: #f56702;
}

.home-5 .header-top-area.alert *:not(a) {
  color: white;
}

.home-5 .header-top-area.alert a {
  color: #ffec9e;
}

.home-5 .header-mid-area {
  background: white;
}

.home-5 .main-menu-area {
  background: #22aeac none repeat scroll 0 0;
}

.home-5 .language-area ul li a {
  color: #666;
  font-size: 15px;
}

.home-5 .language-area ul.pages > .close-icon {
  display: none;
}

@media (max-width: 384.98px) {
  .home-5 .language-area ul.pages {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    background-color: #fff;
    z-index: 1000;
    transition: .3s;
    padding: 11px 0;
  }

  .home-5 .language-area ul.pages.opened {
    opacity: 1;
    visibility: visible;
  }

  .home-5 .language-area ul.pages > .close-icon {
    display: inline-block;
  }
}

.home-5 .language-area ul li a {
  color: #666;
  font-size: 15px;
}

@media (max-width: 575.98px) {
  .home-5 .language-area ul li a {
    font-size: 18px;
  }
}

.home-5 .language-area ul li a i {
  font-size: 14px;
}

@media (max-width: 575.98px) {
  .home-5 .language-area ul li a i {
    font-size: 18px;
  }
}

.home-5 .language-area ul li.horizontal-menu-divider span {
  border-left: 1px solid #666;
}

.home-5 .language-area ul #top_bar_links_toggle {
  display: none;
}

.home-5 .language-area ul #top_bar_links_toggle > a > i {
  padding: 0 0 0 8px;
}

.home-5 .language-area ul #top_bar_links_toggle > a:hover {
  color: unset;
}

@media (max-width: 384.98px) {
  .home-5 .language-area ul #top_bar_links_toggle {
    display: inline-block;
  }
}

body:not(.high__contrast) .mobile-menu-area ul > li.active > a {
  color: #f47e1e !important;
}

.header-mid-area {
  position: relative;
}

.header-mid-area .my-cart ul > li > a {
  padding-top: 0;
}

.header-mid-area .top-bar-menu div.cms-placeholder {
  display: none;
}

.header-mid-area .top-bar-menu #lang_dropdown_toggle {
  cursor: pointer;
  padding: 0;
}

.header-mid-area .top-bar-menu #lang_dropdown_toggle:focus {
  outline: none;
}

.header-mid-area .top-bar-menu #lang_dropdown_toggle > a > i {
  color: #000;
}

.header-mid-area .top-bar-menu #lang_dropdown_toggle .header-sub {
  width: unset;
}

.header-mid-area .top-bar-menu #lang_dropdown_toggle .header-sub ul li {
  display: inline;
}

.header-mid-area .top-bar-menu #lang_dropdown_toggle .header-sub ul li > a {
  padding: 5px 0;
}

@media (max-width: 575.98px) {
  .header-mid-area .top-bar-menu #lang_dropdown_toggle .header-sub {
    position: fixed;
    top: 0;
    left: 0;
  }

  .header-mid-area .top-bar-menu #lang_dropdown_toggle .header-sub ul {
    width: 100vw;
    text-align: center;
  }

  .header-mid-area .top-bar-menu #lang_dropdown_toggle .header-sub ul li {
    display: inline-block;
    padding: 0 4vw;
    margin: 5px 0;
  }

  .header-mid-area .top-bar-menu #lang_dropdown_toggle .header-sub ul li a {
    display: inline;
  }
}

.header-mid-area .menu-area {
  display: flex;
}

.header-mid-area .menu-area ul > li {
  transition: .3s;
  padding-right: 16px;
}

.header-mid-area .menu-area ul > li.active > a {
  color: #ffb400;
}

.header-mid-area .menu-area ul > li.active > span {
  color: #ffb400;
}

.header-mid-area .menu-area ul > li:not(.search-btn):hover {
  background-color: #f6f6f6;
}

.header-mid-area .menu-area ul > li .sub-menu {
  min-width: max-content;
}

.header-mid-area .menu-area ul > li > a {
  display: inline-block;
  background: transparent none repeat scroll 0 0;
  color: #000;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  padding: 20px 6px 20px 20px;
  position: relative;
  text-transform: uppercase;
  z-index: 4;
}

.header-mid-area .menu-area ul > li > .submenu-trigger {
  font-weight: 600;
  color: #000;
  font-size: 18px;
  transition: .3s;
}

.header-mid-area .menu-area ul > li:last-child {
  padding-left: 20px;
  margin-right: 0;
}

.header-mid-area .menu-area ul > li .sub-menu ul li:last-child {
  padding-left: 0;
}

.header-mid-area .menu-area ul > li .search-toggle-button {
  margin-top: -5px;
  min-width: 4rem !important;
}

.header-mid-area .menu-area ul > li .search-toggle-button:focus {
  box-shadow: none !important;
}

@media (min-width: 1200px) {
  .header-mid-area .menu-area ul > li .search-toggle-button {
    min-width: 11.25rem !important;
  }
}

.header-mid-area .menu-area ul > li .search-toggle-button[aria-expanded=false] span:first-child {
  display: inline;
}

.header-mid-area .menu-area ul > li .search-toggle-button[aria-expanded=false] span:last-child {
  display: none;
}

.header-mid-area .menu-area ul > li .search-toggle-button[aria-expanded=true] span:last-child {
  display: inline;
}

.header-mid-area .menu-area ul > li .search-toggle-button[aria-expanded=true] span:first-child {
  display: none;
}

.blue-1 {
  background-color: #5CB9E6 !important;
}

.blue-2 {
  background-color: #15A0E3 !important;
}

.author-destils .author-left .author-description {
  float: left;
  margin-top: 0px;
  overflow: hidden;
  width: 80%;
}

.single-blog-content .blog-single-content p {
  font-size: 18px;
  line-height: 180%;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  color: #555353;
  font-size: 18px;
  line-height: 170%;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  color: #555353;
}

.single-blog-content .single-blog-title h3 {
  color: #252525;
  font-size: 30px;
  font-weight: 500;
  transition: .3s;
  text-decoration: none;
  font-family: 'Poppins', serif;
  font-weight: 400;
  margin-bottom: 40px;
  color: #252525;
  font-size: 26px;
  font-weight: 600;
  transition: .3s;
  text-decoration: none;
  font-family: 'Poppins', serif;
}

.single-blog-content .single-blog-title h3 a {
  color: #252525;
  font-size: 26px;
  font-weight: 600;
  transition: .3s;
  text-decoration: none;
  font-family: 'Poppins', serif;
}

.container {
  max-width: 1380px;
}

.line {
  padding: 50px 0;
  border-top: 1px solid silver;
  line-height: 0;
  height: 0;
  width: 100%;
}

.blue-box {
  padding: 25px;
  color: white;
  margin-bottom: 30px;
  transition: background-color ease-in-out .2s;
  background-color: #0261AC;
}

.blue-box a {
  color: white;
  padding-bottom: 5px;
}

.blue-box a:hover {
  color: black !important;
}

.blue-box li:hover a {
  color: black !important;
}

.blue-box li:hover i {
  color: black !important;
}

.blue-box li i {
  transition: all .3s ease 0s;
}

.blue-box:hover {
  background-color: #034f8a;
}

.blue-box h2 {
  color: white;
  opacity: 0.6;
  margin-bottom: 0px;
  min-height: 60px;
}

.peach-box {
  padding: 25px;
  color: white;
  margin-bottom: 30px;
  transition: background-color ease-in-out .2s;
  background-color: salmon;
}

.peach-box a {
  color: white;
  padding-bottom: 5px;
}

.peach-box a:hover {
  color: black !important;
}

.peach-box li:hover a {
  color: black !important;
}

.peach-box li:hover i {
  color: black !important;
}

.peach-box li i {
  transition: all .3s ease 0s;
}

.peach-box:hover {
  background-color: tomato;
}

.peach-box i {
  padding-inline-end: 16px;
}

.peach-box h2 {
  color: white;
  opacity: 0.6;
  margin-bottom: 0px;
  min-height: 60px;
}

.mustard-box {
  padding: 25px;
  color: white;
  margin-bottom: 30px;
  transition: background-color ease-in-out .2s;
  background-color: #F6C244;
}

.mustard-box a {
  color: white;
  padding-bottom: 5px;
}

.mustard-box a:hover {
  color: black !important;
}

.mustard-box li:hover a {
  color: black !important;
}

.mustard-box li:hover i {
  color: black !important;
}

.mustard-box li i {
  transition: all .3s ease 0s;
}

.mustard-box:hover {
  background-color: orange;
}

.mustard-box h2 {
  color: white;
  opacity: 0.6;
}

.pretty-box .post-content {
  border: 1px solid lightgray;
  padding: 5px 30px;
  min-height: 8.5rem;
  font-size: 22px !important;
}

.header-img {
  position: relative;
  z-index: 0;
  background: url("../../img/bg/5-1.jpg");
  background-size: cover;
  background-position: center;
}

.header-img > .text-center {
  position: relative;
  z-index: 1;
}

.header-img h1 {
  color: white;
  z-index: 1000 !important;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 1;
  opacity: 0.15;
}

.link-list p {
  color: white;
  font-weight: 600;
  font-size: 20px;
  padding-left: 10px;
  line-height: 175%;
}

.safe-area a {
  background: transparent none repeat scroll 0 0;
  color: #000;
  display: block;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  padding: 20px 20px;
  position: relative;
  text-transform: uppercase;
  z-index: 4;
}

.single-banner .banner-text h4 {
  color: #222;
  font-family: "Poppins", serif;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 2px;
}

.single-banner .banner-text p {
  color: #333;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 0;
}

h1 {
  margin: 0 0 10px;
  font-family: 'Poppins', sans-serif;
  color: #333;
  font-weight: 700;
}

h2 {
  margin: 0 0 10px;
  font-family: 'Poppins', sans-serif;
  color: #333;
  font-weight: 700;
}

h3 {
  margin: 0 0 10px;
  font-family: 'Poppins', sans-serif;
  color: #333;
  font-weight: 700;
}

h4 {
  margin: 0 0 10px;
  font-family: 'Poppins', sans-serif;
  color: #333;
  font-weight: 700;
}

h5 {
  margin: 0 0 10px;
  font-family: 'Poppins', sans-serif;
  color: #333;
  font-weight: 700;
}

h6 {
  margin: 0 0 10px;
  font-family: 'Poppins', sans-serif;
  color: #333;
  font-weight: 700;
}

.product-details a {
  color: #333;
  font-weight: 700;
  line-height: 1.3;
  overflow: hidden;
  padding: 0;
  font-family: 'Poppins', serif;
  font-size: 20px;
  display: inline-block;
  margin-bottom: 10px;
  margin-top: 13px;
}

.product-details a:hover {
  color: #ffb400;
}

.product-details h4 a {
  font-size: 20px;
}

.post-content h3 img {
  margin-bottom: 30px;
}

.post-content h3 a {
  font-weight: 600;
  font-size: 26px;
  line-height: 125%;
}

.post-content h3 {
  margin-bottom: 16px !important;
}

.post-content p {
  font-size: 17px;
  line-height: 170%;
  color: #777777;
  margin-bottom: 16px !important;
}

.post-content span.line {
  border-bottom: none;
}

.post-content a {
  align-self: center;
}

.post-content .meta-author a {
  color: white;
}

.tab-menu ul li a {
  font-size: 20px;
}

.single-blog-date {
  margin-top: -30px;
  font-size: 26px;
  margin-bottom: 16px;
}

.meta-event {
  margin-bottom: 20px;
}

i {
  padding-inline-end: 8px;
}

.meta-event-data {
  border: 1px solid #eec0a9;
  padding: 13px;
  text-align: left;
  font-size: 15px;
  margin: 5px 0;
  border-radius: 6px;
  height: 3.3rem;
}

.box .post-content {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 45px;
}

.box .post-content p {
  border-bottom: unset;
  padding-bottom: 0;
}

.btn-mustard {
  color: #fff;
  background-color: #F6C244;
  border-color: #F6C244;
}

.btn-mustard:hover {
  color: #fff;
  background-color: #eeb226;
  border-color: #eeb226;
}

.btn-salmon {
  color: #fff;
  background-color: #EA8777;
  border-color: #EA8777;
}

.btn-salmon:hover {
  color: #fff;
  background-color: #e76e5b;
  border-color: #e76e5b;
}

#search-menu {
  position: absolute;
  width: 100%;
  background-color: #0261AC;
  z-index: 3;
}

@media (max-width: 991.98px) {
  #search-menu {
    margin-top: 4rem;
  }
}

#search-menu .search-menu-links > div {
  text-align: center;
}

#search-menu .search-menu-links > div a {
  font-size: 16px;
}

#search-form .input-group > .search__input {
  border: 1px solid #f6f5f4;
  border-radius: 0;
  flex-grow: 1;
}

#search-form .input-group > .input-group-append > button {
  border: 0;
  border-radius: 0;
  width: fit-content;
}

@media (max-width: 575.98px) {
  #search-form .input-group {
    justify-content: flex-end;
  }

  #search-form .input-group > .search__input {
    border: 1px solid #fff;
    width: 100%;
  }

  #search-form .input-group > .input-group-append {
    width: 100%;
  }

  #search-form .input-group > .input-group-append > button {
    width: 50%;
    flex-grow: 1;
  }
}

#search-form-results-page .input-group > .search__input {
  border: 1px solid #d4d4d4;
  border-radius: 0;
  flex-grow: 1;
}

#search-form-results-page .input-group > .input-group-append > button {
  border: 0;
  border-radius: 0;
  width: fit-content;
}

@media (max-width: 575.98px) {
  #search-form-results-page .input-group {
    justify-content: flex-end;
  }

  #search-form-results-page .input-group > .search__input {
    border: 1px solid #d4d4d4;
    width: 100%;
  }

  #search-form-results-page .input-group > .input-group-append {
    width: 100%;
  }

  #search-form-results-page .input-group > .input-group-append > button {
    width: 50%;
    flex-grow: 1;
  }
}

.mobile-menu-area .mean-container .mean-bar {
  display: flex;
  align-items: center;
  height: 4rem;
}

.mobile-menu-area .mean-container .mean-bar > .logo {
  flex-grow: 1;
  height: 100%;
}

.mobile-menu-area .mean-container .mean-bar > .search-toggle-button {
  padding-right: 40px;
}

.single-banner-5 {
  float: left;
  padding-left: 30px;
  width: 32%;
}

.catalogue-banner {
  background-color: #E9CE6E;
  background-image: url("../../img/bg/5-1.jpg");
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 50%;
  height: 450px;
  padding-left: 53%;
  padding-right: 7%;
  margin-top: 60px;
  margin-bottom: 40px;
}

.catalogue-banner h1 {
  padding-top: 50px;
  padding-bottom: 35px;
  color: white;
}

.catalogue-banner h3 {
  line-height: 150%;
  color: white;
  padding-bottom: 30px;
  font-weight: 300;
  font-size: 24px;
}

.content-area {
  padding-top: 20px;
  margin-bottom: 60px;
}

@media (min-width: 575px) {
  .content-area {
    padding-top: 60px;
  }
}

.content-area p,
.content-area ul,
.content-area li {
  font-size: 18px;
  line-height: 175%;
  font-weight: 300;
}

.content-area .cms-content h2 {
  color: #D35400;
}

.content-area .cms-content .background-plugin-wrapper h2 {
  color: #333;
}

.content-area .cms-content h1,
.content-area h2,
.content-area h3,
.content-area h4,
.content-area h5,
.content-area h6 {
  line-height: 1.5;
}

.content-area .btn-info {
  color: white !important;
}

.content-area .news-list h3 {
  margin-bottom: 0 !important;
}

.content-area .news-list a {
  margin: 0;
}

.content-area ul:not(.menu-plugin):not(.news-post-meta):not(.left-menu-list):not(.posts-tag-list):not(.ul-pagination):not(.share-links) {
  margin-bottom: 30px;
  margin-left: 40px;
  list-style: inside;
  list-style-type: none;
}

.content-area ul:not(.menu-plugin):not(.news-post-meta):not(.left-menu-list):not(.posts-tag-list):not(.ul-pagination):not(.share-links) > li::before {
  content: "";
  display: inline-block;
  font-family: "FontAwesome";
  font-size: 16px;
  margin-right: 20px;
  color: #D35400;
}

@media (max-width: 575.98px) {
  .content-area .books-list .blog-main-wrapper .row > div:nth-child(odd) {
    padding-right: 7.5px;
  }

  .content-area .books-list .blog-main-wrapper .row > div:nth-child(even) {
    padding-left: 7.5px;
  }
}

.content-area .books-list .product-img {
  height: unset;
}

.content-area .books-list .product-details {
  margin-bottom: 0;
}

.content-area .book-detail .book-link {
  display: inline-block;
  width: fit-content;
  margin-bottom: 1rem;
}

.content-area .book-detail .book-link > a {
  color: #fff !important;
}

.content-area .book-detail .book-tags {
  display: flex;
}

.content-area .book-detail .book-tags > .product-attribute {
  width: 25%;
}

.content-area .product-img-list {
  height: 375px;
}

.content-area .product-details p {
  font-size: 16px;
}

.content-area .product-details a {
  color: #333;
  font-weight: 700;
  line-height: 1.3;
  overflow: hidden;
  padding: 0;
  font-family: 'Poppins', serif;
  font-size: 1.3rem;
  display: inline-block;
  margin-bottom: 10px;
  margin-top: 13px;
}

.content-area .product-details a:hover {
  color: #ffb400;
}

.content-area .product-details h4 a {
  font-size: 18px;
}

.content-area h1 {
  margin-bottom: 30px;
}

.content-area h2 {
  margin-bottom: 30px;
}

.content-area h3 {
  margin-bottom: 30px;
}

.content-area h4 {
  margin-bottom: 30px;
}

.content-area h5 {
  margin-bottom: 30px;
}

.content-area h6 {
  margin-bottom: 30px;
}

.content-area P {
  margin-bottom: 30px;
}

.blog-title-detail {
  margin-bottom: 40px;
  font-weight: 500;
}

.event-meta-detail-wrapper {
  background-color: gold;
}

.event-meta-detail-wrapper__default {
  background-color: transparent;
}

.event-meta-detail {
  padding: 30px;
}

.event-meta-detail > .badge-warning {
  font-size: 24px;
}

.event-meta-img img {
  object-fit: cover;
}

.blog-main-wrapper .badge__big {
  font-size: 100%;
  margin-bottom: 5px;
}

.blog-main-wrapper .blog-single-share {
  font-size: 18px;
}

.blog-main-wrapper .blog-single-share p {
  display: inline-block;
}

.blog-main-wrapper .blog-single-share ul {
  display: inline-block;
}

.blog-main-wrapper .blog-single-share ul > li {
  display: inline-block;
}

@media (max-width: 991.98px) {
  .blog-main-wrapper {
    padding: 0;
  }
}

@media (min-width: 992px) {
  .blog-main-wrapper:not(.no-sidebar) {
    padding: 0 20px;
  }
}

.product-main-area .badge__big {
  font-size: 100%;
  margin-bottom: 5px;
}

.product-main-area .product-addto-links a {
  width: 3.125rem;
  height: 2.8125rem;
}

.product-main-area .product-addto-links a i {
  padding: 0;
}

.post_featured_image {
  width: 100%;
  height: 100%;
  background-size: cover;
}

.post_featured_image__default {
  height: 500px;
  background-position: center;
}

.news-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.news-gallery .gallery-item {
  margin: 0 .5%;
  overflow: hidden;
  flex: 0 0 24%;
  margin-bottom: 10px;
}

.news-gallery .gallery-item a img {
  transition: .4s;
}

.news-gallery .gallery-item a img:hover {
  transform: scale(1.1);
}

.file-item {
  font-size: 16px;
  margin-bottom: 10px;
}

.file-item > i {
  font-size: 20px;
}

.blog-single-share {
  border-top: 1px solid lightgray;
  padding: 25px 0;
  margin-top: 60px;
}

strong {
  font-weight: 600 !important;
  color: black;
}

.product-img-book {
  height: 500px;
}

.list-page-2 p {
  font-size: 16px;
}

.multicolumn-plugin {
  margin-left: -20px;
  margin-right: -20px;
}

.multicolumn-plugin .columns-col {
  padding: 0 20px 30px 20px;
}

.card-image {
  padding: 60px 60px 20px;
  min-height: 500px;
  background-color: cornsilk !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.card-text {
  padding: 60px;
  background-color: cornsilk;
}

.card-text.wrapped-image {
  padding: 30px;
}

.card-text.wrapped-image > .image-wrapper {
  width: 50%;
  height: 500px;
  margin-bottom: 20px;
}

.card-text.wrapped-image > .image-wrapper > .overlay {
  position: relative;
}

.card-text.wrapped-image > .image-wrapper > .overlay-text {
  top: 0;
  width: 50%;
  padding: 40px 20px;
}

.card-plugin {
  padding-bottom: 60px;
}

#scrollUp .fa.fa-angle-up {
  padding-right: 0;
}

.pagination-area {
  margin-top: 0;
}

@media (min-width: 992px) {
  .pagination-area {
    margin-top: 50px;
  }
}

.pagination-area li > .active {
  color: #f07c29;
}

.pagination-area li > .disabled {
  opacity: 0.4;
}

.pagination-area .fa-angle-right {
  padding: 0 0 0 8px;
}

.pagination-area .fa-angle-left {
  padding: 0 8px 0 0;
}

.lang-active > a {
  color: #22aeac !important;
}

.book-detail-bg {
  background-color: #f2f0f0;
  padding: 40px;
}

.book-date {
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 300;
}

.product-attributes {
  margin-bottom: 30px;
}

.book-title {
  margin-bottom: 15px;
}

.book-title h2 {
  margin-bottom: 0;
  line-height: 135%;
}

.book-author p {
  font-size: 22px;
}

.book-publisher p {
  font-size: 16px;
}

.separator-dash {
  width: 60px;
  height: 7px;
  background-color: #ab7dab;
  margin-bottom: 30px;
}

#post_body.body-catalogue-link-hidden a[href*='pula.zaki.com.hr'] {
  display: none;
}

.obavijesti h3 {
  margin-bottom: 40px;
}

.obavijesti p {
  font-size: 1rem;
  line-height: 150%;
  font-weight: 300;
  margin-bottom: 5px;
}

.obavijest-item {
  margin-bottom: 30px;
  border: 1px solid #e5e5e5;
  border: 1px solid #eec0a9;
  border-radius: 10px;
  padding: 25px 30px 15px 30px;
}

#selected_pages .selected-pages-wrapper {
  padding: 0 15px;
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 992px) {
  #selected_pages .selected-pages-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

#selected_pages .selected-pages-wrapper > div {
  background-size: cover;
  min-height: 23.75rem;
  position: relative;
}

#selected_pages .selected-pages-wrapper > div .selected-pages {
  position: absolute;
  width: 50%;
  top: 0;
}

@media (max-width: 499.98px) {
  #selected_pages .selected-pages-wrapper > div .selected-pages {
    width: 100%;
    position: relative;
    padding: 15px;
    left: 0;
    right: 0;
  }
}

@media (min-width: 500px) and (max-width: 600px) {
  #selected_pages .selected-pages-wrapper > div .selected-pages {
    width: 70%;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  #selected_pages .selected-pages-wrapper > div .selected-pages {
    width: 60%;
  }
}

#selected_pages .selected-pages-wrapper > div .selected-pages h3 {
  color: white;
}

#selected_pages .selected-pages-wrapper > div .selected-pages h3 a {
  color: white;
}

#selected_pages .selected-pages-wrapper > div .selected-pages .link-list p {
  font-size: 16px;
}

#selected_pages .selected-pages-wrapper > div.zbirke {
  background-image: url("../../img/zbirke.jpg");
}

@media (min-width: 500px) {
  #selected_pages .selected-pages-wrapper > div.zbirke .selected-pages {
    left: 8%;
  }
}

#selected_pages .selected-pages-wrapper > div.ogranci {
  background-image: url("../../img/ogranci.jpg");
}

@media (min-width: 500px) {
  #selected_pages .selected-pages-wrapper > div.ogranci .selected-pages {
    right: 8%;
  }
}

.team-img a::before {
  background: #FFF none repeat scroll 0 0;
}

#featured_links {
  background-color: #eece5c;
}

#featured_links .featured-link-wrapper {
  padding: 0 15px;
  flex: 0 0 25%;
  max-width: 25%;
}

#featured_links .featured-link-wrapper .single-banner .banner-img {
  margin-top: 1px;
  color: #fff;
}

#featured_links .featured-link-wrapper .single-banner .banner-text {
  color: #fff;
}

#featured_links .featured-link-wrapper .single-banner .banner-text p,
#featured_links .featured-link-wrapper .single-banner .banner-text h4,
#featured_links .featured-link-wrapper .single-banner .banner-text a {
  color: #fff;
}

.icon-banner-wrapper__border {
  border: 1px solid #8080802b;
  border-radius: 15px;
  padding-left: 6px;
  padding-right: 6px;
}

.icon-banner-wrapper__border .icon-banner .banner-img {
  padding-top: 8%;
}

.icon-banner-wrapper__border .icon-banner .icon-banner-text {
  padding-bottom: 8%;
}

.icon-banner-wrapper .icon-banner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.icon-banner-wrapper .icon-banner .banner-img {
  flex-basis: 50%;
  display: flex;
  justify-content: center;
  align-items: start;
}

.icon-banner-wrapper .icon-banner .icon-banner-text {
  text-align: center;
  flex-basis: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
}

.icon-banner-wrapper .icon-banner .icon-banner-text p,
.icon-banner-wrapper .icon-banner .icon-banner-text h4 {
  margin: 0;
}

.icon-banner-wrapper .icon-banner .icon-banner-text a {
  margin: 0;
  color: #666;
}

.slider-content {
  width: 100%;
  padding-left: 0;
}

@media (min-width: 400px) and (max-width: 767.98px) {
  .slider-content {
    width: 85%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .slider-content {
    width: 65%;
  }
}

@media (min-width: 576px) and (max-width: 1499px) {
  .slider-content {
    padding-left: 4rem;
  }
}

.slider-content h3 {
  color: #fff;
  font-size: 30px;
  font-weight: 400;
  line-height: 1.5;
  text-transform: none;
  font-family: 'Poppins', serif;
  font-weight: 400;
}

.slider-content a {
  background: #f07c29 none repeat scroll 0 0;
  border-radius: 4px;
  color: #fff;
  display: inline-block;
  font-size: 18px;
  font-weight: 400;
  line-height: 35px;
  margin-top: 13px;
  padding: 5px 20px;
  text-transform: uppercase;
  vertical-align: top;
  border: none;
  box-shadow: none;
  font-weight: 600;
}

.slider-content a:hover {
  background: #f56702 !important;
  border: unset;
  box-shadow: unset;
  transition: unset;
}

.slider-content h2 {
  color: #333;
  font-size: 70px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 25px;
  text-transform: none;
  font-family: 'Poppins', serif;
}

.slider-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.15;
}

.single-slider {
  height: 37.5rem;
}

@media (max-width: 575.98px) {
  #books-slider .tab-menu .nav li a {
    font-size: 1.2rem;
  }
}

#books-slider .tab-content .book-list-link {
  margin-top: 10px;
  border-bottom: 1px solid #d2d2d2;
}

@media (min-width: 576px) {
  #books-slider .tab-active.owl-carousel .product-img {
    height: unset !important;
  }
}

@media (max-width: 479.98px) {
  #books-slider .tab-active.owl-carousel .product-img::after {
    content: none;
  }
}

#books-slider .tab-active.owl-carousel .product-img img {
  width: auto;
  margin: 0 auto;
}

@media (max-width: 575.98px) {
  #books-slider .tab-active.owl-carousel .product-img img {
    height: 100%;
  }
}

#books-slider .tab-active.owl-carousel .owl-nav button {
  top: 38%;
}

#books-slider .product-wrapper .product-details > h4 > a {
  min-height: 46px;
}

#announcements .obavijesti h3 {
  text-align: center;
}

@media (min-width: 992px) {
  #announcements .gradient-hr-mustard {
    display: none;
  }

  #announcements .obavijesti {
    padding-left: 3rem;
    padding-top: 0;
  }
}

@media (max-width: 575.98px) {
  #announcements .featured-banners {
    justify-content: center;
  }

  #announcements .featured-banners > div {
    max-width: 200px;
  }

  #announcements .featured-banners > div:nth-child(odd) {
    padding-right: 7.5px;
  }

  #announcements .featured-banners > div:nth-child(even) {
    padding-left: 7.5px;
  }

  #announcements .featured-banners > div:nth-child(1),
  #announcements .featured-banners > div:nth-child(2) {
    padding-bottom: 15px;
  }
}

#events-section .owl-carousel .owl-nav button {
  top: 23%;
}

#events-section .owl-carousel .owl-nav button.owl-next {
  right: 0;
}

#events-section .post-content > h3 > a {
  height: 64px;
  overflow: hidden;
}

#events-section .post-content > p {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 25px;
  height: 5.1rem;
  overflow-y: hidden;
  text-overflow: hidden;
}

#events-section .post-content > span {
  border-bottom: 1px solid #e5e5e5;
  display: block;
}

#events-section .meta-event span {
  color: salmon;
  font-size: 18px;
}

#events-section .meta-event i {
  padding-inline-end: 10px;
}

#events-section .meta-event > .meta-event-data {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#events-section .table-wrapper {
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
  margin: 0 15px 20px 15px;
  /* Hide scrollbar for Chrome, Safari and Opera */
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

#events-section .table-wrapper::-webkit-scrollbar {
  display: none;
}

#events-section .table-wrapper #incoming_events {
  font-size: 15px;
  font-weight: 300;
  border-collapse: separate;
  border-spacing: 0 8px;
  margin-top: -8px;
}

#events-section .table-wrapper #incoming_events tr.table-row-yellow {
  background-color: #eece5c;
}

#events-section .table-wrapper #incoming_events tr td {
  vertical-align: middle;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

#events-section .table-wrapper #incoming_events tr td:first-child {
  border-left-style: none;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

#events-section .table-wrapper #incoming_events tr td:last-child {
  border-right-style: none;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

#events-section .table-wrapper #incoming_events tr td.event-meta-1 {
  width: 110px;
}

#events-section .table-wrapper #incoming_events tr td.event-meta-2 {
  width: 200px;
}

#events-section .table-wrapper #incoming_events tr td.event-meta-3 {
  width: 250px;
}

#events-section .table-wrapper #incoming_events tr td.event-title {
  font-size: 18px;
  font-weight: 600;
}

#events-section .table-wrapper #incoming_events tr td.event-title a {
  color: #212529;
}

#events-section .table-wrapper #incoming_events tr td span {
  color: salmon;
  font-size: 18px;
  position: relative;
}

#events-section .table-wrapper #incoming_events tr td span > i {
  top: .05rem;
}

#events-section .table-wrapper #incoming_events tr td .text {
  padding-left: 1.7rem;
}

#e_katalog {
  position: relative;
}

#e_katalog .e-katalog-banner {
  position: relative;
  background-image: url("../../img/e-katalog-2.jpg");
  background-size: cover;
  min-height: 25rem;
}

@media (max-width: 575.98px) {
  #e_katalog .e-katalog-banner {
    min-height: 18rem;
  }
}

#e_katalog .e-katalog-box {
  padding: 1.5rem 2rem;
  position: absolute;
  width: 35%;
  top: -1px;
  right: 8%;
}

#e_katalog .e-katalog-box h2 {
  color: #F8F9FA;
  margin-bottom: 1rem;
}

#e_katalog .e-katalog-box p {
  color: #F8F9FA;
  margin-bottom: 1rem;
  font-size: 18px;
  font-weight: 300;
  line-height: 150%;
}

@media (max-width: 575.98px) {
  #e_katalog .e-katalog-box p {
    font-size: 16px;
  }
}

#e_katalog .e-katalog-box a {
  font-size: 22px;
  margin-bottom: 1rem;
}

#guides-and-links .owl-carousel.padding-x-15 {
  padding: 0 15px;
}

.selected-pages ul {
  padding-left: 10px;
  font-weight: 600;
  font-size: 16px;
}

#gallery-teaser .galleries-wrapper {
  height: unset;
}

#gallery-teaser .galleries-wrapper > div:nth-child(2) > .row > div {
  height: 15rem;
  padding-bottom: 15px;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row > div {
    height: 20rem;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row > div:nth-child(odd) {
    padding-right: 7.5px;
  }

  #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row > div:nth-child(even) {
    padding-left: 7.5px;
  }
}

@media (min-width: 992px) {
  #gallery-teaser .galleries-wrapper {
    height: 660px;
  }

  #gallery-teaser .galleries-wrapper > div {
    height: 100%;
  }

  #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row:nth-child(1) {
    height: 60%;
  }

  #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row:nth-child(2) {
    height: 40%;
  }

  #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row > div {
    height: 100%;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}

#gallery-teaser .gal-link {
  position: relative;
  height: 100%;
}

#gallery-teaser .gal-link img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

#gallery-teaser .gal-link:hover .gallery-title {
  opacity: 1;
}

@media (min-width: 992px) {
  #gallery-teaser .gal-link.third {
    height: 90%;
  }

  #gallery-teaser .gal-link.fourth {
    height: 90%;
  }
}

#gallery-teaser .gal-link .gallery-title {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 50px;
  opacity: 0;
  transition: all .3s;
  background: rgba(246, 194, 80, 0.8);
  box-sizing: border-box;
}

.responsive-logo {
  margin: 8px 0px;
  height: 46px !important;
  width: auto;
}

@media (max-width: 576px) {
  .responsive-logo {
    height: 38px !important;
  }
}

.logo-area {
  position: absolute;
  top: 1.25rem;
  left: 1rem;
  z-index: 1;
}

[class*="gradient-hr"] {
  margin-left: auto !important;
  margin-right: auto !important;
  width: 90%;
  height: 1px;
  background: linear-gradient(90deg, white 0%, #dfdfdf 35%, white 100%);
  position: relative;
}

.gradient-hr-mustard {
  background: linear-gradient(90deg, #fff 0%, #f6c244 35%, #fff 100%);
}

@media (max-width: 767.98px) {
  footer .footer-top-menu ul > li {
    margin: 0 10px;
  }
}

@media (max-width: 991px) {
  .event-meta-img {
    height: 400px;
  }

  .event-meta-img img {
    max-height: 100%;
    width: 100%;
  }

  #featured_links {
    display: none;
    background-color: #262626;
    padding: 1rem;
  }

  .featured-banners {
    padding: 2rem 0;
  }
}

@media (max-width: 576px) {
  .gallery-item {
    flex: 0 0 32.33%;
  }
}

@media (max-width: 991.98px) {
  .left-col {
    padding: 60px 15px 60px 15px;
  }

  .right-col {
    padding: 60px 15px 60px 15px;
  }

  .sidebar-mobile {
    margin: 50px 0;
  }
}

@media (max-width: 991.98px) and (max-width: 575.98px) {
  .sidebar-mobile .banner-area > div:nth-child(odd) {
    padding-right: 7.5px;
  }

  .sidebar-mobile .banner-area > div:nth-child(even) {
    padding-left: 7.5px;
  }
}

@media (max-width: 991.98px) {
  .main-blog-wrapper {
    margin-bottom: 0;
  }
}

@media (min-width: 576px) {
  .featured-banners > div:not([class*="gradient-hr"]) {
    margin: 15px 0;
  }
}

@media (max-width: 575.98px) {
  #e_katalog .e-katalog-box {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  #e_katalog .e-katalog-box {
    width: 75%;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  #e_katalog .e-katalog-box {
    width: 60%;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  #e_katalog .e-katalog-box {
    width: 50%;
  }
}

#current_acc_state {
  position: fixed;
  z-index: 2;
  font-size: 20px;
  color: red !important;
}

.wrapper.accordion-fwd {
  width: 100%;
}

.accordion-fwd .panel-heading {
  padding: 0;
  border: 0;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
}

.accordion-fwd .panel-heading.active {
  background-color: cornsilk;
}

.accordion-fwd .panel-title > a,
.accordion-fwd .panel-title > a:active {
  display: block;
  padding: 16px 16px 12px;
  color: #555;
  font-size: 1.125rem;
  font-weight: 700;
  /* text-transform: uppercase; */
  /* letter-spacing: .0625rem; */
  word-spacing: 3px;
  text-decoration: none;
  line-height: 145%;
}

.accordion-fwd .panel-title {
  padding-bottom: 0;
  margin-bottom: 5px;
}

.accordion-fwd .panel-body {
  padding-top: 30px;
}

.accordion-fwd .panel-heading a:before {
  font-family: 'Font Awesome\ 5 Free';
  content: "\f078";
  float: right;
  transition: all 0.5s;
  font-weight: 900;
}

.accordion-fwd .panel-heading.active a:before {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
}

.accordion-fwd .panel-body {
  padding-left: 1rem;
}

.no-pr {
  padding-right: 0;
}

.no-pl {
  padding-left: 0;
}

.product-rating ul li {
  font-size: 14px;
}

.search-text .highlighted {
  color: #f07c29;
}

.admin-edit-link {
  position: relative;
  font-size: 32px;
}

.admin-edit-link i.fa-close {
  font-size: 14px;
  color: #484848;
  position: absolute;
  top: 6px;
  left: 40px;
}

.menu-plugin {
  list-style: none;
}

.menu-plugin i {
  top: 4px;
}

.menu-plugin__level-2 i {
  top: 3px;
}

.menu-plugin__level-3 i {
  top: 2px;
}

.left-menu a.active {
  color: #f07c29;
}

.blog-tag ul li .tag-active {
  background: #F07C29 none repeat scroll 0 0;
  border: 1px solid #F07C29;
  color: #fff;
}

.btn-mustard.text-light:hover {
  color: #fff !important;
}

.background-plugin-wrapper {
  padding: 30px;
  border-radius: 8px;
  margin-bottom: 30px;
}