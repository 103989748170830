a, input, textarea, button, *[tabindex="0"] {
    outline: none;
}

body[class*=font__size__], body.high__contrast {
    @mixin _outline-style($offset: false) {
        transition: none;
        // TODO: auto is not working in internet explorer, should
        // be replaced with: outline: 1px solid rgba(0, 0, 255, 0.5) !important;
        outline: 5px auto rgba(0, 0, 255, 0.5) !important;
        @if $offset {
            outline-offset: $offset !important;
        }
        @else {
            outline-offset: 2px !important;
        }
    }
    @mixin focus-outline($offset: false) {
        &:not(.prevent__focus__onclick):focus {
            @include _outline-style($offset);
        }
    }
    @mixin outline($offset: false) {
        &:not(.prevent__focus__onclick) {
            @include _outline-style($offset);
        }
    }

    .in__focus:not(.prevent__focus__onclick) {
        @include outline();
    }

    // specific a elements
    #books-slider {
        .product-details {
            a {
                @include focus-outline($offset: -2px);
            }
        }
    }
    #events-section {
        .post-content {
            a {
                @include focus-outline($offset: -2px);
            }
        }
    }

    & :not(#books-slider):not(#events-section)  {
        a {
            @include focus-outline();
        }
    }

    *[tabindex="0"] {
        @include focus-outline();
    }

    button {
        @include focus-outline();
        &.search-toggle-button:focus {
            outline-offset: 4px;
        }
    }
    input, textarea {
        @include focus-outline();
    }

    // image focusing style (a elements or any other)
    .image__focus {
        // height: 100%;
        // width: 100%;
        &::before {
            content: "";
            height: 100%;
            top: 0;
            z-index: 1;
            transform: scale(0);
            left: 0;
            position: absolute;
            width: 100%;
            opacity: 0;
            // transition: all .3s ease 0s;
        }
        &:not(.prevent__focus__onclick):focus {
            &::before {
                border: 10px solid #0060fd;
                opacity: .5;
                transform: scale(1);
            }
        }
    }

    .owl-carousel {
        .owl-nav {
            button {
                opacity: 1 !important;
                visibility: visible !important;
                &.owl-prev {
                    left: 0;
                }
                &.owl-next {
                    right: 0;
                }
            }
        }
        .owl-dots {
            opacity: 1 !important;
            visibility: visible !important;
        }
    }

    #gallery-teaser {
        .gal-link {
            a:not(.prevent__focus__onclick):focus {
                .gallery-title {
                    opacity: 1;
                    border: 6px solid rgba(0, 96, 253, 0.5);
                }
            }
        }
    }

    .gallery-list {
        .gallery-title {
            @include focus-outline($offset: -1px);
        }
    }

    .product-details {
        & > a {
            @include focus-outline($offset: -1px);
        }
    }

    .product-link {
        a {
            @include focus-outline($offset: -1px);
        }
    }

    .pagination-area {
        ul {
            > li {
               > a {
                    @include focus-outline($offset: -1px);
                }
            }
        }
    }
}
