
#mc_embed_signup input {border: 1px solid #ABB0B2; -webkit-border-radius: 3px; -moz-border-radius: 3px; border-radius: 3px;}

#mc_embed_signup input:focus {border-color:#333;}
#mc_embed_signup .button {clear:both; background-color: #aaa; border: 0 none; border-radius:4px; transition: all 0.23s ease-in-out 0s; color: #FFFFFF; cursor: pointer; display: inline-block; font-size:15px; font-weight: normal; height: 32px; line-height: 32px; margin: 0 5px 10px 0; padding: 0 22px; text-align: center; text-decoration: none; vertical-align: top; white-space: nowrap; width: auto;}
#mc_embed_signup .button:hover {background-color:#777;}
#mc_embed_signup .small-meta {font-size: 11px;}
#mc_embed_signup .nowrap {white-space:nowrap;}

#mc_embed_signup .mc-field-group {clear:left; position:relative; width:96%; padding-bottom:3%; min-height:50px;}
#mc_embed_signup .size1of2 {clear:none; float:left; display:inline-block; width:46%; margin-right:4%;}
* html #mc_embed_signup .size1of2 {margin-right:2%; /* Fix for IE6 double margins. */}
#mc_embed_signup .mc-field-group label {display:block; margin-bottom:3px;}
#mc_embed_signup .mc-field-group input {display:block; width:100%; padding:8px 0; text-indent:2%;}


#mc_embed_signup div#mce-responses {float:left; top:-1.4em; padding:0em .5em 0em .5em; overflow:hidden; width:90%; margin: 0 5%; clear: both;}
#mc_embed_signup div.response {margin:1em 0; padding:1em .5em .5em 0; font-weight:bold; float:left; top:-1.5em; z-index:1; width:80%;}
#mc_embed_signup #mce-error-response {display:none;}
#mc_embed_signup #mce-success-response {color:#529214; display:none;}
#mc_embed_signup label.error {display:block; float:none; width:auto; margin-left:1.05em; text-align:left; padding:.5em 0;}

#mc-embedded-subscribe {clear:both; width:auto; display:block; margin:1em 0 1em 5%;}
#mc_embed_signup #num-subscribers {font-size:1.1em;}
#mc_embed_signup #num-subscribers span {padding:.5em; border:1px solid #ccc; margin-right:.5em; font-weight:bold;}

#mc_embed_signup #mc-embedded-subscribe-form div.mce_inline_error {display:inline-block; margin:2px 0 1em 0; padding:5px 10px; background-color:rgba(255,255,255,0.85); -webkit-border-radius: 3px; -moz-border-radius: 3px; border-radius: 3px; font-size:14px; font-weight:normal; z-index:1; color:#e85c41;}
#mc_embed_signup #mc-embedded-subscribe-form input.mce_inline_error {border:2px solid #e85c41;}

// custom
#mc_embed_signup {
    background: #fff;
    clear: left;
    font: 14px Helvetica,Arial,sans-serif;
    .indicates-required {text-align:right; font-size:11px; margin-right:4%;}
    .asterisk {color:#e85c41; font-size:150%; font-weight:normal; position:relative; top:5px;}
    .clear {clear:both;}

    #mc-embedded-subscribe-form {
        padding: 0;

        h2 {
            margin-top: 0;
            font-size: 32px;
            margin-bottom: 40px;
        }

        input[type=email] {
            @media(min-width: 992px) {
                width: 50%;
            }
        }

        input[type=checkbox] {
            display: inline;
            width: auto;
            margin-right: 10px;
        }

        input[type=submit] {
            background-color: #f6c244;
            border: 1px solid #f6c244;
            color: #f8f9fa;
            margin: 0;
        }

        div.mce_inline_error {
            margin: 0 !important;
            padding: 5px 0 0 0 !important;
        }

        #mce-responses {
            padding: 0 !important;
            margin: 0 !important;
        }

        #mergeRow-gdpr {
            margin-top: 10px;
            padding-bottom: 0;
            .content__gdpr {
                .confirm-text {
                    margin-bottom: 15px;
                }
            }
            fieldset {
                border: none;
                min-height: 0px;
                margin-bottom: 30px;
                padding-bottom: 0px;
                label {
                    font-weight: bold;

                }
            }
        }
    }
}
