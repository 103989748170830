/********** VARIABLES **********/

/********** Colors **********/

// background
$bg-color: rgb(51, 51, 51) !important;
$bg-color-transparent: rgba(51, 51, 51, 0.7) !important;
$bg-color-dark: rgb(17, 17, 17) !important;
$bg-color-dark-transparent: rgba(17, 17, 17, 0.7) !important;

// font
$main-font-color: rgb(255, 255, 255) !important;
$link-color: rgb(255, 255, 0) !important;

// mixins
@mixin hc-link($border: true) {
    color: $link-color;
    background-color: transparent;

    @if $border {
        border-color: $main-font-color;
    }

    &:hover {
        background-color: $bg-color-dark;
    }
}

@mixin filter-background-img {
    background-image: inherit;
    background-size: inherit;
    background-position: inherit;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; height: 100%;
}

body.high__contrast {
    &.grayscale__filter {
        .hc_filter {
            &__grayscale {
                filter: grayscale(1);
                &__bg {
                    filter: grayscale(1);
                    @include filter-background-img();
                }
            }
        }
    }
    &.invert__filter {
        .hc_filter {
            &__invert {
                filter: invert(1);
                &__bg {
                    filter: invert(1);
                    @include filter-background-img();
                }
            }
        }
    }

    background-color: $bg-color;

    .header-mid-area {
        background-color: $bg-color;
        color: $main-font-color;
    }

    .header-sub, .sub-menu {
        background-color: $bg-color;
    }

    :not(*[class*='cms-']) { // exclude as many as possible cms toolbar/structure-content elements
        p, span, h1, h2, h3, h4, h5 {
            color: $main-font-color;
            & > strong {
                color: $main-font-color;
            }
        }

        a {
            &, & > i {
                @include hc-link;
            }
        }
    }

    #scrollUp {
        @include hc-link
    }

    ul {
        li {
            color: $main-font-color;
            a, a > i, a > span {
                color: $link-color;
            }
        }
    }

    .pagination-area ul {
        color: $main-font-color;
    }

    .card-plugin > .card-text {
        background-color: $bg-color;
    }

    // cookie control banner
    #privacy-banner {
        background: $bg-color;
        .banner-buttons {
            .js-cookie-accept { @include hc-link; }
            .js-cookie-settings { @include hc-link; }
        }
        .banner-text {
            color: $main-font-color;
            & > .js-cookie-settings > strong { @include hc-link($border: false); }
        }
    }

    // cookie control modal
    #privacysettings {
        & > .modal-dialog {
            & > .modal-content {
                background: $bg-color;

                & > .modal-header {
                    & > .close {
                        opacity: 1;
                        span {
                            color: $link-color;
                        }
                    }
                }

                & > .modal-body {
                    & > .alert-info {
                        background: $bg-color-dark;
                    }
                    hr {
                        display: none;
                    }
                }

                & > .modal-footer {
                    & > .js-modal-accept { @include hc-link; }
                }
            }
        }
    }

    /********** Accessibility toolbar **********/
    #acc_toolbar {
        & > .options {
            & > li {
                & > button {
                    @include hc-link;
                    background: $bg-color;
                    border-width: 1px;
                    border-color: $link-color;
                }
            }
        }
    }
    #_acc_toggle {
        background: $link-color;
        & > i {
            color: $bg-color;
            &:hover {
                color: $bg-color-dark;
            }
        }
    }
    /********** Accessibility toolbar end **********/

    /********** News list default **********/
    .blog-main-wrapper {
        .product-details {
            .badge-info {
                background-color: $bg-color-dark;
            }
        }
    }
    /********** News list default end **********/

    /********** News detail default **********/
    .blog-main-wrapper {

        .event-meta-detail-wrapper {
            background-color: $bg-color-dark;
            .event-meta-detail {
                .badge-warning {
                    color: $main-font-color;
                    background-color: $bg-color;
                }
            }
        }

        .blog-single-share {
            .fab {
                color: $link-color;
            }
        }
    }
    /********** News detail default end **********/

    /********** News detail books **********/
    .book-detail {
        .product-main-area {
            .book-detail-bg {
                background-color: $bg-color-dark;
            }
            .product-addto-links {
                a {
                    border: 1px solid $link-color;
                }
            }
            .book-link {
                > a {
                    color: $link-color;
                }
            }
        }
	}
    /********** News detail books end **********/

    /**************************** Owl carousel nav buttons ****************************/

    .owl-carousel {
        .owl-nav {
            & > button {
                background-color: $bg-color;
                &:hover {
                    background-color: $bg-color-dark;
                }
                & > i {
                    color: $link-color;
                }
            }
        }
        .owl-dots {
            .owl-dot {
                span {
                    // background-color: $bg-color-dark;
                }
                &.active span {
                    background-color: $link-color;
                }
            }
        }
    }
    /**************************** Owl carousel nav buttons ****************************/


    /************************************ Base ************************************/
    & > header {
        .logo-area {
            img {
                // filter: invert(1);
                content: url('/static/img/logo/gkc-logo-w.png');
            }
        }
        .language-area {
            #lang_dropdown_toggle {
                i {
                    color: $link-color;
                }
            }
            ul {
                &.pages {
                    background-color: $bg-color;
                    & > .close-icon {
                        color: $link-color;
                    }
                }
            }
        }
        .menu-area {
            li {
                &.active {
                    background-color: $bg-color-dark;
                }
            }
            .submenu-trigger {
                color: $link-color;
            }
            li:not(:last-child):hover {
                background-color: $bg-color-dark;
            }
            .search-toggle-button {
                background: $bg-color;
                border-color: $link-color;
                color: $link-color;
                &:hover {
                    background: $bg-color-dark;
                }
                & > span {
                    color: $link-color;
                    & > span {
                        color: $link-color;
                    }
                }
            }
        }

        .mobile-menu-area {
            .mean-nav {
                ul {
                    li {
                        &.active {
                            > a {
                                text-decoration: underline;
                            }
                        }
                        background-color: $bg-color;
                        a, i {
                            background-color: $bg-color;
                        }
                        li {
                            a {
                                opacity: 1;
                                border-top: none;
                            }
                        }
                    }
                }
            }
        }

        #search-menu {
            background-color: $bg-color-dark;
            h5 {
                color: $link-color;
            }
            .input-group {
                .search__input {
                    background-color: $bg-color;
                    color: $link-color;
                    &::placeholder {
                        color: $link-color;
                    }
                }
                .input-group-append {
                    & > button {
                        background-color: $bg-color;
                        &:hover {
                            background-color: $bg-color-dark;
                        }
                        color: $link-color;
                        border: 1px solid $link-color;
                    }
                }
            }
        }
    }
    /************************************ Base ************************************/

    /************************************ Search results page ************************************/
    #search-form-results-page {
        .input-group {
            .search__input {
                background-color: $bg-color;
                color: $link-color;
                &::placeholder {
                    color: $link-color;
                }
            }
            .input-group-append {
                & > button {
                    background-color: $bg-color;
                    &:hover {
                        background-color: $bg-color-dark;
                    }
                    color: $link-color;
                    border: 1px solid $link-color;
                }
            }
        }
    }
    /************************************ Search results page ************************************/

    /************************************ Homepage ************************************/
    & > #featured_links {
        background-color: $bg-color-dark;
        .featured-link-wrapper {
            .single-banner {
                .banner-img {
                    &.link {
                        i {
                            color: $link-color;
                        }
                    }
                }
                a {
                    .banner-text {
                        p, h4 {
                            color: $link-color;
                        }
                    }
                }
            }
        }
    }
    .slider-area {
        .slider-content {
            .text-light {
                background-color: $bg-color-dark-transparent;
            }
            a {
                background-color: $bg-color-dark;
            }
        }
    }
    #announcements {
        .obavijest-item {
            border-color: $main-font-color;
        }
    }
    #e_katalog {
        .e-katalog-box {
            background-color: $bg-color-dark;
            &:hover {background-color: $bg-color-dark;}
            h2 {
                color: $main-font-color;
                opacity: 1;
            }
        }
    }
    #events-section {
        .single-post {
            .post-img {
                .blog-date-time {
                    background-color: $bg-color-dark;
                }
            }
            .post-content {
                .meta-event {
                    .meta-event-data {
                        border-color: $main-font-color;
                        color: $main-font-color;
                    }
                }
            }
        }
        #incoming_events {
            color: $main-font-color;
            tr {
                background-color: $bg-color-dark;
                border-color: $main-font-color;
            }
        }
    }
    #selected_pages {
        .selected-pages-wrapper {
            .selected-pages {
                background-color: $bg-color-dark;
                .link-list {
                    li {
                        &:hover {
                            i {
                                color: $link-color;
                            }
                        }
                    }
                }
            }
        }
    }
    #gallery-teaser {
        .galleries-wrapper {
            .gal-link {
                .gallery-title {
                    background-color: $bg-color-transparent;
                    h2 {
                        color: $link-color;
                    }
                }
            }
        }
    }
    footer {
        .social-links {
            i {
                color: $link-color;
                &:hover {
                    background-color: $bg-color-dark;
                }
            }
        }
    }
    /************************************ Homepage ************************************/




    /********** Fix affected CMS toolbar colors - THIS MUST BE AT THE BOTTOM **********/
    #cms-top .cms-toolbar-item-navigation {
        li {
            &:not(.cms-toolbar-item-navigation-hover) {
                a {
                    &, & span {
                        color: #000 !important;
                    }
                    &:hover {
                        background: transparent !important;
                    }
                }
            }

            &.cms-toolbar-item-navigation-hover {
                & > ul {
                    & > li {
                        & > a {
                            &:hover {
                                background-color: #0bf !important;;
                            }
                        }
                    }
                }
            }
        }
    }
    /*******************************************************************************************/

    #current_acc_state {
        background-color: $bg-color-dark;
        color: $link-color;
    }
}
