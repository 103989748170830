/* Accessibility styles
     toolbar,
     font size
     high contrast
*/

/* How to use:

@include range(range, min-width, max-width) {
    // styles to apply
    ...
}

@parameters:
    range: a) list of 2 integers defining font-size range in which defined styles will be applied; e.g. (20, 22)
           b) an integer defining single font-size in which defined styles will be applied; e.g. 20
    min-width: integer - define minimum window width after which styles will be applied (optional)
    max-width: integer - define maximal window width upon which styles will be applied (optional)

        - if only min-width is passed, styles will be wrapped in @media(min-width: $min-width) {...}
        - if only max-width is passed, styles will be wrapped in @media(max-width: $max-width) {...}
        - if both max- and min- width is applied, styles will be wrapped in @media (min-width: $min-width) and (max-width: $max-width) { ... }


------ EXAMPLE -------

SCSS:

$range-4: (31, 32);

.sidebar-mobile {
    @include range((34, 35)) {
        background-color: rgb(162, 181, 255) !important;
    }
    .left-menu {
        span {
            @include range($range-4, 992, 1200) {
                float: unset;
                padding-left: 30px;
            }
        }
    }
}

#main {
    .left-col {
        @include range((20, 22), $max-width: 567) {
            width: 100%;
        }
    }
    .right-col {
        @include range(20, $min-width: 567) {
            padding: 40px;
        }
    }
}


--> Compiles to:

body.font__size__34 .sidebar-mobile {
    background-color: #a2b5ff !important;
}

body.font__size__35 .sidebar-mobile {
    background-color: #a2b5ff !important;
}

@media (min-width: 992px) and (max-width: 1200px) {
    body.font__size__31 .sidebar-mobile .left-menu span {
        float: unset;
        padding-left: 30px;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    body.font__size__32 .sidebar-mobile .left-menu span {
        float: unset;
        padding-left: 30px;
    }
}

@media (max-width: 567px) {
    body.font__size__20 #main .left-col {
        width: 100%;
    }
}

@media (max-width: 567px) {
    body.font__size__21 #main .left-col {
        width: 100%;
    }
}

@media (max-width: 567px) {
    body.font__size__22 #main .left-col {
        width: 100%;
    }
}

@media (min-width: 567px) {
    body.font__size__20 #main .right-col {
        padding: 40px;
    }
}


--> CSS is further optimized in minification process.

*/

#acc_toolbar {
  transition: bottom .2s;
  position: fixed;
  bottom: 5.2rem;
  right: -3rem;
  opacity: 0;
  z-index: 1002;
}

body.font__size__19 #acc_toolbar {
  bottom: 4.8rem;
}

body.font__size__20 #acc_toolbar {
  bottom: 4.8rem;
}

body.font__size__21 #acc_toolbar {
  bottom: 4.8rem;
}

body.font__size__22 #acc_toolbar {
  bottom: 4.8rem;
}

body.font__size__23 #acc_toolbar {
  bottom: 4.8rem;
}

body.font__size__24 #acc_toolbar {
  bottom: 4.5rem;
}

body.font__size__25 #acc_toolbar {
  bottom: 4.5rem;
}

body.font__size__26 #acc_toolbar {
  bottom: 4.5rem;
}

body.font__size__27 #acc_toolbar {
  bottom: 4.5rem;
}

body.font__size__28 #acc_toolbar {
  bottom: 4.5rem;
}

body.font__size__29 #acc_toolbar {
  bottom: 4.2rem;
}

body.font__size__30 #acc_toolbar {
  bottom: 4.2rem;
}

body.font__size__31 #acc_toolbar {
  bottom: 4.2rem;
}

body.font__size__32 #acc_toolbar {
  bottom: 4.2rem;
}

#acc_toolbar.above-scrollUp {
  bottom: 8.5rem;
}

body.font__size__18 #acc_toolbar.above-scrollUp {
  bottom: 8.1rem;
}

body.font__size__19 #acc_toolbar.above-scrollUp {
  bottom: 8.1rem;
}

body.font__size__20 #acc_toolbar.above-scrollUp {
  bottom: 8.1rem;
}

body.font__size__21 #acc_toolbar.above-scrollUp {
  bottom: 7.9rem;
}

body.font__size__22 #acc_toolbar.above-scrollUp {
  bottom: 7.9rem;
}

body.font__size__23 #acc_toolbar.above-scrollUp {
  bottom: 7.9rem;
}

body.font__size__24 #acc_toolbar.above-scrollUp {
  bottom: 7.9rem;
}

body.font__size__25 #acc_toolbar.above-scrollUp {
  bottom: 7.5rem;
}

body.font__size__26 #acc_toolbar.above-scrollUp {
  bottom: 7.5rem;
}

body.font__size__27 #acc_toolbar.above-scrollUp {
  bottom: 7.5rem;
}

body.font__size__28 #acc_toolbar.above-scrollUp {
  bottom: 7.5rem;
}

body.font__size__29 #acc_toolbar.above-scrollUp {
  bottom: 7.3rem;
}

body.font__size__30 #acc_toolbar.above-scrollUp {
  bottom: 7.3rem;
}

body.font__size__31 #acc_toolbar.above-scrollUp {
  bottom: 7.3rem;
}

body.font__size__32 #acc_toolbar.above-scrollUp {
  bottom: 7.3rem;
}

#acc_toolbar > .options {
  list-style: none;
}

#acc_toolbar > .options > li {
  padding-bottom: 0.625rem;
}

#acc_toolbar > .options > li > button {
  width: 3rem;
  text-align: center;
  -webkit-box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.48);
  box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.48);
}

#acc_toolbar button i {
  padding: 0;
}

#acc_toolbar .acc-btn-disabled {
  pointer-events: none;
  opacity: 0.4;
}

#acc_toolbar .acc-btn-active {
  color: #fff !important;
  background-color: #0e6877 !important;
  border-color: #0e6877 !important;
}

#_acc_toggle {
  transition: bottom .2s;
  position: fixed;
  bottom: 35px;
  right: 12px;
  z-index: 1002;
  border: none;
  background: #fff;
  border-radius: 50%;
  width: fit-content;
  padding: 0;
  height: fit-content;
  -webkit-box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.48);
  box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.48);
}

#_acc_toggle.above-scrollUp {
  bottom: 5.6rem;
}

body.font__size__18 #_acc_toggle.above-scrollUp {
  bottom: 5.2rem;
}

body.font__size__19 #_acc_toggle.above-scrollUp {
  bottom: 5.2rem;
}

body.font__size__20 #_acc_toggle.above-scrollUp {
  bottom: 5.2rem;
}

body.font__size__21 #_acc_toggle.above-scrollUp {
  bottom: 5rem;
}

body.font__size__22 #_acc_toggle.above-scrollUp {
  bottom: 5rem;
}

body.font__size__23 #_acc_toggle.above-scrollUp {
  bottom: 5rem;
}

body.font__size__24 #_acc_toggle.above-scrollUp {
  bottom: 5rem;
}

body.font__size__25 #_acc_toggle.above-scrollUp {
  bottom: 4.7rem;
}

body.font__size__26 #_acc_toggle.above-scrollUp {
  bottom: 4.7rem;
}

body.font__size__27 #_acc_toggle.above-scrollUp {
  bottom: 4.7rem;
}

body.font__size__28 #_acc_toggle.above-scrollUp {
  bottom: 4.7rem;
}

body.font__size__29 #_acc_toggle.above-scrollUp {
  bottom: 4.5rem;
}

body.font__size__30 #_acc_toggle.above-scrollUp {
  bottom: 4.5rem;
}

body.font__size__31 #_acc_toggle.above-scrollUp {
  bottom: 4.5rem;
}

body.font__size__32 #_acc_toggle.above-scrollUp {
  bottom: 4.5rem;
}

#_acc_toggle > i {
  padding-right: 0;
  font-size: 3rem;
  color: #17a2b8;
}

/********** Accessibility font size ranges specific styles **********/

/* How to use:

@include range(range, min-width, max-width) {
    // styles to apply
    ...
}

@parameters:
    range: a) list of 2 integers defining font-size range in which defined styles will be applied; e.g. (20, 22)
           b) an integer defining single font-size in which defined styles will be applied; e.g. 20
    min-width: integer - define minimum window width after which styles will be applied (optional)
    max-width: integer - define maximal window width upon which styles will be applied (optional)

        - if only min-width is passed, styles will be wrapped in @media(min-width: $min-width) {...}
        - if only max-width is passed, styles will be wrapped in @media(max-width: $max-width) {...}
        - if both max- and min- width is applied, styles will be wrapped in @media (min-width: $min-width) and (max-width: $max-width) { ... }


------ EXAMPLE -------

SCSS:

$range-4: (31, 32);

.sidebar-mobile {
    @include range((34, 35)) {
        background-color: rgb(162, 181, 255) !important;
    }
    .left-menu {
        span {
            @include range($range-4, 992, 1200) {
                float: unset;
                padding-left: 30px;
            }
        }
    }
}

#main {
    .left-col {
        @include range((20, 22), $max-width: 567) {
            width: 100%;
        }
    }
    .right-col {
        @include range(20, $min-width: 567) {
            padding: 40px;
        }
    }
}


--> Compiles to:

body.font__size__34 .sidebar-mobile {
    background-color: #a2b5ff !important;
}

body.font__size__35 .sidebar-mobile {
    background-color: #a2b5ff !important;
}

@media (min-width: 992px) and (max-width: 1200px) {
    body.font__size__31 .sidebar-mobile .left-menu span {
        float: unset;
        padding-left: 30px;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    body.font__size__32 .sidebar-mobile .left-menu span {
        float: unset;
        padding-left: 30px;
    }
}

@media (max-width: 567px) {
    body.font__size__20 #main .left-col {
        width: 100%;
    }
}

@media (max-width: 567px) {
    body.font__size__21 #main .left-col {
        width: 100%;
    }
}

@media (max-width: 567px) {
    body.font__size__22 #main .left-col {
        width: 100%;
    }
}

@media (min-width: 567px) {
    body.font__size__20 #main .right-col {
        padding: 40px;
    }
}


--> CSS is further optimized in minification process.

*/

body.font__size__17 .owl-carousel:not(.slider-active) .owl-nav button {
  width: 69px;
  height: 69px;
}

body.font__size__17 .owl-carousel:not(.slider-active) .owl-nav button i {
  font-size: 50px;
}

body.font__size__18 .owl-carousel:not(.slider-active) .owl-nav button {
  width: 69px;
  height: 69px;
}

body.font__size__18 .owl-carousel:not(.slider-active) .owl-nav button i {
  font-size: 50px;
}

body.font__size__19 .owl-carousel:not(.slider-active) .owl-nav button {
  width: 69px;
  height: 69px;
}

body.font__size__19 .owl-carousel:not(.slider-active) .owl-nav button i {
  font-size: 50px;
}

body.font__size__20 .owl-carousel:not(.slider-active) .owl-nav button {
  width: 69px;
  height: 69px;
}

body.font__size__20 .owl-carousel:not(.slider-active) .owl-nav button i {
  font-size: 50px;
}

body.font__size__21 .owl-carousel:not(.slider-active) .owl-nav button {
  width: 69px;
  height: 69px;
}

body.font__size__21 .owl-carousel:not(.slider-active) .owl-nav button i {
  font-size: 50px;
}

body.font__size__22 .owl-carousel:not(.slider-active) .owl-nav button {
  width: 69px;
  height: 69px;
}

body.font__size__22 .owl-carousel:not(.slider-active) .owl-nav button i {
  font-size: 50px;
}

body.font__size__23 .owl-carousel:not(.slider-active) .owl-nav button {
  width: 69px;
  height: 69px;
}

body.font__size__23 .owl-carousel:not(.slider-active) .owl-nav button i {
  font-size: 50px;
}

body.font__size__24 .owl-carousel:not(.slider-active) .owl-nav button {
  width: 69px;
  height: 69px;
}

body.font__size__24 .owl-carousel:not(.slider-active) .owl-nav button i {
  font-size: 50px;
}

body.font__size__25 .owl-carousel:not(.slider-active) .owl-nav button {
  width: 69px;
  height: 69px;
}

body.font__size__25 .owl-carousel:not(.slider-active) .owl-nav button i {
  font-size: 50px;
}

body.font__size__26 .owl-carousel:not(.slider-active) .owl-nav button {
  width: 69px;
  height: 69px;
}

body.font__size__26 .owl-carousel:not(.slider-active) .owl-nav button i {
  font-size: 50px;
}

body.font__size__27 .owl-carousel:not(.slider-active) .owl-nav button {
  width: 69px;
  height: 69px;
}

body.font__size__27 .owl-carousel:not(.slider-active) .owl-nav button i {
  font-size: 50px;
}

body.font__size__28 .owl-carousel:not(.slider-active) .owl-nav button {
  width: 69px;
  height: 69px;
}

body.font__size__28 .owl-carousel:not(.slider-active) .owl-nav button i {
  font-size: 50px;
}

body.font__size__29 .owl-carousel:not(.slider-active) .owl-nav button {
  width: 69px;
  height: 69px;
}

body.font__size__29 .owl-carousel:not(.slider-active) .owl-nav button i {
  font-size: 50px;
}

body.font__size__30 .owl-carousel:not(.slider-active) .owl-nav button {
  width: 69px;
  height: 69px;
}

body.font__size__30 .owl-carousel:not(.slider-active) .owl-nav button i {
  font-size: 50px;
}

body.font__size__31 .owl-carousel:not(.slider-active) .owl-nav button {
  width: 69px;
  height: 69px;
}

body.font__size__31 .owl-carousel:not(.slider-active) .owl-nav button i {
  font-size: 50px;
}

body.font__size__32 .owl-carousel:not(.slider-active) .owl-nav button {
  width: 69px;
  height: 69px;
}

body.font__size__32 .owl-carousel:not(.slider-active) .owl-nav button i {
  font-size: 50px;
}

/********** SIDEBAR **********/

@media (min-width: 992px) and (max-width: 1370px) {
  body.font__size__29 .sidebar-mobile .left-menu span {
    float: unset;
    padding-left: 30px;
  }
}

@media (min-width: 992px) and (max-width: 1370px) {
  body.font__size__30 .sidebar-mobile .left-menu span {
    float: unset;
    padding-left: 30px;
  }
}

@media (min-width: 992px) and (max-width: 1370px) {
  body.font__size__31 .sidebar-mobile .left-menu span {
    float: unset;
    padding-left: 30px;
  }
}

@media (min-width: 992px) and (max-width: 1370px) {
  body.font__size__32 .sidebar-mobile .left-menu span {
    float: unset;
    padding-left: 30px;
  }
}

@media (min-width: 992px) and (max-width: 1230px) {
  body.font__size__25 .sidebar-mobile .left-menu span {
    float: unset;
    padding-left: 30px;
  }
}

@media (min-width: 992px) and (max-width: 1230px) {
  body.font__size__26 .sidebar-mobile .left-menu span {
    float: unset;
    padding-left: 30px;
  }
}

@media (min-width: 992px) and (max-width: 1230px) {
  body.font__size__27 .sidebar-mobile .left-menu span {
    float: unset;
    padding-left: 30px;
  }
}

@media (min-width: 992px) and (max-width: 1230px) {
  body.font__size__28 .sidebar-mobile .left-menu span {
    float: unset;
    padding-left: 30px;
  }
}

@media (min-width: 992px) and (max-width: 1090px) {
  body.font__size__21 .sidebar-mobile .left-menu span {
    float: unset;
    padding-left: 30px;
  }
}

@media (min-width: 992px) and (max-width: 1090px) {
  body.font__size__22 .sidebar-mobile .left-menu span {
    float: unset;
    padding-left: 30px;
  }
}

@media (min-width: 992px) and (max-width: 1090px) {
  body.font__size__23 .sidebar-mobile .left-menu span {
    float: unset;
    padding-left: 30px;
  }
}

@media (min-width: 992px) and (max-width: 1090px) {
  body.font__size__24 .sidebar-mobile .left-menu span {
    float: unset;
    padding-left: 30px;
  }
}

/********** SIDEBAR end **********/

/********** PRIVACY SETTINGS (COOKIE CONTROL) **********/

/********** PRIVACY SETTINGS (COOKIE CONTROL) end **********/

/********** News detail books **********/

body.font__size__21 .product-main-area .book-detail-bg {
  padding-bottom: 20px;
}

body.font__size__22 .product-main-area .book-detail-bg {
  padding-bottom: 20px;
}

body.font__size__23 .product-main-area .book-detail-bg {
  padding-bottom: 20px;
}

body.font__size__24 .product-main-area .book-detail-bg {
  padding-bottom: 20px;
}

body.font__size__25 .product-main-area .book-detail-bg {
  padding-bottom: 20px;
}

body.font__size__26 .product-main-area .book-detail-bg {
  padding-bottom: 20px;
}

body.font__size__27 .product-main-area .book-detail-bg {
  padding-bottom: 20px;
}

body.font__size__28 .product-main-area .book-detail-bg {
  padding-bottom: 20px;
}

body.font__size__29 .product-main-area .book-detail-bg {
  padding-bottom: 20px;
}

body.font__size__30 .product-main-area .book-detail-bg {
  padding-bottom: 20px;
}

body.font__size__31 .product-main-area .book-detail-bg {
  padding-bottom: 20px;
}

body.font__size__32 .product-main-area .book-detail-bg {
  padding-bottom: 20px;
}

/********** News detail books end **********/

/********** Header & menus **********/

@media (max-width: 449.98px) {
  body.font__size__17 .header-mid-area .language-area ul.pages {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    background-color: #fff;
    z-index: 1000;
    transition: .3s;
    padding: 11px 0;
  }

  body.font__size__17 .header-mid-area .language-area ul.pages.opened {
    opacity: 1;
    visibility: visible;
  }

  body.font__size__17 .header-mid-area .language-area ul.pages > .close-icon {
    display: inline-block;
  }
}

@media (max-width: 449.98px) {
  body.font__size__18 .header-mid-area .language-area ul.pages {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    background-color: #fff;
    z-index: 1000;
    transition: .3s;
    padding: 11px 0;
  }

  body.font__size__18 .header-mid-area .language-area ul.pages.opened {
    opacity: 1;
    visibility: visible;
  }

  body.font__size__18 .header-mid-area .language-area ul.pages > .close-icon {
    display: inline-block;
  }
}

@media (max-width: 449.98px) {
  body.font__size__19 .header-mid-area .language-area ul.pages {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    background-color: #fff;
    z-index: 1000;
    transition: .3s;
    padding: 11px 0;
  }

  body.font__size__19 .header-mid-area .language-area ul.pages.opened {
    opacity: 1;
    visibility: visible;
  }

  body.font__size__19 .header-mid-area .language-area ul.pages > .close-icon {
    display: inline-block;
  }
}

@media (max-width: 449.98px) {
  body.font__size__20 .header-mid-area .language-area ul.pages {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    background-color: #fff;
    z-index: 1000;
    transition: .3s;
    padding: 11px 0;
  }

  body.font__size__20 .header-mid-area .language-area ul.pages.opened {
    opacity: 1;
    visibility: visible;
  }

  body.font__size__20 .header-mid-area .language-area ul.pages > .close-icon {
    display: inline-block;
  }
}

@media (max-width: 509.98px) {
  body.font__size__21 .header-mid-area .language-area ul.pages {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    background-color: #fff;
    z-index: 1000;
    transition: .3s;
    padding: 11px 0;
  }

  body.font__size__21 .header-mid-area .language-area ul.pages.opened {
    opacity: 1;
    visibility: visible;
  }

  body.font__size__21 .header-mid-area .language-area ul.pages > .close-icon {
    display: inline-block;
  }
}

@media (max-width: 509.98px) {
  body.font__size__22 .header-mid-area .language-area ul.pages {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    background-color: #fff;
    z-index: 1000;
    transition: .3s;
    padding: 11px 0;
  }

  body.font__size__22 .header-mid-area .language-area ul.pages.opened {
    opacity: 1;
    visibility: visible;
  }

  body.font__size__22 .header-mid-area .language-area ul.pages > .close-icon {
    display: inline-block;
  }
}

@media (max-width: 509.98px) {
  body.font__size__23 .header-mid-area .language-area ul.pages {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    background-color: #fff;
    z-index: 1000;
    transition: .3s;
    padding: 11px 0;
  }

  body.font__size__23 .header-mid-area .language-area ul.pages.opened {
    opacity: 1;
    visibility: visible;
  }

  body.font__size__23 .header-mid-area .language-area ul.pages > .close-icon {
    display: inline-block;
  }
}

@media (max-width: 509.98px) {
  body.font__size__24 .header-mid-area .language-area ul.pages {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    background-color: #fff;
    z-index: 1000;
    transition: .3s;
    padding: 11px 0;
  }

  body.font__size__24 .header-mid-area .language-area ul.pages.opened {
    opacity: 1;
    visibility: visible;
  }

  body.font__size__24 .header-mid-area .language-area ul.pages > .close-icon {
    display: inline-block;
  }
}

@media (max-width: 575.98px) {
  body.font__size__25 .header-mid-area .language-area ul.pages {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    background-color: #fff;
    z-index: 1000;
    transition: .3s;
    padding: 11px 0;
  }

  body.font__size__25 .header-mid-area .language-area ul.pages.opened {
    opacity: 1;
    visibility: visible;
  }

  body.font__size__25 .header-mid-area .language-area ul.pages > .close-icon {
    display: inline-block;
  }
}

@media (max-width: 575.98px) {
  body.font__size__26 .header-mid-area .language-area ul.pages {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    background-color: #fff;
    z-index: 1000;
    transition: .3s;
    padding: 11px 0;
  }

  body.font__size__26 .header-mid-area .language-area ul.pages.opened {
    opacity: 1;
    visibility: visible;
  }

  body.font__size__26 .header-mid-area .language-area ul.pages > .close-icon {
    display: inline-block;
  }
}

@media (max-width: 575.98px) {
  body.font__size__27 .header-mid-area .language-area ul.pages {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    background-color: #fff;
    z-index: 1000;
    transition: .3s;
    padding: 11px 0;
  }

  body.font__size__27 .header-mid-area .language-area ul.pages.opened {
    opacity: 1;
    visibility: visible;
  }

  body.font__size__27 .header-mid-area .language-area ul.pages > .close-icon {
    display: inline-block;
  }
}

@media (max-width: 575.98px) {
  body.font__size__28 .header-mid-area .language-area ul.pages {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    background-color: #fff;
    z-index: 1000;
    transition: .3s;
    padding: 11px 0;
  }

  body.font__size__28 .header-mid-area .language-area ul.pages.opened {
    opacity: 1;
    visibility: visible;
  }

  body.font__size__28 .header-mid-area .language-area ul.pages > .close-icon {
    display: inline-block;
  }
}

@media (max-width: 634.98px) {
  body.font__size__29 .header-mid-area .language-area ul.pages {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    background-color: #fff;
    z-index: 1000;
    transition: .3s;
    padding: 11px 0;
  }

  body.font__size__29 .header-mid-area .language-area ul.pages.opened {
    opacity: 1;
    visibility: visible;
  }

  body.font__size__29 .header-mid-area .language-area ul.pages > .close-icon {
    display: inline-block;
  }
}

@media (max-width: 634.98px) {
  body.font__size__30 .header-mid-area .language-area ul.pages {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    background-color: #fff;
    z-index: 1000;
    transition: .3s;
    padding: 11px 0;
  }

  body.font__size__30 .header-mid-area .language-area ul.pages.opened {
    opacity: 1;
    visibility: visible;
  }

  body.font__size__30 .header-mid-area .language-area ul.pages > .close-icon {
    display: inline-block;
  }
}

@media (max-width: 634.98px) {
  body.font__size__31 .header-mid-area .language-area ul.pages {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    background-color: #fff;
    z-index: 1000;
    transition: .3s;
    padding: 11px 0;
  }

  body.font__size__31 .header-mid-area .language-area ul.pages.opened {
    opacity: 1;
    visibility: visible;
  }

  body.font__size__31 .header-mid-area .language-area ul.pages > .close-icon {
    display: inline-block;
  }
}

@media (max-width: 634.98px) {
  body.font__size__32 .header-mid-area .language-area ul.pages {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    background-color: #fff;
    z-index: 1000;
    transition: .3s;
    padding: 11px 0;
  }

  body.font__size__32 .header-mid-area .language-area ul.pages.opened {
    opacity: 1;
    visibility: visible;
  }

  body.font__size__32 .header-mid-area .language-area ul.pages > .close-icon {
    display: inline-block;
  }
}

@media (max-width: 449.98px) {
  body.font__size__17 .header-mid-area .language-area ul #top_bar_links_toggle {
    display: inline-block;
  }
}

@media (max-width: 449.98px) {
  body.font__size__18 .header-mid-area .language-area ul #top_bar_links_toggle {
    display: inline-block;
  }
}

@media (max-width: 449.98px) {
  body.font__size__19 .header-mid-area .language-area ul #top_bar_links_toggle {
    display: inline-block;
  }
}

@media (max-width: 449.98px) {
  body.font__size__20 .header-mid-area .language-area ul #top_bar_links_toggle {
    display: inline-block;
  }
}

@media (max-width: 509.98px) {
  body.font__size__21 .header-mid-area .language-area ul #top_bar_links_toggle {
    display: inline-block;
  }
}

@media (max-width: 509.98px) {
  body.font__size__22 .header-mid-area .language-area ul #top_bar_links_toggle {
    display: inline-block;
  }
}

@media (max-width: 509.98px) {
  body.font__size__23 .header-mid-area .language-area ul #top_bar_links_toggle {
    display: inline-block;
  }
}

@media (max-width: 509.98px) {
  body.font__size__24 .header-mid-area .language-area ul #top_bar_links_toggle {
    display: inline-block;
  }
}

@media (max-width: 575.98px) {
  body.font__size__25 .header-mid-area .language-area ul #top_bar_links_toggle {
    display: inline-block;
  }
}

@media (max-width: 575.98px) {
  body.font__size__26 .header-mid-area .language-area ul #top_bar_links_toggle {
    display: inline-block;
  }
}

@media (max-width: 575.98px) {
  body.font__size__27 .header-mid-area .language-area ul #top_bar_links_toggle {
    display: inline-block;
  }
}

@media (max-width: 575.98px) {
  body.font__size__28 .header-mid-area .language-area ul #top_bar_links_toggle {
    display: inline-block;
  }
}

@media (max-width: 634.98px) {
  body.font__size__29 .header-mid-area .language-area ul #top_bar_links_toggle {
    display: inline-block;
  }
}

@media (max-width: 634.98px) {
  body.font__size__30 .header-mid-area .language-area ul #top_bar_links_toggle {
    display: inline-block;
  }
}

@media (max-width: 634.98px) {
  body.font__size__31 .header-mid-area .language-area ul #top_bar_links_toggle {
    display: inline-block;
  }
}

@media (max-width: 634.98px) {
  body.font__size__32 .header-mid-area .language-area ul #top_bar_links_toggle {
    display: inline-block;
  }
}

body.font__size__22 .header-mid-area .menu-area {
  padding: .5rem 0;
}

body.font__size__23 .header-mid-area .menu-area {
  padding: .5rem 0;
}

body.font__size__24 .header-mid-area .menu-area {
  padding: .5rem 0;
}

body.font__size__25 .header-mid-area .menu-area {
  padding: .5rem 0;
}

body.font__size__26 .header-mid-area .menu-area {
  padding: .5rem 0;
}

body.font__size__27 .header-mid-area .menu-area {
  padding: .5rem 0;
}

body.font__size__28 .header-mid-area .menu-area {
  padding: .5rem 0;
}

body.font__size__29 .header-mid-area .menu-area {
  padding: .5rem 0;
}

body.font__size__30 .header-mid-area .menu-area {
  padding: .5rem 0;
}

body.font__size__31 .header-mid-area .menu-area {
  padding: .5rem 0;
}

body.font__size__32 .header-mid-area .menu-area {
  padding: .5rem 0;
}

@media (min-width: 1200px) {
  body.font__size__17 .header-mid-area .menu-area ul > li .search-toggle-button {
    min-width: 180px !important;
  }
}

@media (min-width: 1200px) {
  body.font__size__18 .header-mid-area .menu-area ul > li .search-toggle-button {
    min-width: 180px !important;
  }
}

@media (max-width: 1440px) {
  body.font__size__27 .header-mid-area .menu-area ul > li .search-toggle-button[aria-expanded=false] span:first-child > span {
    display: none !important;
  }
}

@media (max-width: 1440px) {
  body.font__size__28 .header-mid-area .menu-area ul > li .search-toggle-button[aria-expanded=false] span:first-child > span {
    display: none !important;
  }
}

@media (max-width: 1440px) {
  body.font__size__29 .header-mid-area .menu-area ul > li .search-toggle-button[aria-expanded=false] span:first-child > span {
    display: none !important;
  }
}

@media (max-width: 1440px) {
  body.font__size__30 .header-mid-area .menu-area ul > li .search-toggle-button[aria-expanded=false] span:first-child > span {
    display: none !important;
  }
}

body.font__size__31 .header-mid-area .menu-area ul > li .search-toggle-button[aria-expanded=false] span:first-child > span {
  display: none !important;
}

body.font__size__32 .header-mid-area .menu-area ul > li .search-toggle-button[aria-expanded=false] span:first-child > span {
  display: none !important;
}

@media (max-width: 767.98px) {
  body.font__size__18 .header-mid-area #search-form .input-group {
    justify-content: flex-end;
  }

  body.font__size__18 .header-mid-area #search-form .input-group > .search__input {
    border: 1px solid #fff;
    width: 100%;
  }

  body.font__size__18 .header-mid-area #search-form .input-group > .input-group-append {
    width: 100%;
  }

  body.font__size__18 .header-mid-area #search-form .input-group > .input-group-append > button {
    width: 50%;
    flex-grow: 1;
  }
}

@media (max-width: 767.98px) {
  body.font__size__19 .header-mid-area #search-form .input-group {
    justify-content: flex-end;
  }

  body.font__size__19 .header-mid-area #search-form .input-group > .search__input {
    border: 1px solid #fff;
    width: 100%;
  }

  body.font__size__19 .header-mid-area #search-form .input-group > .input-group-append {
    width: 100%;
  }

  body.font__size__19 .header-mid-area #search-form .input-group > .input-group-append > button {
    width: 50%;
    flex-grow: 1;
  }
}

@media (max-width: 767.98px) {
  body.font__size__20 .header-mid-area #search-form .input-group {
    justify-content: flex-end;
  }

  body.font__size__20 .header-mid-area #search-form .input-group > .search__input {
    border: 1px solid #fff;
    width: 100%;
  }

  body.font__size__20 .header-mid-area #search-form .input-group > .input-group-append {
    width: 100%;
  }

  body.font__size__20 .header-mid-area #search-form .input-group > .input-group-append > button {
    width: 50%;
    flex-grow: 1;
  }
}

@media (max-width: 767.98px) {
  body.font__size__21 .header-mid-area #search-form .input-group {
    justify-content: flex-end;
  }

  body.font__size__21 .header-mid-area #search-form .input-group > .search__input {
    border: 1px solid #fff;
    width: 100%;
  }

  body.font__size__21 .header-mid-area #search-form .input-group > .input-group-append {
    width: 100%;
  }

  body.font__size__21 .header-mid-area #search-form .input-group > .input-group-append > button {
    width: 50%;
    flex-grow: 1;
  }
}

@media (max-width: 767.98px) {
  body.font__size__22 .header-mid-area #search-form .input-group {
    justify-content: flex-end;
  }

  body.font__size__22 .header-mid-area #search-form .input-group > .search__input {
    border: 1px solid #fff;
    width: 100%;
  }

  body.font__size__22 .header-mid-area #search-form .input-group > .input-group-append {
    width: 100%;
  }

  body.font__size__22 .header-mid-area #search-form .input-group > .input-group-append > button {
    width: 50%;
    flex-grow: 1;
  }
}

@media (max-width: 991.98px) {
  body.font__size__23 .header-mid-area #search-form .input-group {
    justify-content: flex-end;
  }

  body.font__size__23 .header-mid-area #search-form .input-group > .search__input {
    border: 1px solid #fff;
    width: 100%;
  }

  body.font__size__23 .header-mid-area #search-form .input-group > .input-group-append {
    width: 100%;
  }

  body.font__size__23 .header-mid-area #search-form .input-group > .input-group-append > button {
    width: 50%;
    flex-grow: 1;
  }
}

@media (max-width: 991.98px) {
  body.font__size__24 .header-mid-area #search-form .input-group {
    justify-content: flex-end;
  }

  body.font__size__24 .header-mid-area #search-form .input-group > .search__input {
    border: 1px solid #fff;
    width: 100%;
  }

  body.font__size__24 .header-mid-area #search-form .input-group > .input-group-append {
    width: 100%;
  }

  body.font__size__24 .header-mid-area #search-form .input-group > .input-group-append > button {
    width: 50%;
    flex-grow: 1;
  }
}

@media (max-width: 991.98px) {
  body.font__size__25 .header-mid-area #search-form .input-group {
    justify-content: flex-end;
  }

  body.font__size__25 .header-mid-area #search-form .input-group > .search__input {
    border: 1px solid #fff;
    width: 100%;
  }

  body.font__size__25 .header-mid-area #search-form .input-group > .input-group-append {
    width: 100%;
  }

  body.font__size__25 .header-mid-area #search-form .input-group > .input-group-append > button {
    width: 50%;
    flex-grow: 1;
  }
}

@media (max-width: 991.98px) {
  body.font__size__26 .header-mid-area #search-form .input-group {
    justify-content: flex-end;
  }

  body.font__size__26 .header-mid-area #search-form .input-group > .search__input {
    border: 1px solid #fff;
    width: 100%;
  }

  body.font__size__26 .header-mid-area #search-form .input-group > .input-group-append {
    width: 100%;
  }

  body.font__size__26 .header-mid-area #search-form .input-group > .input-group-append > button {
    width: 50%;
    flex-grow: 1;
  }
}

@media (max-width: 991.98px) {
  body.font__size__27 .header-mid-area #search-form .input-group {
    justify-content: flex-end;
  }

  body.font__size__27 .header-mid-area #search-form .input-group > .search__input {
    border: 1px solid #fff;
    width: 100%;
  }

  body.font__size__27 .header-mid-area #search-form .input-group > .input-group-append {
    width: 100%;
  }

  body.font__size__27 .header-mid-area #search-form .input-group > .input-group-append > button {
    width: 50%;
    flex-grow: 1;
  }
}

@media (max-width: 991.98px) {
  body.font__size__28 .header-mid-area #search-form .input-group {
    justify-content: flex-end;
  }

  body.font__size__28 .header-mid-area #search-form .input-group > .search__input {
    border: 1px solid #fff;
    width: 100%;
  }

  body.font__size__28 .header-mid-area #search-form .input-group > .input-group-append {
    width: 100%;
  }

  body.font__size__28 .header-mid-area #search-form .input-group > .input-group-append > button {
    width: 50%;
    flex-grow: 1;
  }
}

@media (max-width: 991.98px) {
  body.font__size__29 .header-mid-area #search-form .input-group {
    justify-content: flex-end;
  }

  body.font__size__29 .header-mid-area #search-form .input-group > .search__input {
    border: 1px solid #fff;
    width: 100%;
  }

  body.font__size__29 .header-mid-area #search-form .input-group > .input-group-append {
    width: 100%;
  }

  body.font__size__29 .header-mid-area #search-form .input-group > .input-group-append > button {
    width: 50%;
    flex-grow: 1;
  }
}

@media (max-width: 1199.98px) {
  body.font__size__30 .header-mid-area #search-form .input-group {
    justify-content: flex-end;
  }

  body.font__size__30 .header-mid-area #search-form .input-group > .search__input {
    border: 1px solid #fff;
    width: 100%;
  }

  body.font__size__30 .header-mid-area #search-form .input-group > .input-group-append {
    width: 100%;
  }

  body.font__size__30 .header-mid-area #search-form .input-group > .input-group-append > button {
    width: 50%;
    flex-grow: 1;
  }
}

@media (max-width: 1199.98px) {
  body.font__size__31 .header-mid-area #search-form .input-group {
    justify-content: flex-end;
  }

  body.font__size__31 .header-mid-area #search-form .input-group > .search__input {
    border: 1px solid #fff;
    width: 100%;
  }

  body.font__size__31 .header-mid-area #search-form .input-group > .input-group-append {
    width: 100%;
  }

  body.font__size__31 .header-mid-area #search-form .input-group > .input-group-append > button {
    width: 50%;
    flex-grow: 1;
  }
}

@media (max-width: 1199.98px) {
  body.font__size__32 .header-mid-area #search-form .input-group {
    justify-content: flex-end;
  }

  body.font__size__32 .header-mid-area #search-form .input-group > .search__input {
    border: 1px solid #fff;
    width: 100%;
  }

  body.font__size__32 .header-mid-area #search-form .input-group > .input-group-append {
    width: 100%;
  }

  body.font__size__32 .header-mid-area #search-form .input-group > .input-group-append > button {
    width: 50%;
    flex-grow: 1;
  }
}

@media (max-width: 767.98px) {
  body.font__size__18 #search-form-results-page .input-group {
    justify-content: flex-end;
  }

  body.font__size__18 #search-form-results-page .input-group > .search__input {
    border: 1px solid #d4d4d4;
    width: 100%;
  }

  body.font__size__18 #search-form-results-page .input-group > .input-group-append {
    width: 100%;
  }

  body.font__size__18 #search-form-results-page .input-group > .input-group-append > button {
    width: 50%;
    flex-grow: 1;
  }
}

@media (max-width: 767.98px) {
  body.font__size__19 #search-form-results-page .input-group {
    justify-content: flex-end;
  }

  body.font__size__19 #search-form-results-page .input-group > .search__input {
    border: 1px solid #d4d4d4;
    width: 100%;
  }

  body.font__size__19 #search-form-results-page .input-group > .input-group-append {
    width: 100%;
  }

  body.font__size__19 #search-form-results-page .input-group > .input-group-append > button {
    width: 50%;
    flex-grow: 1;
  }
}

@media (max-width: 767.98px) {
  body.font__size__20 #search-form-results-page .input-group {
    justify-content: flex-end;
  }

  body.font__size__20 #search-form-results-page .input-group > .search__input {
    border: 1px solid #d4d4d4;
    width: 100%;
  }

  body.font__size__20 #search-form-results-page .input-group > .input-group-append {
    width: 100%;
  }

  body.font__size__20 #search-form-results-page .input-group > .input-group-append > button {
    width: 50%;
    flex-grow: 1;
  }
}

@media (max-width: 767.98px) {
  body.font__size__21 #search-form-results-page .input-group {
    justify-content: flex-end;
  }

  body.font__size__21 #search-form-results-page .input-group > .search__input {
    border: 1px solid #d4d4d4;
    width: 100%;
  }

  body.font__size__21 #search-form-results-page .input-group > .input-group-append {
    width: 100%;
  }

  body.font__size__21 #search-form-results-page .input-group > .input-group-append > button {
    width: 50%;
    flex-grow: 1;
  }
}

@media (max-width: 767.98px) {
  body.font__size__22 #search-form-results-page .input-group {
    justify-content: flex-end;
  }

  body.font__size__22 #search-form-results-page .input-group > .search__input {
    border: 1px solid #d4d4d4;
    width: 100%;
  }

  body.font__size__22 #search-form-results-page .input-group > .input-group-append {
    width: 100%;
  }

  body.font__size__22 #search-form-results-page .input-group > .input-group-append > button {
    width: 50%;
    flex-grow: 1;
  }
}

@media (max-width: 991.98px) {
  body.font__size__23 #search-form-results-page .input-group {
    justify-content: flex-end;
  }

  body.font__size__23 #search-form-results-page .input-group > .search__input {
    border: 1px solid #d4d4d4;
    width: 100%;
  }

  body.font__size__23 #search-form-results-page .input-group > .input-group-append {
    width: 100%;
  }

  body.font__size__23 #search-form-results-page .input-group > .input-group-append > button {
    width: 50%;
    flex-grow: 1;
  }
}

@media (max-width: 991.98px) {
  body.font__size__24 #search-form-results-page .input-group {
    justify-content: flex-end;
  }

  body.font__size__24 #search-form-results-page .input-group > .search__input {
    border: 1px solid #d4d4d4;
    width: 100%;
  }

  body.font__size__24 #search-form-results-page .input-group > .input-group-append {
    width: 100%;
  }

  body.font__size__24 #search-form-results-page .input-group > .input-group-append > button {
    width: 50%;
    flex-grow: 1;
  }
}

@media (max-width: 991.98px) {
  body.font__size__25 #search-form-results-page .input-group {
    justify-content: flex-end;
  }

  body.font__size__25 #search-form-results-page .input-group > .search__input {
    border: 1px solid #d4d4d4;
    width: 100%;
  }

  body.font__size__25 #search-form-results-page .input-group > .input-group-append {
    width: 100%;
  }

  body.font__size__25 #search-form-results-page .input-group > .input-group-append > button {
    width: 50%;
    flex-grow: 1;
  }
}

@media (max-width: 991.98px) {
  body.font__size__26 #search-form-results-page .input-group {
    justify-content: flex-end;
  }

  body.font__size__26 #search-form-results-page .input-group > .search__input {
    border: 1px solid #d4d4d4;
    width: 100%;
  }

  body.font__size__26 #search-form-results-page .input-group > .input-group-append {
    width: 100%;
  }

  body.font__size__26 #search-form-results-page .input-group > .input-group-append > button {
    width: 50%;
    flex-grow: 1;
  }
}

@media (max-width: 991.98px) {
  body.font__size__27 #search-form-results-page .input-group {
    justify-content: flex-end;
  }

  body.font__size__27 #search-form-results-page .input-group > .search__input {
    border: 1px solid #d4d4d4;
    width: 100%;
  }

  body.font__size__27 #search-form-results-page .input-group > .input-group-append {
    width: 100%;
  }

  body.font__size__27 #search-form-results-page .input-group > .input-group-append > button {
    width: 50%;
    flex-grow: 1;
  }
}

@media (max-width: 991.98px) {
  body.font__size__28 #search-form-results-page .input-group {
    justify-content: flex-end;
  }

  body.font__size__28 #search-form-results-page .input-group > .search__input {
    border: 1px solid #d4d4d4;
    width: 100%;
  }

  body.font__size__28 #search-form-results-page .input-group > .input-group-append {
    width: 100%;
  }

  body.font__size__28 #search-form-results-page .input-group > .input-group-append > button {
    width: 50%;
    flex-grow: 1;
  }
}

@media (max-width: 991.98px) {
  body.font__size__29 #search-form-results-page .input-group {
    justify-content: flex-end;
  }

  body.font__size__29 #search-form-results-page .input-group > .search__input {
    border: 1px solid #d4d4d4;
    width: 100%;
  }

  body.font__size__29 #search-form-results-page .input-group > .input-group-append {
    width: 100%;
  }

  body.font__size__29 #search-form-results-page .input-group > .input-group-append > button {
    width: 50%;
    flex-grow: 1;
  }
}

@media (max-width: 1199.98px) {
  body.font__size__30 #search-form-results-page .input-group {
    justify-content: flex-end;
  }

  body.font__size__30 #search-form-results-page .input-group > .search__input {
    border: 1px solid #d4d4d4;
    width: 100%;
  }

  body.font__size__30 #search-form-results-page .input-group > .input-group-append {
    width: 100%;
  }

  body.font__size__30 #search-form-results-page .input-group > .input-group-append > button {
    width: 50%;
    flex-grow: 1;
  }
}

@media (max-width: 1199.98px) {
  body.font__size__31 #search-form-results-page .input-group {
    justify-content: flex-end;
  }

  body.font__size__31 #search-form-results-page .input-group > .search__input {
    border: 1px solid #d4d4d4;
    width: 100%;
  }

  body.font__size__31 #search-form-results-page .input-group > .input-group-append {
    width: 100%;
  }

  body.font__size__31 #search-form-results-page .input-group > .input-group-append > button {
    width: 50%;
    flex-grow: 1;
  }
}

@media (max-width: 1199.98px) {
  body.font__size__32 #search-form-results-page .input-group {
    justify-content: flex-end;
  }

  body.font__size__32 #search-form-results-page .input-group > .search__input {
    border: 1px solid #d4d4d4;
    width: 100%;
  }

  body.font__size__32 #search-form-results-page .input-group > .input-group-append {
    width: 100%;
  }

  body.font__size__32 #search-form-results-page .input-group > .input-group-append > button {
    width: 50%;
    flex-grow: 1;
  }
}

/********** Header & menus **********/

/********** Homepage #featured_links **********/

@media (min-width: 992px) and (max-width: 1200px) {
  body.font__size__20 #featured_links .featured-link-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  body.font__size__21 #featured_links .featured-link-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  body.font__size__22 #featured_links .featured-link-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  body.font__size__23 #featured_links .featured-link-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  body.font__size__24 #featured_links .featured-link-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  body.font__size__25 #featured_links .featured-link-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  body.font__size__26 #featured_links .featured-link-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  body.font__size__27 #featured_links .featured-link-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  body.font__size__28 #featured_links .featured-link-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  body.font__size__29 #featured_links .featured-link-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  body.font__size__30 #featured_links .featured-link-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  body.font__size__31 #featured_links .featured-link-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  body.font__size__32 #featured_links .featured-link-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  body.font__size__24 #featured_links .featured-link-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  body.font__size__25 #featured_links .featured-link-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  body.font__size__26 #featured_links .featured-link-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  body.font__size__27 #featured_links .featured-link-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  body.font__size__28 #featured_links .featured-link-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  body.font__size__29 #featured_links .featured-link-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  body.font__size__30 #featured_links .featured-link-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  body.font__size__31 #featured_links .featured-link-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 1200px) and (max-width: 1440px) {
  body.font__size__32 #featured_links .featured-link-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

/********** Homepage #featured_links **********/

/********** Homepage slider **********/

@media (min-width: 1500px) {
  body.font__size__21 .slider-area .slider-content {
    padding-left: 4rem;
  }

  body.font__size__22 .slider-area .slider-content {
    padding-left: 4rem;
  }

  body.font__size__23 .slider-area .slider-content {
    padding-left: 4rem;
  }

  body.font__size__24 .slider-area .slider-content {
    padding-left: 4rem;
  }

  body.font__size__25 .slider-area .slider-content {
    padding-left: 4rem;
  }

  body.font__size__26 .slider-area .slider-content {
    padding-left: 4rem;
  }

  body.font__size__27 .slider-area .slider-content {
    padding-left: 4rem;
  }

  body.font__size__28 .slider-area .slider-content {
    padding-left: 4rem;
  }

  body.font__size__29 .slider-area .slider-content {
    padding-left: 4rem;
  }

  body.font__size__30 .slider-area .slider-content {
    padding-left: 4rem;
  }

  body.font__size__31 .slider-area .slider-content {
    padding-left: 4rem;
  }

  body.font__size__32 .slider-area .slider-content {
    padding-left: 4rem;
  }
}

@media (max-width: 991.98px) {
  body.font__size__25 .slider-area .slider-content {
    width: 90%;
  }

  body.font__size__26 .slider-area .slider-content {
    width: 90%;
  }

  body.font__size__27 .slider-area .slider-content {
    width: 90%;
  }

  body.font__size__28 .slider-area .slider-content {
    width: 90%;
  }

  body.font__size__29 .slider-area .slider-content {
    width: 90%;
  }

  body.font__size__30 .slider-area .slider-content {
    width: 90%;
  }

  body.font__size__31 .slider-area .slider-content {
    width: 90%;
  }

  body.font__size__32 .slider-area .slider-content {
    width: 90%;
  }
}

body.font__size__17 .slider-area .slider-content a {
  font-size: 1.2rem !important;
  padding: .5rem 1.5rem !important;
}

body.font__size__18 .slider-area .slider-content a {
  font-size: 1.2rem !important;
  padding: .5rem 1.5rem !important;
}

body.font__size__19 .slider-area .slider-content a {
  font-size: 1.2rem !important;
  padding: .5rem 1.5rem !important;
}

body.font__size__20 .slider-area .slider-content a {
  font-size: 1.2rem !important;
  padding: .5rem 1.5rem !important;
}

body.font__size__21 .slider-area .slider-content a {
  font-size: 1.2rem !important;
  padding: .5rem 1.5rem !important;
}

body.font__size__22 .slider-area .slider-content a {
  font-size: 1.2rem !important;
  padding: .5rem 1.5rem !important;
}

body.font__size__23 .slider-area .slider-content a {
  font-size: 1.2rem !important;
  padding: .5rem 1.5rem !important;
}

body.font__size__24 .slider-area .slider-content a {
  font-size: 1.2rem !important;
  padding: .5rem 1.5rem !important;
}

body.font__size__25 .slider-area .slider-content a {
  font-size: 1.2rem !important;
  padding: .5rem 1.5rem !important;
}

body.font__size__26 .slider-area .slider-content a {
  font-size: 1.2rem !important;
  padding: .5rem 1.5rem !important;
}

body.font__size__27 .slider-area .slider-content a {
  font-size: 1.2rem !important;
  padding: .5rem 1.5rem !important;
}

body.font__size__28 .slider-area .slider-content a {
  font-size: 1.2rem !important;
  padding: .5rem 1.5rem !important;
}

body.font__size__29 .slider-area .slider-content a {
  font-size: 1.2rem !important;
  padding: .5rem 1.5rem !important;
}

body.font__size__30 .slider-area .slider-content a {
  font-size: 1.2rem !important;
  padding: .5rem 1.5rem !important;
}

body.font__size__31 .slider-area .slider-content a {
  font-size: 1.2rem !important;
  padding: .5rem 1.5rem !important;
}

body.font__size__32 .slider-area .slider-content a {
  font-size: 1.2rem !important;
  padding: .5rem 1.5rem !important;
}

/********** Homepage slider **********/

/********** Homepage announcements **********/

@media (min-width: 992px) and (max-width: 1199px) {
  body.font__size__21 #announcements {
    margin-top: 0;
  }

  body.font__size__21 #announcements .col-lg-5 {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }

  body.font__size__21 #announcements .obavijesti {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    padding-left: 15px;
    padding-top: 1.5rem;
  }

  body.font__size__21 #announcements .gradient-hr-mustard {
    display: block;
  }

  body.font__size__21 #announcements .featured-banners {
    padding: 2rem 0;
  }

  body.font__size__21 #announcements .featured-banners > .col-6 {
    width: 25%;
    max-width: 25%;
    flex: 0 0 25%;
  }

  body.font__size__21 #announcements .featured-banners > div:not([class*="gradient-hr"]) {
    margin: 15px 0;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  body.font__size__22 #announcements {
    margin-top: 0;
  }

  body.font__size__22 #announcements .col-lg-5 {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }

  body.font__size__22 #announcements .obavijesti {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    padding-left: 15px;
    padding-top: 1.5rem;
  }

  body.font__size__22 #announcements .gradient-hr-mustard {
    display: block;
  }

  body.font__size__22 #announcements .featured-banners {
    padding: 2rem 0;
  }

  body.font__size__22 #announcements .featured-banners > .col-6 {
    width: 25%;
    max-width: 25%;
    flex: 0 0 25%;
  }

  body.font__size__22 #announcements .featured-banners > div:not([class*="gradient-hr"]) {
    margin: 15px 0;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  body.font__size__23 #announcements {
    margin-top: 0;
  }

  body.font__size__23 #announcements .col-lg-5 {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }

  body.font__size__23 #announcements .obavijesti {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    padding-left: 15px;
    padding-top: 1.5rem;
  }

  body.font__size__23 #announcements .gradient-hr-mustard {
    display: block;
  }

  body.font__size__23 #announcements .featured-banners {
    padding: 2rem 0;
  }

  body.font__size__23 #announcements .featured-banners > .col-6 {
    width: 25%;
    max-width: 25%;
    flex: 0 0 25%;
  }

  body.font__size__23 #announcements .featured-banners > div:not([class*="gradient-hr"]) {
    margin: 15px 0;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  body.font__size__24 #announcements {
    margin-top: 0;
  }

  body.font__size__24 #announcements .col-lg-5 {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }

  body.font__size__24 #announcements .obavijesti {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    padding-left: 15px;
    padding-top: 1.5rem;
  }

  body.font__size__24 #announcements .gradient-hr-mustard {
    display: block;
  }

  body.font__size__24 #announcements .featured-banners {
    padding: 2rem 0;
  }

  body.font__size__24 #announcements .featured-banners > .col-6 {
    width: 25%;
    max-width: 25%;
    flex: 0 0 25%;
  }

  body.font__size__24 #announcements .featured-banners > div:not([class*="gradient-hr"]) {
    margin: 15px 0;
  }
}

@media (min-width: 992px) and (max-width: 1439px) {
  body.font__size__25 #announcements {
    margin-top: 0;
  }

  body.font__size__25 #announcements .col-lg-5 {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }

  body.font__size__25 #announcements .obavijesti {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    padding-left: 15px;
    padding-top: 1.5rem;
  }

  body.font__size__25 #announcements .gradient-hr-mustard {
    display: block;
  }

  body.font__size__25 #announcements .featured-banners {
    padding: 2rem 0;
  }

  body.font__size__25 #announcements .featured-banners > .col-6 {
    width: 25%;
    max-width: 25%;
    flex: 0 0 25%;
  }

  body.font__size__25 #announcements .featured-banners > div:not([class*="gradient-hr"]) {
    margin: 15px 0;
  }
}

@media (min-width: 992px) and (max-width: 1439px) {
  body.font__size__26 #announcements {
    margin-top: 0;
  }

  body.font__size__26 #announcements .col-lg-5 {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }

  body.font__size__26 #announcements .obavijesti {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    padding-left: 15px;
    padding-top: 1.5rem;
  }

  body.font__size__26 #announcements .gradient-hr-mustard {
    display: block;
  }

  body.font__size__26 #announcements .featured-banners {
    padding: 2rem 0;
  }

  body.font__size__26 #announcements .featured-banners > .col-6 {
    width: 25%;
    max-width: 25%;
    flex: 0 0 25%;
  }

  body.font__size__26 #announcements .featured-banners > div:not([class*="gradient-hr"]) {
    margin: 15px 0;
  }
}

@media (min-width: 992px) and (max-width: 1439px) {
  body.font__size__27 #announcements {
    margin-top: 0;
  }

  body.font__size__27 #announcements .col-lg-5 {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }

  body.font__size__27 #announcements .obavijesti {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    padding-left: 15px;
    padding-top: 1.5rem;
  }

  body.font__size__27 #announcements .gradient-hr-mustard {
    display: block;
  }

  body.font__size__27 #announcements .featured-banners {
    padding: 2rem 0;
  }

  body.font__size__27 #announcements .featured-banners > .col-6 {
    width: 25%;
    max-width: 25%;
    flex: 0 0 25%;
  }

  body.font__size__27 #announcements .featured-banners > div:not([class*="gradient-hr"]) {
    margin: 15px 0;
  }
}

@media (min-width: 992px) and (max-width: 1439px) {
  body.font__size__28 #announcements {
    margin-top: 0;
  }

  body.font__size__28 #announcements .col-lg-5 {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }

  body.font__size__28 #announcements .obavijesti {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    padding-left: 15px;
    padding-top: 1.5rem;
  }

  body.font__size__28 #announcements .gradient-hr-mustard {
    display: block;
  }

  body.font__size__28 #announcements .featured-banners {
    padding: 2rem 0;
  }

  body.font__size__28 #announcements .featured-banners > .col-6 {
    width: 25%;
    max-width: 25%;
    flex: 0 0 25%;
  }

  body.font__size__28 #announcements .featured-banners > div:not([class*="gradient-hr"]) {
    margin: 15px 0;
  }
}

@media (min-width: 992px) and (max-width: 1439px) {
  body.font__size__29 #announcements {
    margin-top: 0;
  }

  body.font__size__29 #announcements .col-lg-5 {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }

  body.font__size__29 #announcements .obavijesti {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    padding-left: 15px;
    padding-top: 1.5rem;
  }

  body.font__size__29 #announcements .gradient-hr-mustard {
    display: block;
  }

  body.font__size__29 #announcements .featured-banners {
    padding: 2rem 0;
  }

  body.font__size__29 #announcements .featured-banners > .col-6 {
    width: 25%;
    max-width: 25%;
    flex: 0 0 25%;
  }

  body.font__size__29 #announcements .featured-banners > div:not([class*="gradient-hr"]) {
    margin: 15px 0;
  }
}

@media (min-width: 992px) and (max-width: 1439px) {
  body.font__size__30 #announcements {
    margin-top: 0;
  }

  body.font__size__30 #announcements .col-lg-5 {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }

  body.font__size__30 #announcements .obavijesti {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    padding-left: 15px;
    padding-top: 1.5rem;
  }

  body.font__size__30 #announcements .gradient-hr-mustard {
    display: block;
  }

  body.font__size__30 #announcements .featured-banners {
    padding: 2rem 0;
  }

  body.font__size__30 #announcements .featured-banners > .col-6 {
    width: 25%;
    max-width: 25%;
    flex: 0 0 25%;
  }

  body.font__size__30 #announcements .featured-banners > div:not([class*="gradient-hr"]) {
    margin: 15px 0;
  }
}

@media (min-width: 992px) and (max-width: 1439px) {
  body.font__size__31 #announcements {
    margin-top: 0;
  }

  body.font__size__31 #announcements .col-lg-5 {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }

  body.font__size__31 #announcements .obavijesti {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    padding-left: 15px;
    padding-top: 1.5rem;
  }

  body.font__size__31 #announcements .gradient-hr-mustard {
    display: block;
  }

  body.font__size__31 #announcements .featured-banners {
    padding: 2rem 0;
  }

  body.font__size__31 #announcements .featured-banners > .col-6 {
    width: 25%;
    max-width: 25%;
    flex: 0 0 25%;
  }

  body.font__size__31 #announcements .featured-banners > div:not([class*="gradient-hr"]) {
    margin: 15px 0;
  }
}

@media (min-width: 992px) and (max-width: 1439px) {
  body.font__size__32 #announcements {
    margin-top: 0;
  }

  body.font__size__32 #announcements .col-lg-5 {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }

  body.font__size__32 #announcements .obavijesti {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    padding-left: 15px;
    padding-top: 1.5rem;
  }

  body.font__size__32 #announcements .gradient-hr-mustard {
    display: block;
  }

  body.font__size__32 #announcements .featured-banners {
    padding: 2rem 0;
  }

  body.font__size__32 #announcements .featured-banners > .col-6 {
    width: 25%;
    max-width: 25%;
    flex: 0 0 25%;
  }

  body.font__size__32 #announcements .featured-banners > div:not([class*="gradient-hr"]) {
    margin: 15px 0;
  }
}

/********** Homepage announcements **********/

/********** Homepage events section **********/

body.font__size__18 #events-section .post-content > h3 > a {
  height: 6rem;
}

body.font__size__19 #events-section .post-content > h3 > a {
  height: 6rem;
}

body.font__size__20 #events-section .post-content > h3 > a {
  height: 6rem;
}

body.font__size__21 #events-section .post-content > h3 > a {
  height: 6rem;
}

body.font__size__22 #events-section .post-content > h3 > a {
  height: 6rem;
}

body.font__size__23 #events-section .post-content > h3 > a {
  height: 6rem;
}

body.font__size__24 #events-section .post-content > h3 > a {
  height: 6rem;
}

body.font__size__25 #events-section .post-content > h3 > a {
  height: 6rem;
}

body.font__size__26 #events-section .post-content > h3 > a {
  height: 6rem;
}

body.font__size__27 #events-section .post-content > h3 > a {
  height: 6rem;
}

body.font__size__28 #events-section .post-content > h3 > a {
  height: 6rem;
}

body.font__size__29 #events-section .post-content > h3 > a {
  height: 6rem;
}

body.font__size__30 #events-section .post-content > h3 > a {
  height: 6rem;
}

body.font__size__31 #events-section .post-content > h3 > a {
  height: 6rem;
}

body.font__size__32 #events-section .post-content > h3 > a {
  height: 6rem;
}

body.font__size__18 #events-section .table-wrapper table#incoming_events td span > i {
  top: .1rem;
}

body.font__size__19 #events-section .table-wrapper table#incoming_events td span > i {
  top: .1rem;
}

body.font__size__20 #events-section .table-wrapper table#incoming_events td span > i {
  top: .1rem;
}

body.font__size__21 #events-section .table-wrapper table#incoming_events td span > i {
  top: .1rem;
}

body.font__size__22 #events-section .table-wrapper table#incoming_events td span > i {
  top: .1rem;
}

body.font__size__23 #events-section .table-wrapper table#incoming_events td span > i {
  top: .1rem;
}

body.font__size__24 #events-section .table-wrapper table#incoming_events td span > i {
  top: .1rem;
}

body.font__size__25 #events-section .table-wrapper table#incoming_events td span > i {
  top: .1rem;
}

body.font__size__26 #events-section .table-wrapper table#incoming_events td span > i {
  top: .1rem;
}

body.font__size__27 #events-section .table-wrapper table#incoming_events td span > i {
  top: .1rem;
}

body.font__size__28 #events-section .table-wrapper table#incoming_events td span > i {
  top: .1rem;
}

body.font__size__29 #events-section .table-wrapper table#incoming_events td span > i {
  top: .1rem;
}

body.font__size__30 #events-section .table-wrapper table#incoming_events td span > i {
  top: .1rem;
}

body.font__size__31 #events-section .table-wrapper table#incoming_events td span > i {
  top: .1rem;
}

body.font__size__32 #events-section .table-wrapper table#incoming_events td span > i {
  top: .1rem;
}

/********** Homepage events section **********/

/********** E-katalog section **********/

body.font__size__26 #e_katalog .e-katalog-box {
  width: 50%;
}

body.font__size__27 #e_katalog .e-katalog-box {
  width: 50%;
}

body.font__size__28 #e_katalog .e-katalog-box {
  width: 50%;
}

body.font__size__29 #e_katalog .e-katalog-box {
  width: 50%;
}

body.font__size__30 #e_katalog .e-katalog-box {
  width: 50%;
}

body.font__size__31 #e_katalog .e-katalog-box {
  width: 50%;
}

body.font__size__32 #e_katalog .e-katalog-box {
  width: 50%;
}

@media (max-width: 1440px) {
  body.font__size__22 #e_katalog .e-katalog-box {
    width: 50%;
  }
}

@media (max-width: 1440px) {
  body.font__size__23 #e_katalog .e-katalog-box {
    width: 50%;
  }
}

@media (max-width: 1440px) {
  body.font__size__24 #e_katalog .e-katalog-box {
    width: 50%;
  }
}

@media (max-width: 1440px) {
  body.font__size__25 #e_katalog .e-katalog-box {
    width: 50%;
  }
}

@media (max-width: 1440px) {
  body.font__size__26 #e_katalog .e-katalog-box {
    width: 50%;
  }
}

@media (max-width: 1440px) {
  body.font__size__27 #e_katalog .e-katalog-box {
    width: 50%;
  }
}

@media (max-width: 1440px) {
  body.font__size__28 #e_katalog .e-katalog-box {
    width: 50%;
  }
}

@media (max-width: 1440px) {
  body.font__size__29 #e_katalog .e-katalog-box {
    width: 50%;
  }
}

@media (max-width: 1440px) {
  body.font__size__30 #e_katalog .e-katalog-box {
    width: 50%;
  }
}

@media (max-width: 1440px) {
  body.font__size__31 #e_katalog .e-katalog-box {
    width: 50%;
  }
}

@media (max-width: 1440px) {
  body.font__size__32 #e_katalog .e-katalog-box {
    width: 50%;
  }
}

@media (max-width: 1440px) {
  body.font__size__29 #e_katalog .e-katalog-box {
    width: 70%;
  }
}

@media (max-width: 1440px) {
  body.font__size__30 #e_katalog .e-katalog-box {
    width: 70%;
  }
}

@media (max-width: 1440px) {
  body.font__size__31 #e_katalog .e-katalog-box {
    width: 70%;
  }
}

@media (max-width: 1440px) {
  body.font__size__32 #e_katalog .e-katalog-box {
    width: 70%;
  }
}

@media (max-width: 1250px) {
  body.font__size__20 #e_katalog .e-katalog-box {
    width: 70%;
  }
}

@media (max-width: 1250px) {
  body.font__size__21 #e_katalog .e-katalog-box {
    width: 70%;
  }
}

@media (max-width: 1250px) {
  body.font__size__22 #e_katalog .e-katalog-box {
    width: 70%;
  }
}

@media (max-width: 1250px) {
  body.font__size__23 #e_katalog .e-katalog-box {
    width: 70%;
  }
}

@media (max-width: 1250px) {
  body.font__size__24 #e_katalog .e-katalog-box {
    width: 70%;
  }
}

@media (max-width: 1250px) {
  body.font__size__25 #e_katalog .e-katalog-box {
    width: 70%;
  }
}

@media (max-width: 1250px) {
  body.font__size__26 #e_katalog .e-katalog-box {
    width: 70%;
  }
}

@media (max-width: 1250px) {
  body.font__size__27 #e_katalog .e-katalog-box {
    width: 70%;
  }
}

@media (max-width: 1250px) {
  body.font__size__28 #e_katalog .e-katalog-box {
    width: 70%;
  }
}

@media (max-width: 1250px) {
  body.font__size__29 #e_katalog .e-katalog-box {
    width: 70%;
  }
}

@media (max-width: 1250px) {
  body.font__size__30 #e_katalog .e-katalog-box {
    width: 70%;
  }
}

@media (max-width: 1250px) {
  body.font__size__31 #e_katalog .e-katalog-box {
    width: 70%;
  }
}

@media (max-width: 1250px) {
  body.font__size__32 #e_katalog .e-katalog-box {
    width: 70%;
  }
}

@media (max-width: 991.98px) {
  body.font__size__29 #e_katalog .e-katalog-box {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
  }
}

@media (max-width: 991.98px) {
  body.font__size__30 #e_katalog .e-katalog-box {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
  }
}

@media (max-width: 991.98px) {
  body.font__size__31 #e_katalog .e-katalog-box {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
  }
}

@media (max-width: 991.98px) {
  body.font__size__32 #e_katalog .e-katalog-box {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
  }
}

@media (max-width: 850px) {
  body.font__size__26 #e_katalog .e-katalog-box {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
  }
}

@media (max-width: 850px) {
  body.font__size__27 #e_katalog .e-katalog-box {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
  }
}

@media (max-width: 850px) {
  body.font__size__28 #e_katalog .e-katalog-box {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
  }
}

@media (max-width: 850px) {
  body.font__size__29 #e_katalog .e-katalog-box {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
  }
}

@media (max-width: 850px) {
  body.font__size__30 #e_katalog .e-katalog-box {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
  }
}

@media (max-width: 850px) {
  body.font__size__31 #e_katalog .e-katalog-box {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
  }
}

@media (max-width: 850px) {
  body.font__size__32 #e_katalog .e-katalog-box {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  body.font__size__20 #e_katalog .e-katalog-box {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  body.font__size__21 #e_katalog .e-katalog-box {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  body.font__size__22 #e_katalog .e-katalog-box {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  body.font__size__23 #e_katalog .e-katalog-box {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  body.font__size__24 #e_katalog .e-katalog-box {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  body.font__size__25 #e_katalog .e-katalog-box {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  body.font__size__26 #e_katalog .e-katalog-box {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  body.font__size__27 #e_katalog .e-katalog-box {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  body.font__size__28 #e_katalog .e-katalog-box {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  body.font__size__29 #e_katalog .e-katalog-box {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  body.font__size__30 #e_katalog .e-katalog-box {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  body.font__size__31 #e_katalog .e-katalog-box {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  body.font__size__32 #e_katalog .e-katalog-box {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
  }
}

/********** E-katalog section **********/

/********** container sizing ***********/

@media (min-width: 1441px) {
  body.font__size__21 .container {
    max-width: 1654px;
  }

  body.font__size__22 .container {
    max-width: 1654px;
  }

  body.font__size__23 .container {
    max-width: 1654px;
  }

  body.font__size__24 .container {
    max-width: 1654px;
  }

  body.font__size__25 .container {
    max-width: 1654px;
  }

  body.font__size__26 .container {
    max-width: 1654px;
  }

  body.font__size__27 .container {
    max-width: 1654px;
  }

  body.font__size__28 .container {
    max-width: 1654px;
  }

  body.font__size__29 .container {
    max-width: 1840px;
  }

  body.font__size__30 .container {
    max-width: 1840px;
  }

  body.font__size__31 .container {
    max-width: 1840px;
  }

  body.font__size__32 .container {
    max-width: 1840px;
  }
}

/********** container sizing ***********/

/********** selected_pages section ***********/

body.font__size__29 #selected_pages .selected-pages-wrapper {
  width: 65%;
}

@media (max-width: 1439.98px) {
  body.font__size__29 #selected_pages .selected-pages-wrapper {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 576.98px) {
  body.font__size__29 #selected_pages .selected-pages-wrapper .selected-pages {
    width: 100%;
    position: relative;
    padding: 0 15px;
    left: 0;
    right: 0;
  }
}

@media (min-width: 577px) and (max-width: 991.98px) {
  body.font__size__29 #selected_pages .selected-pages-wrapper .selected-pages {
    width: 80%;
  }
}

body.font__size__30 #selected_pages .selected-pages-wrapper {
  width: 65%;
}

@media (max-width: 1439.98px) {
  body.font__size__30 #selected_pages .selected-pages-wrapper {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 576.98px) {
  body.font__size__30 #selected_pages .selected-pages-wrapper .selected-pages {
    width: 100%;
    position: relative;
    padding: 0 15px;
    left: 0;
    right: 0;
  }
}

@media (min-width: 577px) and (max-width: 991.98px) {
  body.font__size__30 #selected_pages .selected-pages-wrapper .selected-pages {
    width: 80%;
  }
}

body.font__size__31 #selected_pages .selected-pages-wrapper {
  width: 65%;
}

@media (max-width: 1439.98px) {
  body.font__size__31 #selected_pages .selected-pages-wrapper {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 576.98px) {
  body.font__size__31 #selected_pages .selected-pages-wrapper .selected-pages {
    width: 100%;
    position: relative;
    padding: 0 15px;
    left: 0;
    right: 0;
  }
}

@media (min-width: 577px) and (max-width: 991.98px) {
  body.font__size__31 #selected_pages .selected-pages-wrapper .selected-pages {
    width: 80%;
  }
}

body.font__size__32 #selected_pages .selected-pages-wrapper {
  width: 65%;
}

@media (max-width: 1439.98px) {
  body.font__size__32 #selected_pages .selected-pages-wrapper {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (max-width: 576.98px) {
  body.font__size__32 #selected_pages .selected-pages-wrapper .selected-pages {
    width: 100%;
    position: relative;
    padding: 0 15px;
    left: 0;
    right: 0;
  }
}

@media (min-width: 577px) and (max-width: 991.98px) {
  body.font__size__32 #selected_pages .selected-pages-wrapper .selected-pages {
    width: 80%;
  }
}

body.font__size__25 #selected_pages .selected-pages-wrapper .selected-pages {
  width: 65%;
}

@media (max-width: 576.98px) {
  body.font__size__25 #selected_pages .selected-pages-wrapper .selected-pages {
    width: 100%;
    position: relative;
    padding: 0 15px;
    left: 0;
    right: 0;
  }
}

@media (min-width: 577px) and (max-width: 1199.98px) {
  body.font__size__25 #selected_pages .selected-pages-wrapper .selected-pages {
    width: 80%;
  }
}

body.font__size__26 #selected_pages .selected-pages-wrapper .selected-pages {
  width: 65%;
}

@media (max-width: 576.98px) {
  body.font__size__26 #selected_pages .selected-pages-wrapper .selected-pages {
    width: 100%;
    position: relative;
    padding: 0 15px;
    left: 0;
    right: 0;
  }
}

@media (min-width: 577px) and (max-width: 1199.98px) {
  body.font__size__26 #selected_pages .selected-pages-wrapper .selected-pages {
    width: 80%;
  }
}

body.font__size__27 #selected_pages .selected-pages-wrapper .selected-pages {
  width: 65%;
}

@media (max-width: 576.98px) {
  body.font__size__27 #selected_pages .selected-pages-wrapper .selected-pages {
    width: 100%;
    position: relative;
    padding: 0 15px;
    left: 0;
    right: 0;
  }
}

@media (min-width: 577px) and (max-width: 1199.98px) {
  body.font__size__27 #selected_pages .selected-pages-wrapper .selected-pages {
    width: 80%;
  }
}

body.font__size__28 #selected_pages .selected-pages-wrapper .selected-pages {
  width: 65%;
}

@media (max-width: 576.98px) {
  body.font__size__28 #selected_pages .selected-pages-wrapper .selected-pages {
    width: 100%;
    position: relative;
    padding: 0 15px;
    left: 0;
    right: 0;
  }
}

@media (min-width: 577px) and (max-width: 1199.98px) {
  body.font__size__28 #selected_pages .selected-pages-wrapper .selected-pages {
    width: 80%;
  }
}

@media (max-width: 767.98px) {
  body.font__size__21 #selected_pages .selected-pages-wrapper .selected-pages {
    width: 100%;
    position: relative;
    padding: 0 15px;
    left: 0;
    right: 0;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  body.font__size__21 #selected_pages .selected-pages-wrapper .selected-pages {
    width: 80%;
  }
}

@media (min-width: 992px) and (max-width: 1300px) {
  body.font__size__21 #selected_pages .selected-pages-wrapper .selected-pages {
    width: 70%;
  }
}

@media (max-width: 767.98px) {
  body.font__size__22 #selected_pages .selected-pages-wrapper .selected-pages {
    width: 100%;
    position: relative;
    padding: 0 15px;
    left: 0;
    right: 0;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  body.font__size__22 #selected_pages .selected-pages-wrapper .selected-pages {
    width: 80%;
  }
}

@media (min-width: 992px) and (max-width: 1300px) {
  body.font__size__22 #selected_pages .selected-pages-wrapper .selected-pages {
    width: 70%;
  }
}

@media (max-width: 767.98px) {
  body.font__size__23 #selected_pages .selected-pages-wrapper .selected-pages {
    width: 100%;
    position: relative;
    padding: 0 15px;
    left: 0;
    right: 0;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  body.font__size__23 #selected_pages .selected-pages-wrapper .selected-pages {
    width: 80%;
  }
}

@media (min-width: 992px) and (max-width: 1300px) {
  body.font__size__23 #selected_pages .selected-pages-wrapper .selected-pages {
    width: 70%;
  }
}

@media (max-width: 767.98px) {
  body.font__size__24 #selected_pages .selected-pages-wrapper .selected-pages {
    width: 100%;
    position: relative;
    padding: 0 15px;
    left: 0;
    right: 0;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  body.font__size__24 #selected_pages .selected-pages-wrapper .selected-pages {
    width: 80%;
  }
}

@media (min-width: 992px) and (max-width: 1300px) {
  body.font__size__24 #selected_pages .selected-pages-wrapper .selected-pages {
    width: 70%;
  }
}

/********** selected_pages section ***********/

/********** gallery_teaser section ***********/

body.font__size__21 #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row:nth-child(2) .gallery-title {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0;
}

body.font__size__22 #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row:nth-child(2) .gallery-title {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0;
}

body.font__size__23 #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row:nth-child(2) .gallery-title {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0;
}

body.font__size__24 #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row:nth-child(2) .gallery-title {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0;
}

@media (min-width: 992px) {
  body.font__size__25 #gallery-teaser .galleries-wrapper {
    height: 990px;
  }
}

@media (min-width: 992px) {
  body.font__size__26 #gallery-teaser .galleries-wrapper {
    height: 990px;
  }
}

@media (min-width: 992px) {
  body.font__size__27 #gallery-teaser .galleries-wrapper {
    height: 990px;
  }
}

@media (min-width: 992px) {
  body.font__size__28 #gallery-teaser .galleries-wrapper {
    height: 990px;
  }
}

@media (min-width: 1200px) {
  body.font__size__29 #gallery-teaser .galleries-wrapper {
    height: 990px;
  }
}

@media (max-width: 399.98px) {
  body.font__size__29 #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row > div {
    height: 10rem;
  }
}

@media (min-width: 400px) and (max-width: 575.98px) {
  body.font__size__29 #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row > div {
    height: 12rem;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  body.font__size__29 #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row > div {
    height: 10rem;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  body.font__size__29 #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row > div {
    height: 12rem;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  body.font__size__29 #gallery-teaser .galleries-wrapper {
    height: unset;
  }

  body.font__size__29 #gallery-teaser .galleries-wrapper > div {
    height: 100%;
  }

  body.font__size__29 #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row:nth-child(1) {
    height: unset;
  }

  body.font__size__29 #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row:nth-child(2) {
    height: unset;
  }

  body.font__size__29 #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row > div {
    height: 15rem;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  body.font__size__29 #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row > div .gal-link.third,
  body.font__size__29 #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row > div .gal-link.fourth {
    height: 100% !important;
  }
}

@media (min-width: 1200px) {
  body.font__size__30 #gallery-teaser .galleries-wrapper {
    height: 990px;
  }
}

@media (max-width: 399.98px) {
  body.font__size__30 #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row > div {
    height: 10rem;
  }
}

@media (min-width: 400px) and (max-width: 575.98px) {
  body.font__size__30 #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row > div {
    height: 12rem;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  body.font__size__30 #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row > div {
    height: 10rem;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  body.font__size__30 #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row > div {
    height: 12rem;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  body.font__size__30 #gallery-teaser .galleries-wrapper {
    height: unset;
  }

  body.font__size__30 #gallery-teaser .galleries-wrapper > div {
    height: 100%;
  }

  body.font__size__30 #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row:nth-child(1) {
    height: unset;
  }

  body.font__size__30 #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row:nth-child(2) {
    height: unset;
  }

  body.font__size__30 #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row > div {
    height: 15rem;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  body.font__size__30 #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row > div .gal-link.third,
  body.font__size__30 #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row > div .gal-link.fourth {
    height: 100% !important;
  }
}

@media (min-width: 1200px) {
  body.font__size__31 #gallery-teaser .galleries-wrapper {
    height: 990px;
  }
}

@media (max-width: 399.98px) {
  body.font__size__31 #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row > div {
    height: 10rem;
  }
}

@media (min-width: 400px) and (max-width: 575.98px) {
  body.font__size__31 #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row > div {
    height: 12rem;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  body.font__size__31 #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row > div {
    height: 10rem;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  body.font__size__31 #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row > div {
    height: 12rem;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  body.font__size__31 #gallery-teaser .galleries-wrapper {
    height: unset;
  }

  body.font__size__31 #gallery-teaser .galleries-wrapper > div {
    height: 100%;
  }

  body.font__size__31 #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row:nth-child(1) {
    height: unset;
  }

  body.font__size__31 #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row:nth-child(2) {
    height: unset;
  }

  body.font__size__31 #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row > div {
    height: 15rem;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  body.font__size__31 #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row > div .gal-link.third,
  body.font__size__31 #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row > div .gal-link.fourth {
    height: 100% !important;
  }
}

@media (min-width: 1200px) {
  body.font__size__32 #gallery-teaser .galleries-wrapper {
    height: 990px;
  }
}

@media (max-width: 399.98px) {
  body.font__size__32 #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row > div {
    height: 10rem;
  }
}

@media (min-width: 400px) and (max-width: 575.98px) {
  body.font__size__32 #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row > div {
    height: 12rem;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  body.font__size__32 #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row > div {
    height: 10rem;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  body.font__size__32 #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row > div {
    height: 12rem;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  body.font__size__32 #gallery-teaser .galleries-wrapper {
    height: unset;
  }

  body.font__size__32 #gallery-teaser .galleries-wrapper > div {
    height: 100%;
  }

  body.font__size__32 #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row:nth-child(1) {
    height: unset;
  }

  body.font__size__32 #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row:nth-child(2) {
    height: unset;
  }

  body.font__size__32 #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row > div {
    height: 15rem;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  body.font__size__32 #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row > div .gal-link.third,
  body.font__size__32 #gallery-teaser .galleries-wrapper > div:nth-child(2) > .row > div .gal-link.fourth {
    height: 100% !important;
  }
}

/********** gallery_teaser section ***********/

@media (min-width: 992px) {
  body.font__size__29 div.d-xl-block__font-size-4 {
    display: block !important;
  }
}

@media (max-width: 1199.98px) {
  body.font__size__29 div.d-xl-block__font-size-4 {
    display: none !important;
  }
}

@media (min-width: 992px) {
  body.font__size__29 div.d-xl-none__font-size-4 {
    display: none !important;
  }
}

@media (max-width: 1199.98px) {
  body.font__size__29 div.d-xl-none__font-size-4 {
    display: block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  body.font__size__29 div.col-md-12__font-size-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  body.font__size__29 div.col-xl-12__font-size-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  body.font__size__29 div.col-lg-6__font-size-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 992px) {
  body.font__size__30 div.d-xl-block__font-size-4 {
    display: block !important;
  }
}

@media (max-width: 1199.98px) {
  body.font__size__30 div.d-xl-block__font-size-4 {
    display: none !important;
  }
}

@media (min-width: 992px) {
  body.font__size__30 div.d-xl-none__font-size-4 {
    display: none !important;
  }
}

@media (max-width: 1199.98px) {
  body.font__size__30 div.d-xl-none__font-size-4 {
    display: block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  body.font__size__30 div.col-md-12__font-size-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  body.font__size__30 div.col-xl-12__font-size-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  body.font__size__30 div.col-lg-6__font-size-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 992px) {
  body.font__size__31 div.d-xl-block__font-size-4 {
    display: block !important;
  }
}

@media (max-width: 1199.98px) {
  body.font__size__31 div.d-xl-block__font-size-4 {
    display: none !important;
  }
}

@media (min-width: 992px) {
  body.font__size__31 div.d-xl-none__font-size-4 {
    display: none !important;
  }
}

@media (max-width: 1199.98px) {
  body.font__size__31 div.d-xl-none__font-size-4 {
    display: block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  body.font__size__31 div.col-md-12__font-size-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  body.font__size__31 div.col-xl-12__font-size-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  body.font__size__31 div.col-lg-6__font-size-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 992px) {
  body.font__size__32 div.d-xl-block__font-size-4 {
    display: block !important;
  }
}

@media (max-width: 1199.98px) {
  body.font__size__32 div.d-xl-block__font-size-4 {
    display: none !important;
  }
}

@media (min-width: 992px) {
  body.font__size__32 div.d-xl-none__font-size-4 {
    display: none !important;
  }
}

@media (max-width: 1199.98px) {
  body.font__size__32 div.d-xl-none__font-size-4 {
    display: block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  body.font__size__32 div.col-md-12__font-size-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  body.font__size__32 div.col-xl-12__font-size-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  body.font__size__32 div.col-lg-6__font-size-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

/********** VARIABLES **********/

/********** Colors **********/

body.high__contrast {
  background-color: #333333 !important;
  /********** Accessibility toolbar **********/
  /********** Accessibility toolbar end **********/
  /********** News list default **********/
  /********** News list default end **********/
  /********** News detail default **********/
  /********** News detail default end **********/
  /********** News detail books **********/
  /********** News detail books end **********/
  /**************************** Owl carousel nav buttons ****************************/
  /**************************** Owl carousel nav buttons ****************************/
  /************************************ Base ************************************/
  /************************************ Base ************************************/
  /************************************ Search results page ************************************/
  /************************************ Search results page ************************************/
  /************************************ Homepage ************************************/
  /************************************ Homepage ************************************/
  /********** Fix affected CMS toolbar colors - THIS MUST BE AT THE BOTTOM **********/
  /*******************************************************************************************/
}

body.high__contrast.grayscale__filter .hc_filter__grayscale {
  filter: grayscale(1);
}

body.high__contrast.grayscale__filter .hc_filter__grayscale__bg {
  filter: grayscale(1);
  background-image: inherit;
  background-size: inherit;
  background-position: inherit;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

body.high__contrast.invert__filter .hc_filter__invert {
  filter: invert(1);
}

body.high__contrast.invert__filter .hc_filter__invert__bg {
  filter: invert(1);
  background-image: inherit;
  background-size: inherit;
  background-position: inherit;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

body.high__contrast .header-mid-area {
  background-color: #333333 !important;
  color: white !important;
}

body.high__contrast .header-sub,
body.high__contrast .sub-menu {
  background-color: #333333 !important;
}

body.high__contrast :not(*[class*='cms-']) p,
body.high__contrast :not(*[class*='cms-']) span,
body.high__contrast :not(*[class*='cms-']) h1,
body.high__contrast :not(*[class*='cms-']) h2,
body.high__contrast :not(*[class*='cms-']) h3,
body.high__contrast :not(*[class*='cms-']) h4,
body.high__contrast :not(*[class*='cms-']) h5 {
  color: white !important;
}

body.high__contrast :not(*[class*='cms-']) p > strong,
body.high__contrast :not(*[class*='cms-']) span > strong,
body.high__contrast :not(*[class*='cms-']) h1 > strong,
body.high__contrast :not(*[class*='cms-']) h2 > strong,
body.high__contrast :not(*[class*='cms-']) h3 > strong,
body.high__contrast :not(*[class*='cms-']) h4 > strong,
body.high__contrast :not(*[class*='cms-']) h5 > strong {
  color: white !important;
}

body.high__contrast :not(*[class*='cms-']) a,
body.high__contrast :not(*[class*='cms-']) a > i {
  color: yellow !important;
  background-color: transparent;
  border-color: white !important;
}

body.high__contrast :not(*[class*='cms-']) a:hover,
body.high__contrast :not(*[class*='cms-']) a > i:hover {
  background-color: #111111 !important;
}

body.high__contrast #scrollUp {
  color: yellow !important;
  background-color: transparent;
  border-color: white !important;
}

body.high__contrast #scrollUp:hover {
  background-color: #111111 !important;
}

body.high__contrast ul li {
  color: white !important;
}

body.high__contrast ul li a,
body.high__contrast ul li a > i,
body.high__contrast ul li a > span {
  color: yellow !important;
}

body.high__contrast .pagination-area ul {
  color: white !important;
}

body.high__contrast .card-plugin > .card-text {
  background-color: #333333 !important;
}

body.high__contrast #privacy-banner {
  background: #333333 !important;
}

body.high__contrast #privacy-banner .banner-buttons .js-cookie-accept {
  color: yellow !important;
  background-color: transparent;
  border-color: white !important;
}

body.high__contrast #privacy-banner .banner-buttons .js-cookie-accept:hover {
  background-color: #111111 !important;
}

body.high__contrast #privacy-banner .banner-buttons .js-cookie-settings {
  color: yellow !important;
  background-color: transparent;
  border-color: white !important;
}

body.high__contrast #privacy-banner .banner-buttons .js-cookie-settings:hover {
  background-color: #111111 !important;
}

body.high__contrast #privacy-banner .banner-text {
  color: white !important;
}

body.high__contrast #privacy-banner .banner-text > .js-cookie-settings > strong {
  color: yellow !important;
  background-color: transparent;
}

body.high__contrast #privacy-banner .banner-text > .js-cookie-settings > strong:hover {
  background-color: #111111 !important;
}

body.high__contrast #privacysettings > .modal-dialog > .modal-content {
  background: #333333 !important;
}

body.high__contrast #privacysettings > .modal-dialog > .modal-content > .modal-header > .close {
  opacity: 1;
}

body.high__contrast #privacysettings > .modal-dialog > .modal-content > .modal-header > .close span {
  color: yellow !important;
}

body.high__contrast #privacysettings > .modal-dialog > .modal-content > .modal-body > .alert-info {
  background: #111111 !important;
}

body.high__contrast #privacysettings > .modal-dialog > .modal-content > .modal-body hr {
  display: none;
}

body.high__contrast #privacysettings > .modal-dialog > .modal-content > .modal-footer > .js-modal-accept {
  color: yellow !important;
  background-color: transparent;
  border-color: white !important;
}

body.high__contrast #privacysettings > .modal-dialog > .modal-content > .modal-footer > .js-modal-accept:hover {
  background-color: #111111 !important;
}

body.high__contrast #acc_toolbar > .options > li > button {
  color: yellow !important;
  background-color: transparent;
  border-color: white !important;
  background: #333333 !important;
  border-width: 1px;
  border-color: yellow !important;
}

body.high__contrast #acc_toolbar > .options > li > button:hover {
  background-color: #111111 !important;
}

body.high__contrast #_acc_toggle {
  background: yellow !important;
}

body.high__contrast #_acc_toggle > i {
  color: #333333 !important;
}

body.high__contrast #_acc_toggle > i:hover {
  color: #111111 !important;
}

body.high__contrast .blog-main-wrapper .product-details .badge-info {
  background-color: #111111 !important;
}

body.high__contrast .blog-main-wrapper .event-meta-detail-wrapper {
  background-color: #111111 !important;
}

body.high__contrast .blog-main-wrapper .event-meta-detail-wrapper .event-meta-detail .badge-warning {
  color: white !important;
  background-color: #333333 !important;
}

body.high__contrast .blog-main-wrapper .blog-single-share .fab {
  color: yellow !important;
}

body.high__contrast .book-detail .product-main-area .book-detail-bg {
  background-color: #111111 !important;
}

body.high__contrast .book-detail .product-main-area .product-addto-links a {
  border: 1px solid yellow !important;
}

body.high__contrast .book-detail .product-main-area .book-link > a {
  color: yellow !important;
}

body.high__contrast .owl-carousel .owl-nav > button {
  background-color: #333333 !important;
}

body.high__contrast .owl-carousel .owl-nav > button:hover {
  background-color: #111111 !important;
}

body.high__contrast .owl-carousel .owl-nav > button > i {
  color: yellow !important;
}

body.high__contrast .owl-carousel .owl-dots .owl-dot.active span {
  background-color: yellow !important;
}

body.high__contrast > header .logo-area img {
  content: url("/static/img/logo/gkc-logo-w.png");
}

body.high__contrast > header .language-area #lang_dropdown_toggle i {
  color: yellow !important;
}

body.high__contrast > header .language-area ul.pages {
  background-color: #333333 !important;
}

body.high__contrast > header .language-area ul.pages > .close-icon {
  color: yellow !important;
}

body.high__contrast > header .menu-area li.active {
  background-color: #111111 !important;
}

body.high__contrast > header .menu-area .submenu-trigger {
  color: yellow !important;
}

body.high__contrast > header .menu-area li:not(:last-child):hover {
  background-color: #111111 !important;
}

body.high__contrast > header .menu-area .search-toggle-button {
  background: #333333 !important;
  border-color: yellow !important;
  color: yellow !important;
}

body.high__contrast > header .menu-area .search-toggle-button:hover {
  background: #111111 !important;
}

body.high__contrast > header .menu-area .search-toggle-button > span {
  color: yellow !important;
}

body.high__contrast > header .menu-area .search-toggle-button > span > span {
  color: yellow !important;
}

body.high__contrast > header .mobile-menu-area .mean-nav ul li {
  background-color: #333333 !important;
}

body.high__contrast > header .mobile-menu-area .mean-nav ul li.active > a {
  text-decoration: underline;
}

body.high__contrast > header .mobile-menu-area .mean-nav ul li a,
body.high__contrast > header .mobile-menu-area .mean-nav ul li i {
  background-color: #333333 !important;
}

body.high__contrast > header .mobile-menu-area .mean-nav ul li li a {
  opacity: 1;
  border-top: none;
}

body.high__contrast > header #search-menu {
  background-color: #111111 !important;
}

body.high__contrast > header #search-menu h5 {
  color: yellow !important;
}

body.high__contrast > header #search-menu .input-group .search__input {
  background-color: #333333 !important;
  color: yellow !important;
}

body.high__contrast > header #search-menu .input-group .search__input::placeholder {
  color: yellow !important;
}

body.high__contrast > header #search-menu .input-group .input-group-append > button {
  background-color: #333333 !important;
  color: yellow !important;
  border: 1px solid yellow !important;
}

body.high__contrast > header #search-menu .input-group .input-group-append > button:hover {
  background-color: #111111 !important;
}

body.high__contrast #search-form-results-page .input-group .search__input {
  background-color: #333333 !important;
  color: yellow !important;
}

body.high__contrast #search-form-results-page .input-group .search__input::placeholder {
  color: yellow !important;
}

body.high__contrast #search-form-results-page .input-group .input-group-append > button {
  background-color: #333333 !important;
  color: yellow !important;
  border: 1px solid yellow !important;
}

body.high__contrast #search-form-results-page .input-group .input-group-append > button:hover {
  background-color: #111111 !important;
}

body.high__contrast > #featured_links {
  background-color: #111111 !important;
}

body.high__contrast > #featured_links .featured-link-wrapper .single-banner .banner-img.link i {
  color: yellow !important;
}

body.high__contrast > #featured_links .featured-link-wrapper .single-banner a .banner-text p,
body.high__contrast > #featured_links .featured-link-wrapper .single-banner a .banner-text h4 {
  color: yellow !important;
}

body.high__contrast .slider-area .slider-content .text-light {
  background-color: rgba(17, 17, 17, 0.7) !important;
}

body.high__contrast .slider-area .slider-content a {
  background-color: #111111 !important;
}

body.high__contrast #announcements .obavijest-item {
  border-color: white !important;
}

body.high__contrast #e_katalog .e-katalog-box {
  background-color: #111111 !important;
}

body.high__contrast #e_katalog .e-katalog-box:hover {
  background-color: #111111 !important;
}

body.high__contrast #e_katalog .e-katalog-box h2 {
  color: white !important;
  opacity: 1;
}

body.high__contrast #events-section .single-post .post-img .blog-date-time {
  background-color: #111111 !important;
}

body.high__contrast #events-section .single-post .post-content .meta-event .meta-event-data {
  border-color: white !important;
  color: white !important;
}

body.high__contrast #events-section #incoming_events {
  color: white !important;
}

body.high__contrast #events-section #incoming_events tr {
  background-color: #111111 !important;
  border-color: white !important;
}

body.high__contrast #selected_pages .selected-pages-wrapper .selected-pages {
  background-color: #111111 !important;
}

body.high__contrast #selected_pages .selected-pages-wrapper .selected-pages .link-list li:hover i {
  color: yellow !important;
}

body.high__contrast #gallery-teaser .galleries-wrapper .gal-link .gallery-title {
  background-color: rgba(51, 51, 51, 0.7) !important;
}

body.high__contrast #gallery-teaser .galleries-wrapper .gal-link .gallery-title h2 {
  color: yellow !important;
}

body.high__contrast footer .social-links i {
  color: yellow !important;
}

body.high__contrast footer .social-links i:hover {
  background-color: #111111 !important;
}

body.high__contrast #cms-top .cms-toolbar-item-navigation li:not(.cms-toolbar-item-navigation-hover) a,
body.high__contrast #cms-top .cms-toolbar-item-navigation li:not(.cms-toolbar-item-navigation-hover) a span {
  color: #000 !important;
}

body.high__contrast #cms-top .cms-toolbar-item-navigation li:not(.cms-toolbar-item-navigation-hover) a:hover {
  background: transparent !important;
}

body.high__contrast #cms-top .cms-toolbar-item-navigation li.cms-toolbar-item-navigation-hover > ul > li > a:hover {
  background-color: #0bf !important;
}

body.high__contrast #current_acc_state {
  background-color: #111111 !important;
  color: yellow !important;
}

a,
input,
textarea,
button,
*[tabindex="0"] {
  outline: none;
}

body[class*=font__size__] .in__focus:not(.prevent__focus__onclick):not(.prevent__focus__onclick),
body.high__contrast .in__focus:not(.prevent__focus__onclick):not(.prevent__focus__onclick) {
  transition: none;
  outline: 5px auto rgba(0, 0, 255, 0.5) !important;
  outline-offset: 2px !important;
}

body[class*=font__size__] #books-slider .product-details a:not(.prevent__focus__onclick):focus,
body.high__contrast #books-slider .product-details a:not(.prevent__focus__onclick):focus {
  transition: none;
  outline: 5px auto rgba(0, 0, 255, 0.5) !important;
  outline-offset: -2px !important;
}

body[class*=font__size__] #events-section .post-content a:not(.prevent__focus__onclick):focus,
body.high__contrast #events-section .post-content a:not(.prevent__focus__onclick):focus {
  transition: none;
  outline: 5px auto rgba(0, 0, 255, 0.5) !important;
  outline-offset: -2px !important;
}

body[class*=font__size__] :not(#books-slider):not(#events-section) a:not(.prevent__focus__onclick):focus,
body.high__contrast :not(#books-slider):not(#events-section) a:not(.prevent__focus__onclick):focus {
  transition: none;
  outline: 5px auto rgba(0, 0, 255, 0.5) !important;
  outline-offset: 2px !important;
}

body[class*=font__size__] *[tabindex="0"]:not(.prevent__focus__onclick):focus,
body.high__contrast *[tabindex="0"]:not(.prevent__focus__onclick):focus {
  transition: none;
  outline: 5px auto rgba(0, 0, 255, 0.5) !important;
  outline-offset: 2px !important;
}

body[class*=font__size__] button:not(.prevent__focus__onclick):focus,
body.high__contrast button:not(.prevent__focus__onclick):focus {
  transition: none;
  outline: 5px auto rgba(0, 0, 255, 0.5) !important;
  outline-offset: 2px !important;
}

body[class*=font__size__] button.search-toggle-button:focus,
body.high__contrast button.search-toggle-button:focus {
  outline-offset: 4px;
}

body[class*=font__size__] input:not(.prevent__focus__onclick):focus,
body[class*=font__size__] textarea:not(.prevent__focus__onclick):focus,
body.high__contrast input:not(.prevent__focus__onclick):focus,
body.high__contrast textarea:not(.prevent__focus__onclick):focus {
  transition: none;
  outline: 5px auto rgba(0, 0, 255, 0.5) !important;
  outline-offset: 2px !important;
}

body[class*=font__size__] .image__focus::before,
body.high__contrast .image__focus::before {
  content: "";
  height: 100%;
  top: 0;
  z-index: 1;
  transform: scale(0);
  left: 0;
  position: absolute;
  width: 100%;
  opacity: 0;
}

body[class*=font__size__] .image__focus:not(.prevent__focus__onclick):focus::before,
body.high__contrast .image__focus:not(.prevent__focus__onclick):focus::before {
  border: 10px solid #0060fd;
  opacity: .5;
  transform: scale(1);
}

body[class*=font__size__] .owl-carousel .owl-nav button,
body.high__contrast .owl-carousel .owl-nav button {
  opacity: 1 !important;
  visibility: visible !important;
}

body[class*=font__size__] .owl-carousel .owl-nav button.owl-prev,
body.high__contrast .owl-carousel .owl-nav button.owl-prev {
  left: 0;
}

body[class*=font__size__] .owl-carousel .owl-nav button.owl-next,
body.high__contrast .owl-carousel .owl-nav button.owl-next {
  right: 0;
}

body[class*=font__size__] .owl-carousel .owl-dots,
body.high__contrast .owl-carousel .owl-dots {
  opacity: 1 !important;
  visibility: visible !important;
}

body[class*=font__size__] #gallery-teaser .gal-link a:not(.prevent__focus__onclick):focus .gallery-title,
body.high__contrast #gallery-teaser .gal-link a:not(.prevent__focus__onclick):focus .gallery-title {
  opacity: 1;
  border: 6px solid rgba(0, 96, 253, 0.5);
}

body[class*=font__size__] .gallery-list .gallery-title:not(.prevent__focus__onclick):focus,
body.high__contrast .gallery-list .gallery-title:not(.prevent__focus__onclick):focus {
  transition: none;
  outline: 5px auto rgba(0, 0, 255, 0.5) !important;
  outline-offset: -1px !important;
}

body[class*=font__size__] .product-details > a:not(.prevent__focus__onclick):focus,
body.high__contrast .product-details > a:not(.prevent__focus__onclick):focus {
  transition: none;
  outline: 5px auto rgba(0, 0, 255, 0.5) !important;
  outline-offset: -1px !important;
}

body[class*=font__size__] .product-link a:not(.prevent__focus__onclick):focus,
body.high__contrast .product-link a:not(.prevent__focus__onclick):focus {
  transition: none;
  outline: 5px auto rgba(0, 0, 255, 0.5) !important;
  outline-offset: -1px !important;
}

body[class*=font__size__] .pagination-area ul > li > a:not(.prevent__focus__onclick):focus,
body.high__contrast .pagination-area ul > li > a:not(.prevent__focus__onclick):focus {
  transition: none;
  outline: 5px auto rgba(0, 0, 255, 0.5) !important;
  outline-offset: -1px !important;
}