@mixin search-menu__input-group-mobile($border-color: false){
    justify-content: flex-end;
    & > .search__input {
        @if $border-color {
            border: 1px solid $border-color;
        }
        @else {
            border: 1px solid #fff;
        }
        width: 100%;
    }
    & > .input-group-append {
        width: 100%;
        & > button {
            width: 50%;
            flex-grow: 1;
        }
    }
}

@mixin top-bar-menu__pages__hidden {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    background-color: #fff;
    z-index: 1000;
    transition: .3s;
    padding: 11px 0;
    &.opened {
        opacity: 1;
        visibility: visible;
    }
    & > .close-icon {
        display: inline-block;
    }
}
